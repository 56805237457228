import { createSelector } from "reselect";

import converToArrays from "../../helpers/convertToarrays";

const selectTiendaState = state => state.tienda;

export const selectIsFetchingTienda = createSelector(
  [selectTiendaState],
  ({ isFetching }) => isFetching
);

export const selectIsDoingAsync = createSelector(
  [selectTiendaState],
  ({ canjeando, comprando }) => canjeando || comprando
);

export const selectProductosArray = createSelector(
  [selectTiendaState],
  ({ productos, pagination }) => {
    return converToArrays(productos, pagination);
  }
);
