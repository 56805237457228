import { bannerActionTypes } from './types'

export const getBannerStart = () => ({
	type: bannerActionTypes.GET_BANNER_START,
})

export const getBannerSucces = banners => ({
	type: bannerActionTypes.GET_BANNER_SUCCESS,
	payload: banners,
})

export const getBannerFailed = error => ({
	type: bannerActionTypes.GET_BANNER_FAILED,
	payload: error,
})
