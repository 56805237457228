import React, { useEffect, useCallback, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Container } from "reactstrap";
import Swalt from "sweetalert2";

import Tienda from "../components/tienda";

import { PaginationItem, PaginationLink } from "reactstrap";

import { withAuthentication } from "../firebase/session";

import getDomain from "../helpers/getDomain";

import { selectProductosArray } from "../redux/tienda/selectors";

import * as tiendaActions from "../redux/tienda/actions";
import * as userActions from "../redux/user/actions";

const ContainerTienda = (props) => {
  const { comprado, comprando, errorMessageCompra } = useSelector(
    (state) => state.tienda
  );
  const productosArray = useSelector(selectProductosArray);
  const { firebase } = props;
  const user = useSelector((state) => state.user.user);
  const token = useSelector((state) => state.user.token);
  const [indexPage, setIndexPage] = useState(0);
  const [disableNext, setDisableNext] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const loadTienda = useCallback(() => {
    firebase
      .getRefreshToken()
      .then((token) => {
        dispatch(tiendaActions.fetchTiendaStart(token));
        dispatch(userActions.fetchUserDataStart(token));
        dispatch(userActions.refreshToken(token));
      })
      .catch((error) => {
        // console.log("error en citas getting token", error);
      });
  }, [dispatch, firebase]);

  const selectPage = useCallback((page) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setIndexPage(page);
  }, []);

  const canjearProducto = useCallback(
    (producto) => {
      if (producto.rCosto > user.puntos) {
        Swalt.fire({
          titleText: "No tienes suficientes puntos",
          icon: "warning",
        });
      } else {
        dispatch(
          tiendaActions.comprarRegaloStart({
            token: token,
            regaloId: producto.rId,
          })
        );
      }
    },
    [dispatch, token, user.puntos]
  );

  useEffect(() => {
    if (indexPage === productosArray.length - 1) setDisableNext(true);
  }, [disableNext, indexPage, productosArray.length]);

  useEffect(() => {
    if (comprado && !comprando) {
      Swalt.fire({
        titleText: "Regalo comprado con exito",
        icon: "success",
        showConfirmButton: true,
        confirmButtonText: "Ver mis Regalos",
        showCancelButton: true,
        cancelButtonText: "Cerrar",
        reverseButtons: true,
        onClose: () => {
          dispatch(tiendaActions.cleanAfterComprar());
          dispatch(userActions.userFetchBasicStart(token));
        },
      }).then((result) =>
        result.value ? history.push(`/${getDomain()}/regalos`) : null
      );
    }
  }, [comprado, dispatch, comprando, history, token]);

  useEffect(() => {
    if (errorMessageCompra) {
      Swalt.fire({
        titleText: "No tienes suficientes puntos",
        text: errorMessageCompra || "Error al Comprar",
        icon: "warning",
        onClose: () => {
          dispatch(tiendaActions.cleanAfterComprar());
        },
      });
    }
  }, [errorMessageCompra, dispatch]);

  useEffect(() => {
    loadTienda();
  }, [loadTienda]);

  const NoData = (
    <React.Fragment>
      <div>
        <h3 className="mt-5" align="center">
          No hay productos disponibles
        </h3>
      </div>
    </React.Fragment>
  );

  const renderPaginationItems = !productosArray.length
    ? null
    : productosArray.map((value, key) => {
        return (
          <PaginationItem key={key} active={indexPage === key}>
            <PaginationLink onClick={() => selectPage(key)}>
              {key + 1}
            </PaginationLink>
          </PaginationItem>
        );
      });

  const containerPagination = (
    <nav aria-label="Page navigation example" className="mt-5">
      <ul className="pagination justify-content-center">
        <PaginationItem disabled={indexPage === 0}>
          <PaginationLink previous onClick={() => selectPage(indexPage - 1)} />
        </PaginationItem>
        {renderPaginationItems}
        <PaginationItem>
          <PaginationLink
            next
            disabled={indexPage === productosArray.length - 1}
            onClick={() => selectPage(indexPage + 1)}
          />
        </PaginationItem>
      </ul>
    </nav>
  );
  return (
    // <Container className="p-3">
    //   <Row className="mb-3 mt-3 ">
    //     <Col xs="12" sm="12" md="12">
    //       <div className=" card  border-dark shadow-sm ">
    //         <h1 className="text-center text-capitalize m-0 m-1">
    //           <span className="text-primary border-dark">
    //             {" "}
    //             {user.puntos / 100}
    //           </span>{" "}
    //           puntos
    //         </h1>
    //       </div>
    //     </Col>
    //   </Row>
    //   {productosArray.length ? (
    //     <React.Fragment>
    //       {containerPagination}
    //       <Tienda
    //         productos={productosArray[indexPage]}
    //         canjearProducto={canjearProducto}
    //       />
    //       {containerPagination}
    //     </React.Fragment>
    //   ) : (
    //     NoData
    //   )}
    // </Container>
    <>
      <section className="mb-16 ">
        <div className="bg-[#29296a]">
          <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
              <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-white sm:text-4xl md:mx-auto">
                Tienda de puntos
              </h2>
              <p className="text-base text-gray-400 md:text-lg">
                Accede a los mejores descuentos con tu Puntos Loyalty en todos
                los establecimientos afiliados.
              </p>
            </div>
          </div>
        </div>
        <div className="relative px-4 sm:px-0 -mt-12">
          <div className="absolute inset-0 bg-[#29296a] h-1/2" />
          <div className="relative grid mx-auto overflow-hidden bg-white divide-y rounded shadow sm:divide-y-0 sm:divide-x sm:max-w-screen-sm sm:grid-cols-1 lg:max-w-screen-md">
            <div className="inline-block  p-4 text-center">
              <span className="text-4xl font-extrabold text-blue-600 md:text-5xl">
                {user.puntos / 100}
              </span>
              <p className="mt-3 font-bold tracking-wide text-gray-800 text-3xl">
                Puntos
              </p>
            </div>
          </div>
        </div>
      </section>

      <Container className="p-3">
        {productosArray.length ? (
          <React.Fragment>
            <Tienda
              productos={productosArray[indexPage]}
              canjearProducto={canjearProducto}
            />
            {containerPagination}
          </React.Fragment>
        ) : (
          NoData
        )}
      </Container>
    </>
  );
};

export default withRouter(withAuthentication(ContainerTienda));
