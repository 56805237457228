import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Swalt from "sweetalert2";
import { useFormik } from "formik";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  Row,
  Col,
  Progress,
  FormFeedback
} from "reactstrap";

import { withFirebase } from "../../firebase/context";

import getDomain from "../../helpers/getDomain";

import * as userActions from "../../redux/user/actions";

// TODO: mostrar mensajes de error

const ValidateUser = props => {
  const history = useHistory();
  const [send, setSend] = useState(false);

  const { isValidUser, isValidatingUser, errorMessageUserValid } = useSelector(
    state => state.user
  );
  const dispatch = useDispatch();

  const initialValues = {
    cedula: props.user.cedula || "",
    celular: props.user.celular || ""
  };

  const validate = values => {
    const errors = {};
    if (!values.cedula) {
      errors.cedula = "campo obligatorio";
    }
    if (!values.celular) {
      errors.celular = "campo obligatorio";
    }
    return errors;
  };

  const onSubmit = values => {
    setSend(true);
    dispatch(userActions.userValidateStart(values));
  };

  useEffect(() => {
    if (isValidUser) {
      setSend(false);
      Swalt.fire({
        titleText: "Exito",
        text: "Usuario validado con exito",
        icon: "success",
        onClose: () => {
          dispatch(userActions.userValidateClean());
          // dispatch(userActions.userValidateFetchStart(user));
          history.push(`/${getDomain()}/finalizar-registro/user`);
        }
      });
    }
  }, [isValidUser, dispatch, history]);

  useEffect(() => {}, [send]);

  useEffect(() => {
    if (errorMessageUserValid) {
      setSend(false);
      Swalt.fire({
        titleText: "Error",
        text: errorMessageUserValid || "Credenciales no coinciden",
        icon: "warning",
        onClose: () => dispatch(userActions.userValidateClean())
      });
    }
  }, [errorMessageUserValid, dispatch]);

  const formik = useFormik({ initialValues, onSubmit, validate });

  return (
    <div className="finalizar-registro-container">
      <Row>
        <Col sm="12" md={{ size: 4, offset: 4 }}>
          <Row>
            <Col sm="12" md="12">
              <h4 align="center">Finalizar Registro</h4>
            </Col>
          </Row>
          <Col sm="12" md="12">
            {isValidatingUser ? (
              <Progress animated color="success" value="100" />
            ) : (
              <div></div>
            )}
          </Col>
          <Form onSubmit={formik.handleSubmit}>
            <Card body>
              <Row>
                <Col md="12" sm="12">
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <Label for="cedula">Cédula</Label>
                        <Input
                          type="cedula"
                          id="cedula"
                          name="cedula"
                          {...formik.getFieldProps("cedula")}
                          invalid={
                            !!(formik.errors.cedula && formik.touched.cedula)
                          }
                          placeholder="Escribe tu Cedula"
                        ></Input>
                        <FormFeedback>{formik.errors.cedula}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup>
                        <Label for="celular">Telefono</Label>
                        <Input
                          type="celular"
                          id="celular"
                          name="celular"
                          {...formik.getFieldProps("celular")}
                          invalid={
                            !!(formik.errors.celular && formik.touched.celular)
                          }
                          placeholder="Escribe tu celular"
                        ></Input>
                        <FormFeedback>{formik.errors.celular}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Button type="submit" color="primary" block>
                    Validar Usuario
                  </Button>
                  <Row>
                    <br />
                    <br />
                    <br />
                  </Row>
                </Col>
              </Row>
            </Card>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default withFirebase(ValidateUser);
