export default {
  FETCH_CITAS_USUARIO_START: "FETCH_CITAS_USUARIO_START",
  FETCH_CITAS_USUARIO_SUCCESS: "FETCH_CITAS_USUARIO_SUCCESS",
  FETCH_CITAS_USUARIO_FAILED: "FETCH_CITAS_USUARIO_FAILED",
  AGENDAR_CITA_START: "AGENDAR_CITA_START",
  AGENDAR_CITA_SUCCESS: "AGENDAR_CITA_SUCCESS",
  AGENDAR_CITA_FAILED: "AGENDAR_CITA_FAILED",
  FETCH_CITAS_CATEGORIA_START: "FETCH_CITAS_CATEGORIA_START",
  FETCH_CITAS_CATEGORIA_SUCCESS: "FETCH_CITAS_CATEGORIA_SUCCESS",
  FETCH_CITAS_CATEGORIA_FAILED: "FETCH_CITAS_CATEGORIA_FAILED",
  FETCH_CITAS_DISPONIBILIDAD_START: "FETCH_CITAS_DISPONIBILIDAD_START",
  FETCH_CITAS_DISPONIBILIDAD_SUCCESS: "FETCH_CITAS_DISPONIBILIDAD_SUCCESS",
  FETCH_CITAS_DISPONIBILIDAD_FAILED: "FETCH_CITAS_DISPONIBILIDAD_FAILED"
};
