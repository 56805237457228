import axios from "axios";

import getDomain from "../../helpers/getDomain";

import {initializeApp} from "../../firebase/index";
//obtiene todos lo datos referentes al usuario incluyendo direcciones, regalos, historial
export const fetchUser = async token => {
  try {
    if (!token) throw new Error("Se necesita token");

    const { data } = await axios.get(`/${getDomain()}/usuarios/all/${token}`);

    if (!data.ok) throw Error("Error al consultar datos del usuario");
    return data.user;
  } catch (error) {
    // console.log("ALGO AQUI")
    if (error.code === "auth/id-token-expired") throw error;
  }
};

//consulta los regalos del usuario
export const fetchRegalos = async token => {
  try {
    if (!token) throw new Error("Se necesita token");

    const { data } = await axios.get(
      `/${getDomain()}/usuarios/${token}/regalos`
    );

    if (!data.ok) throw Error("Error al consultar datos tus regalos");

    return data.regalos || [];
  } catch (error) {
    if (error.code === "auth/id-token-expired") throw error;
  }
};

//obtiene los productos disponibles en la tienda
export const fetchTienda = async token => {
  try {
    //console.log("Fetch de productos de tienda puntos ");
    if (!token) throw new Error("Se necesita token");
    const { data } = await axios.get(`/${getDomain()}/tienda/${token}`);

    return data.productos;
  } catch (error) {
    if (error.code === "auth/id-token-expired") {
      throw new Error("Token expirado");
    }
    throw error;
  }
};

//realiza la compra de un regalo
export const comprarRegalo = async (token, regaloId) => {
  try {
    //console.log("Fetch de productos de tienda puntos ");
    if (!token || !regaloId) throw new Error("Se necesita token");
    const { data } = await axios.post(
      `/${getDomain()}/regalos/${token}/${regaloId}/comprar`
    );

    if (!data.ok) throw new Error("No se pudo comprar regalo");
    return data;
  } catch (error) {
    if (error.code === "auth/id-token-expired") {
      throw new Error("Token expirado");
    }
    throw error;
  }
};

//Realiza el canjeo de regalo y retorna el código de cupón
export const canjearRegalo = async (token, regaloId) => {
  try {
   // console.log("Fetch de canjear Productos ");
    if (!token || !regaloId) throw new Error("Se necesita token");
    const { data } = await axios.post(
      `/${getDomain()}/regalos/${token}/${regaloId}/canjear`
    );

    if (!data.ok) throw new Error("No se pudo canjear regalo");
    return data.codigo;
  } catch (error) {
    // console.log(error);
    if (error.code === "auth/id-token-expired") {
      throw new Error("Token expirado");
    }
    throw error;
  }
};

// consulta solo los datos básicos del usuario
export const fetchOnlyUser = async token => {
  try {
    if (!token) throw new Error("Se necesita token");

    const { data } = await axios.get(`/${getDomain()}/usuarios/${token}`);
    if (data.CodRes == 6){
      // console.log("Vamos a volver a registrarlo");
      // console.log("===========================");
      try {
        var fir = initializeApp
  
        var displayName = (fir.app.auth().currentUser.displayName != null)?fir.app.auth().currentUser.displayName.split(" ") : ["",""];
        var firstName = displayName[0];
        var lastName = (displayName.length > 1)? displayName[1] : "";
          var user = {
        UsuarioName: firstName,
        Usuariolastname: lastName,
        UsuarioCorreo: fir.app.auth().currentUser.email,
        UsuarioTelefono: "",
        UsuarioCodigoReferido: "",
        UsuarioTipoID: "",
        UsuarioNumID: "",
        UsuarioBirth: "",
        UsuarioGender: "",
        UsuarioToken: await fir.app.auth().currentUser.getIdToken(),
        UsuarioDeviceOS: ""
      }
      // console.log(user);
      await apiUserCreate(user);
      return fetchOnlyUser(token)
      } catch (error) {
        console.log(error);
      }
     
      // console.log("===========================");
     
      // getCurrentUser().then(user => {
      //   console.log(user);
      // }).catch(error => {
      //   console.log(error);
      // })
      // token
    
    }
    if (!data.ok) throw Error("Error al consultar datos del usuario");
    
    return data.user;
  } catch (error) {
    if (error.code === "auth/id-token-expired") throw error;
    
  }
};

const apiUserCreate = user => {
  return axios
    .post(`/${getDomain()}/usuarios/fix`, user)
    .then(({ data }) => data)
    .catch(error => {
      throw new Error(error.message ? error.message : "Error al cear usuario");
    });
};