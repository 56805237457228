import { userActionTypes } from "./types";

//proceso de iniciar sesion
export const userSignInStart = dto => ({
  type: userActionTypes.USER_SIGNIN_START,
  payload: dto
});

export const userSignInSuccess = user => ({
  type: userActionTypes.USER_SIGNIN_SUCCESS,
  payload: user
});

export const userSignInFailed = error => ({
  type: userActionTypes.USER_SIGNING_FAILED,
  payload: error
});

//proceso de crear usuario
export const userSignUpStart = dto => ({
  type: userActionTypes.USER_SIGNUP_START,
  payload: dto
});

export const userSignUpSuccess = user => ({
  type: userActionTypes.USER_SIGNUP_SUCCESS,
  payload: user
});

export const userSignUpFailed = error => ({
  type: userActionTypes.USER_SIGNUP_FAILED,
  payload: error
});

//validar datos de correo, documento y telefono en caso de que existan
export const validarUserAppStart = dto => ({
  type: userActionTypes.REGISTRO_VALIDAR_USUARIO_APP_START,
  payload: dto
});

export const validarUserAppSuccess = user => ({
  type: userActionTypes.REGISTRO_VALIDAR_USUARIO_APP_SUCCESS,
  payload: user
});

export const validarUserAppFailed = error => ({
  type: userActionTypes.REGISTRO_VALIDAR_USUARIO_APP_FAILED,
  payload: error
});

//proceso de cerra sesion
export const userLogoutStart = firebase => ({
  type: userActionTypes.USER_LOGOUT_START,
  payload: firebase
});

export const userLogoutSuccess = () => ({
  type: userActionTypes.USER_LOGOUT_SUCCESS
});

export const userLogoutFailed = error => ({
  type: userActionTypes.USER_LOGOUT_FAILED,
  payload: error
});

//seteo de mensaje a null para nuevos procesos
export const userSuccessMessageToNull = () => ({
  type: userActionTypes.USER_SUCCESS_MESSAGE_TO_NULL
});

export const userErrorMessgeToNull = () => ({
  type: userActionTypes.USER_ERROR_MESSAGE_TO_NULL
});

//proceso de consultar data del usuario
export const fetchUserDataStart = token => ({
  type: userActionTypes.USER_FETCH_DATA_START,
  payload: token
});

export const fetchUserDataSucces = user => ({
  type: userActionTypes.USER_FETCH_DATA_SUCCESS,
  payload: user
});

export const fetchUserDataFailed = error => ({
  type: userActionTypes.USER_FETCH_DATA_FAILED,
  payload: error
});

export const fetchRegalosStart = token => ({
  type: userActionTypes.USER_FETCH_REGALOS_START,
  payload: token
});

export const fetchRegalosSuccess = regalos => ({
  type: userActionTypes.USER_FETCH_REGALOS_SUCCESS,
  payload: regalos
});

export const fetchRegalosFailed = error => ({
  type: userActionTypes.USER_FETCH_REGALOS_FAILED,
  payload: error
});

export const userFetchBasicStart = token => ({
  type: userActionTypes.USER_FETCH_BASIC_START,
  payload: token
});

export const userFetchBasicSuccess = user => ({
  type: userActionTypes.USER_FETCH_BASIC_SUCCESS,
  payload: user
});

export const userFetchBasicFailed = error => ({
  type: userActionTypes.USER_FETCH_BASIC_FAILED,
  payload: error
});

export const userValidateStart = dto => ({
  type: userActionTypes.USER_VALIDATE_START,
  payload: dto
});

export const userValidateSuccess = dto => ({
  type: userActionTypes.USER_VALIDATE_SUCCESS,
  payload: dto
});

export const userValidateFailed = error => ({
  type: userActionTypes.USER_VALIDATE_FAILED,
  payload: error
});

export const userValidateClean = () => ({
  type: userActionTypes.USER_VALIDATE_CLEAN
});

export const userValidateFetchStart = dto => ({
  type: userActionTypes.USER_VALIDATE_FETCH_START,
  payload: dto
});

export const userValidateFetchSuccess = dto => ({
  type: userActionTypes.USER_VALIDATE_FETCH_SUCCESS,
  payload: dto
});

export const userValidateFetchFailed = error => ({
  type: userActionTypes.USER_VALIDATE_FETCH_FAILED,
  payload: error
});

export const userValidateUpdateStart = dto => ({
  type: userActionTypes.USER_VALIDATE_UPDATE_START,
  payload: dto
});

export const userValidateUpdateSuccess = () => ({
  type: userActionTypes.USER_VALIDATE_UPDATE_SUCCESS
});

export const userValidateUpdateFailed = error => ({
  type: userActionTypes.USER_VALIDATE_UPDATE_FAILED,
  payload: error
});

export const userValidateUpdateClean = () => ({
  type: userActionTypes.USER_VALIDATE_UPDATE_CLEAN
});

export const refreshToken = token => ({
  type: userActionTypes.USER_REFRESH_TOKEN,
  payload: token
});

export const setCupon = cupon => ({
  type: userActionTypes.USER_SETCUPON,
  payload: cupon
});

export const deleteCupon = cupon => ({
  type: userActionTypes.USER_DELETECUPON
});
