import { validationRegex } from "../../../helpers/validationRegex";
import { validateUser } from "../../../api/user/signUpUser";
//modulo de validacion de campos del formulario

const initialValues = {
  email: "",
  nombre: "",
  apellido: "",
  fecha: "",
  documento: "",
  celular: "",
  genero: "",
  terminos: false
};

const bodyTest = {
  UsuarioName: "",
  Usuariolastname: "",
  UsuarioCorreo: "",
  UsuarioTelefono: "",
  UsuarioCodigoReferido: "",
  UsuarioTipoID: "",
  UsuarioNumID: "",
  UsuarioBirth: "",
  UsuarioGender: "",
  UsuarioToken: "",
  UsuarioDeviceOS: ""
};
const onSubmit = values => {};

const validate = (values, ...rest) => {
  const errors = {};
  const actualYear = new Date().getFullYear();
  if (!values.nombre) {
    errors.nombre = "Campo requerido";
  } else if (values.nombre.length > 64) {
    errors.nombre = "debe contener menos de 64 caracteres";
  }

  if (!values.celular) {
    errors.celular = "Campo requerido";
  }

  if (!values.apellido) {
    errors.apellido = "Campo requerido";
  } else if (values.apellido.length > 64) {
    errors.apellido = "debe contener menos de 64 caracteres";
  }

  if (!values.terminos) {
    errors.terminos = "debe aceptar los términos y condiciones";
  }
  if (values.terminos === false) {
    errors.terminos = "debe aceptar los términos y condiciones";
  }

  if (
    values.genero !== "Masculino" &&
    values.genero !== "Femenino" &&
    values.genero !== "otro"
  ) {
    errors.genero = "Campo Debe seleccionar un genero";
  }

  if (!values.email) {
    errors.email = "Campo requerido";
  } else if (!validationRegex.validEmail.test(values.email)) {
    errors.email = "Email no valido";
  }

  if (!values.isEmail) {
    if (validationRegex.validEmail.test(values.email)) {
      bodyTest.UsuarioCorreo = values.email;
      bodyTest.UsuarioTelefono = values.celular;
      bodyTest.UsuarioNumID = values.documento;
      validateUser(bodyTest)
        .then(({ data }) => {
          if (data.fields.email === true) {
            errors.email = "Este Correo ya esta en uso";
          }
        })
        .catch(error => {
          throw error;
        });
    }
  }

  if (!values.fecha) {
    errors.fecha = "requerido";
  } else if (parseInt(values.fecha.split("-")[0]) < actualYear - 90) {
    errors.fecha = "edad incorrecta";
  } else if (parseInt(values.fecha.split("-")[0]) > actualYear - 12) {
    errors.fecha = "debes tener almenos 13 años";
  }

  return errors;
};

export default {
  initialValues,
  onSubmit,
  validate
};
