import axios from "axios";
import getDomain from "../../helpers/getDomain";

import formatUserData from "../../helpers/formatUserData";

export const validateUser = async user => {
  try {
    const response = await axios.post(
      `/${getDomain()}/usuarios/verificar`,
      user
    );
    return response;
  } catch (error) {
    throw new Error(error.message || "error al validar usuario");
  }
};

const apiUserCreate = user => {
  return axios
    .post(`/${getDomain()}/usuarios`, user)
    .then(({ data }) => data)
    .catch(error => {
      throw new Error(error.message ? error.message : "Error al crear usuario");
    });
};

export const validateUserApp = async user => {
  try {
    const errorFields = new Error();

    const userFormat = formatUserData(user);

    const validate = await validateUser(userFormat);
    //console.log(validate);
    if (validate.data.fields) {
      const { fields } = validate.data;
      errorFields.code = 101;
      errorFields.message = "datos ya estan en uso";

      fields.celular = fields.celular ? "Numero En uso por otra cuenta" : "";
      fields.cedula = fields.cedula ? "En uso por otra cuenta" : "";
      fields.email = fields.email ? "Email en uso por otra cuenta" : "";
      errorFields.stack = fields;
      throw errorFields;
    }
    return user;
  } catch (error) {
    throw error;
  }
};

export const validateUserFireBase = async user => {};

export default async dto => {
  try {
    const { firebase, user } = dto;

    const userFormat = formatUserData(user);
    const newUser = await firebase.createUserWithEmailAndPassword(
      user.email,
      user.password1
    );
    // const user1 = firebase.currentUser;
    await  firebase.updateProfile({
      displayName: user.nombre + " " + user.apellido
    })
    // await firebase.updateDisplayName(newUser.user.uid, user.nombre);
    userFormat.UsuarioToken = newUser.user.ma;
    user.token = newUser.user.ma;
    await apiUserCreate(userFormat);

    return {
      user,
      firebase
    };
  } catch (error) {
    // console.log(error.stack);
    if (error.code === "auth/wrong-password")
      error.message = "Contraseña incorrecta";
    if (error.code === "auth/email-already-in-use")
      error.message = "correo esta en uso";
    if (error.code === "auth/user-not-found")
      error.message = "El usuario no existe";
    if (error.message === "") error.message = "Error al iniciar sesión";
    // console.log(error.code);
    if (error.code === 101) {
      // console.log("aqui error personal");
      throw error;
    }

    throw error;
  }
};
