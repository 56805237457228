import React, { useCallback, useEffect, useState } from "react";

import { useFormik } from "formik";

import { useSelector, useDispatch } from "react-redux";

import SpinnerWrapper from "../../utils/spinnerWrapper";

import formikConfig from "./formikConfig";

import { selectCitaData } from "../../../redux/citas/selectors";
import {
  fetchCategoriasStart,
  fetchDisponibilidadStart,
  agendarStart
} from "../../../redux/citas/actions";

import { randomKey } from "../../../helpers";

import { useComponentDidMount } from "../../../hooks";

import {
  Form,
  Button,
  Row,
  Col,
  Input,
  FormGroup,
  FormFeedback,
  Label,
  Spinner
} from "reactstrap";

const alignCenter = {
  size: 5,
  offset: 5
};
const NewCita = ({ toggle }) => {
  const dispatch = useDispatch();
  const token = useSelector(state => state.user.token);
  const { isFetchingCategorias, isFetchingDisponibilidad } = useSelector(
    ({ citas }) => citas
  );
  const [categoria, setCatgoria] = useState("");
  const isMounted = useComponentDidMount();

  const [disable, setDisable] = useState(false);

  const { categorias, disponibilidad } = useSelector(selectCitaData);

  const onSubmit = useCallback(
    (values, action) => {
      dispatch(agendarStart({ token, ...values }));

      // console.log(action);
      action.setSubmitting(false);
      action.resetForm(formikConfig.initialValues);
    },
    // eslint-disable-next-line
    [dispatch, token]
  );

  //iniciar config de formik
  formikConfig.onSubmit = onSubmit;
  const formik = useFormik(formikConfig);

  useEffect(() => {
    dispatch(fetchCategoriasStart(token));
  }, [dispatch, token]);

  useEffect(() => {
    if (formik.values.fecha && !formik.errors.fecha)
      dispatch(fetchDisponibilidadStart({ token, ...formik.values }));
    // eslint-disable-next-line
  }, [formik.values.fecha, dispatch, token]);

  //verificar si cambia de categoria y reinicia el formuluario hasta fecha
  useEffect(() => {
    if (formik.values.categoria) {
      if (formik.values.categoria !== categoria) {
        setCatgoria(formik.values.categoria);
        formik.setFieldValue("fecha", "");
        formik.setFieldValue("slot", "");
        // console.log("cambio de formik", formik.values);
      }
    }
    // eslint-disable-next-line
  }, [formik.values.categoria, categoria]);

  //validar el estado del formulario para activar el boton de agendar

  const evaluarForm = useCallback(dto => {
    return Object.keys(dto).every(key => {
      if (key === "comentarios" || key === "status") return true;
      return !!dto[key];
    });
  }, []);

  //verfica el estado el form y lo actualiza si los campos requeridos estan completos
  useEffect(() => {
    if (isMounted.current) {
      if (evaluarForm(formik.values)) setDisable(evaluarForm(formik.values));
      else setDisable(false);
    }
  }, [formik.values, evaluarForm, isMounted]);

  const renderDisponibilidad = (
    <React.Fragment>
      {disponibilidad.length ? (
        <React.Fragment>
          <option value="" key={randomKey()}>
            Seleccione su Hora de cita...
          </option>
          {disponibilidad.map((value, key) => (
            <option value={value.slot} key={`${key}${randomKey()}`}>
              {value.slot}
            </option>
          ))}
        </React.Fragment>
      ) : (
        <option>No hay disponibilidad de citas</option>
      )}
    </React.Fragment>
  );

  const renderCategoria = (
    <React.Fragment>
      {categorias.length ? (
        <React.Fragment>
          <option value="" key={randomKey()}>
            Seleccione una categoria...
          </option>
          {categorias.map((value, key) => (
            <option value={value.categoria} key={`${key}${randomKey()}`}>
              {value.categoria}
            </option>
          ))}
        </React.Fragment>
      ) : (
        <option>No hay Categorias</option>
      )}
    </React.Fragment>
  );

  //agrega spiner a los componentes dentro
  const ContainerSpiner = Children => (
    <React.Fragment>
      {Children.isFetching ? (
        <Col sm={alignCenter} md={alignCenter}>
          <Spinner />
        </Col>
      ) : (
        Children.children
      )}
    </React.Fragment>
  );

  const CategoriasComponent = categorias.length ? (
    <ContainerSpiner isFetching={isFetchingCategorias}>
      <FormGroup>
        <Label for="categoria">Categoria</Label>
        <Input
          type="select"
          id="categoria"
          name="categoria"
          {...formik.getFieldProps("categoria")}
          invalid={!!(formik.errors.categoria && formik.touched.categoria)}
          placeholder="Selecione una categoria"
        >
          {renderCategoria}
        </Input>

        <FormFeedback>{formik.errors.categoria}</FormFeedback>
      </FormGroup>
    </ContainerSpiner>
  ) : (
    <div className="alert alert-warning m-1 mb-2" role="alert">
      No hay Categorias Disponibles
    </div>
  );

  const DisponibilidadComponent = disponibilidad.length ? (
    <ContainerSpiner isFetching={isFetchingDisponibilidad}>
      <FormGroup>
        <Label for="slot">Horario de Citas</Label>
        <Input
          type="select"
          id="slot"
          name="slot"
          {...formik.getFieldProps("slot")}
          invalid={!!(formik.errors.slot && formik.touched.slot)}
          placeholder="Selecione una disponibilidad"
        >
          {renderDisponibilidad}
        </Input>

        <FormFeedback>{formik.errors.disponbilidad}</FormFeedback>
      </FormGroup>
    </ContainerSpiner>
  ) : (
    <div className="alert alert-warning m-1 mb-2" role="alert">
      No hay citas Disponibles para esta fecha
    </div>
  );

  const FechaInput = (
    <FormGroup>
      <Label htmlFor="fecha">Seleccione la fecha para la cita</Label>
      <Input
        type="date"
        id="disponibilidad"
        name="fecha"
        {...formik.getFieldProps("fecha")}
        invalid={!!(formik.errors.fecha && formik.touched.fecha)}
      />
      <FormFeedback>{formik.errors.fecha}</FormFeedback>
    </FormGroup>
  );

  const ComentarioInput = (
    <FormGroup>
      <Label htmlFor="comentarios">Comentarios</Label>

      <Input
        type="textarea"
        id="comentarios"
        name="comentarios"
        {...formik.getFieldProps("comentarios")}
        invalid={!!(formik.errors.comentarios && formik.touched.comentarios)}
      />
      <FormFeedback>{formik.errors.comentarios}</FormFeedback>
    </FormGroup>
  );

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row>
        <Col md="12">
          <SpinnerWrapper isFetching={isFetchingCategorias}>
            {CategoriasComponent}
          </SpinnerWrapper>
        </Col>
        <Col md="12">{formik.values.categoria ? FechaInput : null}</Col>
        <Col md="12">
          <SpinnerWrapper isFetching={isFetchingDisponibilidad}>
            {formik.values.fecha ? DisponibilidadComponent : null}
          </SpinnerWrapper>
        </Col>
        <Col md="12">{formik.values.slot ? ComentarioInput : null}</Col>
      </Row>
      <Row>
        <Col>
          <Button
            type="submit"
            color="primary"
            addon="true"
            disabled={!disable}
          >
            Agendar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default React.memo(NewCita);
