import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { withAuthentication } from "../../firebase/session";
import { userLogoutStart } from "../../redux/user/actions";

import getDomain from "../../helpers/getDomain";
import { getLogo } from "../../helpers/logos";
import domainSettings from "../../helpers/domainSettings";

import Routes from "../../settings/routes";

import useWindows from "../../hooks/windows-dimension";

import "./index.css";
import "../../settings/styles/demo.css";
import "../../settings/styles/merkadoo.css";
import "../../settings/styles/cafeduran.css";
import "../../settings/styles/motatan.css";

const Navigation = (props) => {
  const { firebase, authUser } = props;
  const dispatch = useDispatch();

  const { show } = useSelector((state) => state.navigation);
  const { width } = useWindows();
  const [showIcon, setShowIcon] = useState(false);
  const [navWitdth, setNavWidth] = useState(0);

  const toggleIcon = useCallback(() => setShowIcon(!showIcon), [showIcon]);

  //const [background, setBackground] = useState("black-backgound");

  const toggleNav = useCallback(() => {
    if (navWitdth >= 250) setNavWidth(0);
    else setNavWidth(250);
    //if (responsive === "topnav") setResponsive(`${responsive} responsive`);
    // else setResponsive("topnav");
  }, [navWitdth]);

  useEffect(() => {
    if (width < 600) setShowIcon(true);
    else setShowIcon(false);
  }, [width, toggleIcon]);
  //setBackground(domainSettings[getDomain()].navbar.background)

  const clickLink = useCallback(
    (e) => {
      e.preventDefault();
      toggleNav();
    },
    [toggleNav]
  );

  const cerrarSesion = useCallback(
    (e) => {
      e.preventDefault();
      firebase.auth
        .signOut()
        .then(function () {
          dispatch(userLogoutStart());
        })
        .catch(function (error) {
          // console.log("Error al Cerrar sesion");
        });
    },
    [dispatch, firebase]
  );

  //effects

  //carga los links de que tendra acceso el cliente
  const Navs = getDomain()
    ? Routes.clientes[getDomain()].map((value, key) => {
        if (Routes.navLinks[value]) {
          return (
            <Link to={Routes.navLinks[value].link} key={key}>
              {Routes.navLinks[value].title}
            </Link>
          );
        } else return null;
      })
    : null;

  const linksUser = !authUser ? null : (
    <React.Fragment>
      {Navs}

      {width < 600 ? (
        <Link to="" onClick={cerrarSesion}>
          Cerra Sesion
        </Link>
      ) : (
        <div className="left-nav">
          <Link to="" onClick={cerrarSesion}>
            Cerra Sesion
          </Link>
        </div>
      )}
    </React.Fragment>
  );

  const linksLoging =
    width < 600 ? (
      <React.Fragment>
        <Link to={`/${getDomain()}/inicio`}>Iniciar sesion</Link>
        <Link to={`/${getDomain()}/registro`}>Registro</Link>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <div className="left-nav">
          <Link to={`/${getDomain()}/inicio`}>Iniciar sesion</Link>
          <Link to={`/${getDomain()}/registro`}>Registro</Link>
        </div>
      </React.Fragment>
    );

  const SideNav = () => (
    <div id="mySidenav" className="sidenav" style={{ width: navWitdth }}>
      <button
        type="button"
        className="close closebtn"
        aria-label="Close"
        onClick={clickLink}
      >
        <span aria-hidden="true">&times;</span>
      </button>

      {!show ? null : authUser ? linksUser : linksLoging}
    </div>
  );

  const imageContainer = getLogo(getDomain()) ? (
    <div className="narvbar-image-container">
      <Link
        to={`/${getDomain()}`}
        //className="nav-image-brand img-responsive "
      >
        <img
          src={getLogo(getDomain())}
          alt="Marca"
          className="img-fluid navbar-image ml-2 mt-1"
          // style={{ width: 200, height: autoMergeLevel1 }}
        />
      </Link>
    </div>
  ) : null;

  return (
    <React.Fragment>
      <div
        className={`topnav ${
          getDomain()
            ? domainSettings[getDomain()].navbar.background
            : "demo-background"
        } `}
        id="myTopnav"
      >
        {imageContainer}

        <div className="active"></div>

        <Link to="156156" className="icon" onClick={clickLink}>
          <i className="fa fa-bars"></i>
        </Link>

        {!show ? null : authUser ? linksUser : linksLoging}
      </div>
      <SideNav />
    </React.Fragment>
  );
};

export default withAuthentication(Navigation);
