import React, { useCallback } from "react";

import { Button, Row, Col, Container, CardBody, CardImg } from "reactstrap";
import SpinnerWrapper from "../../utils/spinnerWrapper";

import { comprarRegaloStart } from "../../../redux/tienda/actions";
import { selectIsDoingAsync } from "../../../redux/tienda/selectors";
import { selectUserData } from "../../../redux/user/selectors";
import { useDispatch, useSelector } from "react-redux";

import { utils } from "../../../helpers";

const Modaldata = ({ data, toggle }) => {
  const dispatch = useDispatch();
  const token = useSelector(({ user }) => user.token);
  const { puntos } = useSelector(selectUserData);
  const isDoing = useSelector(selectIsDoingAsync);

  const comprarRegalo = useCallback(() => {
    dispatch(
      comprarRegaloStart({
        token: token,
        regaloId: data.rId
      })
    );
  }, [dispatch, data.rId, token]);

  const noPoints = (
    <p className="alert alert-warning m-1 p-1 text-center font-weight-bold shadow-sm">
      No cuentas con suficientes puntos
    </p>
  );
  return (
    <Container className="overflow-auto card">
      <Row>
        {data ? (
          <Col key={data.rId} sm="12" className="mb-4">
            <CardBody>
              <h5 className="card-title text-center">
                {utils.capitalize(data.rTitulo)}
              </h5>

              <div className="tienda-image-container">
                <CardImg
                  top
                  className="img-fluid tienda-card-image"
                  src={data.rImg}
                  alt={data.rTitulo}
                />
              </div>

              <p className="mt-2 text-dark text-break text-center">
                {data.rDesc}
              </p>
              <p className="tienda-card-puntos text-center">
                Puntos :{" "}
                <span className="text-primary font-weight-bold">
                  {data.rCosto / 100}
                </span>
              </p>

              <div className="tienda-card-footer">
                <SpinnerWrapper isFetching={isDoing}>
                  {puntos < data.rCosto ? (
                    noPoints
                  ) : (
                    <Button
                      color="primary"
                      block
                      onClick={e => comprarRegalo(data)}
                      disabled={puntos < data.rCosto}
                    >
                      Comprar
                    </Button>
                  )}
                </SpinnerWrapper>
              </div>
            </CardBody>
          </Col>
        ) : null}
      </Row>
    </Container>
  );
};

export default React.memo(Modaldata);
