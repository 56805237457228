import React, { useContext } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
//import { useSelector } from "react-redux";

import { AuthUserContext } from "../../firebase/session";

import getDomain from "../../helpers/getDomain";

import Routes from "../../settings/routes";

export const ProtectedRoute = ({
  component: Component,
  tag,

  ...rest
}) => {
  //const user = useSelector(state => state.user);
  const cliente = Routes.clientes[getDomain()];
  const authUser = useContext(AuthUserContext);
  const isAvailable = cliente.find(item => item === tag);

  //console.log(authUser, rest);

  const renderItem = props => {
    if (authUser) {
      if (!isAvailable && tag) {
       // console.log("componente con tag");
        return (
          <Redirect
            to={{
              pathname: `/${getDomain()}/noAccess`,
              state: {
                from: props.location
              }
            }}
          />
        );
      } else {
        return <Component {...props} />;
      }
    } else {
     /*  console.log(
        "********************** redireccionando a iniciar sesion",
        authUser
      ); */
      return (
        <Redirect
          to={{
            pathname: `/${getDomain()}/inicio`,
            state: {
              from: props.location
            }
          }}
        />
      );
    }
  };

  return <Route {...rest} render={renderItem} />;
};

export default withRouter(ProtectedRoute);
