export const logos = {
  merkadoo: "https://s3.us-east-2.amazonaws.com/clau.app.merkdoo/lgoomerk.png",
  glow: "https://dashboard.clau.io/dashboard/app-assets/img/logos/logo-glow.png",
  fitbar: "https://s3.us-east-2.amazonaws.com/migo.app.fitbar/logo_square.jpg",
  jumboplus: "https://jumbodotrecursos.clau.io/1572641078-9504.png",
  panda:
    "https://dashboard.clau.io/dashboard/app-assets/img/logos/logo_panda.png",
  carretillon:
    "https://dashboard.clau.io/dashboard/app-assets/img/logos/logo_supercarnes.png",
  local: "https://cdn-multi.clau.io/1/logo-local.png",
  runningbalboa: "https://recursosdotpiazza.clau.io/logo/piaza.jpeg",
  madison:
    "https://img.invupos.com/clauwebapp/portal%20demo%20logos%20/logo-madison.png",
  todoadollar:
    "https://img.invupos.com/clauwebapp/portal%20demo%20logos%20/Todoadollar%20logo.png",
  demo: "https://dashboard.clau.io/dashboard/app-assets/img/logos/logo.png",
  chickensabroso: "https://cdn-multi.clau.io/153/1647563597-3878.jpeg",
  maagoos: "https://img.clau.io/177.png",
  tosto: "https://img.clau.io/166.png",
  motatan: "https://cdn-multi.clau.io/260/1712759635-5484.png",
  cafeduran: "https://img.clau.io/Portales_Files/Logo%20de%20Epa.png",
  grancombo: "https://img.clau.io/264.jpg",
  keenes: "https://img.clau.io/280.png",
  beep: "https://img.clau.io/307.png",
  alleria: "https://img.clau.io/311.png",
  larosa: "https://cdn-multi.clau.io/171/1735059727-5077.png",
};

export const getLogo = (domain) => logos[domain];
export default getLogo;
