import messagesTypes from "./types";

const INITIAL_STATE = {
  position: "fixed_bottom",
  bg: "",
  icon: "",
  time: 3500,
  show: false,
  text: "",
  type: "toast",
  title: "",
  message: ""
};

export default (currentState = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case messagesTypes.SET_MESSAGE:
      console.info(payload);
      return {
        ...currentState,
        message: payload.message,
        title: payload.title,
        // message: action.payload.message,
        /*         position: action.payload.position || INITIAL_STATE.position,
        type: action.payload.type || "toast",
        time: action.payload.time || INITIAL_STATE.time,
        icon: action.payload.icon || INITIAL_STATE.icon,
        bg: action.payload.bg || INITIAL_STATE.bg, */
        text: payload.text || INITIAL_STATE.text,
        show: true
      };

    case messagesTypes.CLEAR_MESSAGE:
      return {
        ...currentState,
        ...INITIAL_STATE
      };

    case messagesTypes.SHOW_MESSAGE:
      return {
        ...currentState,
        show: true
      };

    case messagesTypes.HIDE_MESSAGE:
      return {
        ...currentState,
        show: false
      };

    default:
      return currentState;
  }
};
