import axios from "axios";

import getDomain from "../../helpers/getDomain";

export async function getCitas(token) {
  try {
    const { data } = await axios.post(`/${getDomain()}/citas/ver-citas`, {
      token
    });
    if (!data.ok) throw new Error("Error al cargar citas");

    return data.citas || [];
  } catch (error) {
    throw error;
  }
}

export async function getCategorias(token) {
  try {
    const { data } = await axios.post(`/${getDomain()}/citas/ver-categorias`, {
      token
    });

    if (!data.ok) throw new Error("Error al cargar categoria de citas");

    return data.categorias || [];
  } catch (error) {}
}

export async function getDisponibilidadxDia(dto) {
  try {
    //console.log(dto);
    if (!dto.fecha || !dto.categoria || !dto.token) {
      throw new Error(
        "Se necesita fecha, categoria  y token para pode enviar esta peticion"
      );
    }

    const { data } = await axios.post(
      `/${getDomain()}/citas/ver-citas/categorias/disponibilidad_dia`,
      {
        ...dto
      }
    );

    if (!data.ok)
      throw new Error("Error al cargar las disponibilidades por dia");

    return data.disponibilidad || [];
  } catch (error) {
    throw error;
  }
}

export async function agendarCita(dto) {
  try {
    if (!dto.fecha || !dto.categoria || !dto.token || !dto.slot) {
      throw new Error(
        "Se necesita fecha, categoria, slot  y token para pode enviar esta peticion"
      );
    }

    const { data } = await axios.post(`/${getDomain()}/citas/agendar-cita`, {
      ...dto
    });

    if (!data.ok) throw new Error("Error al agendar cita");

    return data;
  } catch (error) {
    throw error;
  }
}
