import React, { useState, useCallback, useEffect } from "react";
import {
  Card,
  CardBody,
  CardImg,
  Col,
  Row,
  Modal,
  ModalHeader,
} from "reactstrap";

import { useDispatch } from "react-redux";

import { cleanAfterCanjear } from "../../redux/tienda/actions";

import { utils } from "../../helpers";

import ModalTienda from "./modal-tienda";

import Overlay from "../utils/overlay";

import "./index.css";

const Tienda = (props) => {
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState({});

  const dispatch = useDispatch();

  const toggle = useCallback(() => {
    setModal(!modal);
  }, [modal]);

  const selectProducto = useCallback(
    (producto) => {
      setSelected(producto);
      toggle();
    },
    [toggle]
  );

  useEffect(() => {
    if (!modal) dispatch(cleanAfterCanjear());
  }, [modal, dispatch]);

  return (
    <React.Fragment>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        keyboard
        unmountOnClose
        scrollable
        returnFocusAfterClose={false}
      >
        <ModalHeader toggle={toggle} className="text-center"></ModalHeader>

        <ModalTienda data={selected} toggle={toggle} />
      </Modal>

      <Row>
        {props.productos.map((producto) => (
          <Col
            key={producto.rId}
            sm="12"
            md={{ size: 4, offset: 0 }}
            className="mb-4"
          >
            <Overlay>
              {/* <Card
                className="shadow-sm"
                onClick={(e) => selectProducto(producto)}
                style={{ cursor: "pointer" }}
              >
                <CardBody>
                  <h5 className="card-title text-center ">
                    {utils.truncateText(utils.capitalize(producto.rTitulo), 25)}
                  </h5>

                  <div className="tienda-image-container">
                    <CardImg
                      top
                      className="img-fluid tienda-card-image"
                      src={producto.rImg}
                      alt={producto.rTitulo}
                    />
                  </div>

                  <p className="mt-2 text-dark text-break text-center">
                    {utils.truncateText(producto.rDesc, 25)}
                  </p>
                  <p className="tienda-card-puntos text-center font-weight-bold">
                    Puntos :{" "}
                    <span className="text-primary font-weight-bold">
                      {producto.rCosto / 100}
                    </span>
                  </p>
                </CardBody>
              </Card> */}

              <a
                onClick={(e) => selectProducto(producto)}
                style={{ cursor: "pointer" }}
                class="block rounded-3xl  shadow-sm shadow-indigo-100 border border-gray-800 bg-white shadow-xl transition ease-in-out delay-150 hover:border-pink-500/10 hover:shadow-[#E0E7FF]"
              >
                <img
                  src={producto.rImg}
                  alt={producto.rTitulo}
                  class="h-56 w-full rounded-md object-cover"
                />
                <div class="mt-2 p-4">
                  <dl>
                    <p className="text-xs tracking-wider text-gray-500 uppercase">
                      Descripción
                    </p>
                    <div>
                      <p class="text-xl font-bold text-gray-700">
                        {utils.truncateText(producto.rDesc, 25)}
                      </p>
                    </div>

                    <div className="flex space-x-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-5 h-5 text-[#6B7280]"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0"
                        />
                      </svg>

                      <p class="font-medium text-gray-500">
                        {" "}
                        Puntos :{" "}
                        <span className="text-[#017BFF] font-semibold">
                          {producto.rCosto / 100}
                        </span>
                      </p>
                    </div>
                  </dl>
                </div>
                <div class="flex justify-end -mt-6">
                  <strong class="-mr-[2px] -mb-[2px] inline-flex items-center gap-1 rounded-tl-xl rounded-br-xl bg-green-600 py-1.5 px-3 text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="h-5 w-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                      />
                    </svg>

                    <span class="text-[10px] font-medium sm:text-sm">
                      {utils.truncateText(
                        utils.capitalize(producto.rTitulo),
                        25
                      )}
                    </span>
                  </strong>
                </div>
              </a>
            </Overlay>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  );
};

export default React.memo(Tienda);
