import { call, all, put, takeLatest } from "redux-saga/effects";

import citasTypes from "./types";
import * as citasActions from "./actions";
import {
  getCitas,
  getCategorias,
  agendarCita,
  getDisponibilidadxDia
} from "../../api/citas";

export function* fetchCitasAsync({ payload }) {
  try {
    const citas = yield getCitas(payload);
    yield put(citasActions.fetchCitasSuccess(citas));
  } catch (error) {
    yield put(citasActions.fetchCitasFailed(error.message));
  }
}

export function* fetchCategoriasAsync({ payload }) {
  try {
    const categorias = yield getCategorias(payload);
    yield put(citasActions.fetchCategoriaSuccess(categorias));
  } catch (error) {
    yield put(citasActions.fetchCategoriasFailed(error.message));
  }
}

export function* fetchDisponibilidadAsync({ payload }) {
  try {
    const disponibilidad = yield getDisponibilidadxDia(payload);
    yield put(citasActions.fetchDisponibilidadSuccess(disponibilidad));
  } catch (error) {
    yield put(citasActions.fetchDisponibilidadFailed(error.message));
  }
}

export function* agendarCitaAsync({ payload }) {
  try {
    yield agendarCita(payload);
    yield put(citasActions.agendarSuccess());
  } catch (error) {
    yield put(citasActions.agendarFailed(error.message));
  }
}

// Listeners de saga
export function* fetchCitasListener() {
  yield takeLatest(citasTypes.FETCH_CITAS_USUARIO_START, fetchCitasAsync);
}

export function* fetchCategoriasListener() {
  yield takeLatest(
    citasTypes.FETCH_CITAS_CATEGORIA_START,
    fetchCategoriasAsync
  );
}

export function* fetchDisponibilidadListener() {
  yield takeLatest(
    citasTypes.FETCH_CITAS_DISPONIBILIDAD_START,
    fetchDisponibilidadAsync
  );
}

export function* agendarCitaListener() {
  yield takeLatest(citasTypes.AGENDAR_CITA_START, agendarCitaAsync);
}
export function* citasSaga() {
  yield all([
    call(fetchCitasListener),
    call(fetchCategoriasListener),
    call(fetchDisponibilidadListener),
    call(agendarCitaListener)
  ]);
}
