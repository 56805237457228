import React from "react";
import Favicon from "react-favicon";
import Helmet from "react-helmet";

import { withAuthentication } from "./firebase/session";

import SwitchRouter from "./components/switch";
import Navigation from "./components/navigation";
import ErrorBoundary from "./components/errorBoundary";
import ProgressBar from "./components/utils/progress";

import Worker from "./services/firebase-worker";

import getDomain from "./helpers/getDomain";
import favicon from "./statics/favicon";

import { utils } from "./helpers";
import "./index.css";

function App(props) {
  const domain = getDomain();
  return (
    <ErrorBoundary>
      <Navigation />
      <ProgressBar />

      <SwitchRouter />
      <Worker />
      <Favicon url={favicon} />
      <Helmet>
        <title>{domain ? utils.capitalize(domain) : "Clau.io"}</title>
      </Helmet>
    </ErrorBoundary>
  );
}

export default withAuthentication(App);
