import React from "react";

import { Table, Badge } from "reactstrap";

import { formatDate, utils, randomKey } from "../../../helpers";

import "./index.css";

const TableCitas = ({ citas }) => {
  const color = {
    0: "secondary",
    1: "success",
    5: "danger"
  };
  const mensajeStatus = {
    0: "Por confirmar",
    1: "Confirmada",
    5: "Rechazada"
  };

  const CitaStatus = ({ status }) => {
    return (
      <h6>
        <Badge href="#" pill color={color[status]}>
          {mensajeStatus[status]}
        </Badge>
      </h6>
    );
  };

  const PopOver = ({ text }) => {
    return (
      <div className="custom_tooltip text-break">
        {text ? utils.truncateText(text, 25) : ""}
        <span className="tooltiptext">{text}</span>
      </div>
    );
  };

  const citasList = (
    <React.Fragment>
      {citas.length ? (
        citas.map((value, index) => (
          <tr key={index + randomKey()}>
            <td>{value.categoria}</td>
            <td>{formatDate(value.fecha)}</td>
            <td>{value.slot}</td>
            <td>
              <PopOver text={value.comentarios} />
            </td>
            <td>
              <CitaStatus status={value.status} />
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td className="text-center text-capitalize" colSpan="5">
            No Tienes cita
          </td>
        </tr>
      )}
    </React.Fragment>
  );

  return (
    <Table responsive hover bordered>
      <thead>
        <tr>
          <th>Categoria</th>
          <th>Dia</th>
          <th>Hora</th>
          <th>Comentarios</th>
          <th>Estatus</th>
        </tr>
      </thead>
      <tbody>{citasList}</tbody>
    </Table>
  );
};

export default React.memo(TableCitas);
