import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Redirect } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

import UpdateForm from "../components/validateUser/update";
//import * as userActions from "../redux/user/actions";
import { toggleShowNavigation } from "../redux/navigation/actions";

import getDomain from "../helpers/getDomain";

const ContainerUpdateValidUser = props => {
  const { show } = useSelector(state => state.navigation);
  const { isLoged, user } = useSelector(state => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (show) dispatch(toggleShowNavigation());
    //dispatch(userActions.userValidateFetchStart(user))
    return () => null;
  }, [dispatch, show]);

  const Update =
    user.email || user.genero ? <UpdateForm user={user} /> : <h2>Cargando</h2>;
  return (
    <Container className="mt-4">
      <Row>
        <Col sm="12" md={{ size: 6, offset: 3 }}>
          {isLoged || user.token ? (
            <div>
              <Redirect to={`/${getDomain()}/inicio`} />
            </div>
          ) : (
            Update
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ContainerUpdateValidUser;
