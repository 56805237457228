import React, { useEffect, useCallback } from "react";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link, useLocation } from "react-router-dom";
import { FormFeedback } from "reactstrap";

import SpinnerWrapper from "../utils/spinnerWrapper";
import { withFirebase } from "../../firebase/context";
import formikConfig from "./formikConfig";
import { validarUserAppStart } from "../../redux/user/actions";
import getDomain from "../../helpers/getDomain";
import domainSettings from "../../helpers/domainSettings";

const SignUpForm = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { isLoged, registroErrores, isSignUp } = useSelector(
    (state) => state.user
  );

  const onSubmit = useCallback(
    (values, actions) => {
      dispatch(validarUserAppStart({ firebase: props.firebase, user: values }));
    },
    [dispatch, props.firebase]
  );

  formikConfig.onSubmit = onSubmit;
  const formik = useFormik(formikConfig);

  useEffect(() => {
    if (registroErrores.cedula) {
      formik.setFieldError("documento", registroErrores.cedula);
    }
    if (registroErrores.celular) {
      formik.setFieldError("celular", registroErrores.celular);
    }
    if (registroErrores.email) {
      formik.setFieldError("email", registroErrores.email);
    }
  }, [registroErrores.cedula, registroErrores.celular, registroErrores.email]);

  useEffect(() => {
    const redirectPath = { pathname: `/${getDomain()}/perfil` };
    if (isLoged) {
      history.replace(redirectPath);
    }
  }, [isLoged, history]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const referralCode = params.get('codigoReferido');
    if (referralCode) {
      formik.setFieldValue('codigoReferido', referralCode);
    }
  }, [location.search]);

  const TerminosCondiciones = (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={domainSettings[getDomain()].terminos}
    >
      Ver terminos y condciones
    </a>
  );

  return (
    <>
      <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl">
          <h1 className="text-center text-2xl font-bold text-[#2C2C7D] sm:text-3xl">
            Regístrate
          </h1>

          <p className="mx-auto mt-4 max-w-lg text-center text-gray-500">
            Premia la lealtad de tus clientes y aumenta tus ventas. Todo desde
            una plataforma personalizable y fácil de usar.
          </p>

          <div className="rounded-xl bg-white p-8 mt-12 shadow-lg lg:col-span-3 lg:p-12">
            <form onSubmit={formik.handleSubmit} className="space-y-4">
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                {/* Row 1 */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Nombre
                  </label>

                  <input
                    type="text"
                    id="nombre"
                    {...formik.getFieldProps("nombre")}
                    placeholder="Ingresar su nombre"
                    className="mt-1 w-full py-3 px-4 rounded-full text-sm border border-gray-200 focus:outline-none focus:ring focus:ring-[#282A70]"
                  />
                  <span className="text-red-500 text-sm">
                    {formik.errors.nombre}
                  </span>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Apellido
                  </label>
                  <input
                    type="text"
                    id="apellido"
                    {...formik.getFieldProps("apellido")}
                    placeholder="Ingresar su apellido"
                    className="mt-1 w-full py-3 px-4 rounded-full text-sm border border-gray-200 focus:outline-none focus:ring focus:ring-[#282A70]"
                  />
                  <span className="text-red-500 text-sm">
                    {formik.errors.apellido}
                  </span>
                </div>
                {/* Row 2 */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Email
                  </label>

                  <input
                    type="email"
                    id="email"
                    {...formik.getFieldProps("email")}
                    placeholder="Ingresar su correo"
                    className="mt-1 w-full py-3 px-4 rounded-full text-sm border border-gray-200 focus:outline-none focus:ring focus:ring-[#282A70]"
                  />
                  <span className="text-red-500 text-sm">
                    {formik.errors.email}
                  </span>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Célular
                  </label>

                  <input
                    type="text"
                    id="celular"
                    {...formik.getFieldProps("celular")}
                    placeholder="Ingresar su célular"
                    className="mt-1 w-full py-3 px-4 rounded-full text-sm border border-gray-200 focus:outline-none focus:ring focus:ring-[#282A70]"
                  />
                  <span className="text-red-500 text-sm">
                    {formik.errors.celular}
                  </span>
                </div>
                {/* Row 3 */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Género
                  </label>

                  <select
                    className="mt-1 w-full py-3 px-4 bg-white rounded-full text-sm border border-gray-200 focus:outline-none focus:ring focus:ring-[#282A70]"
                    name="genero"
                    id="genero"
                    {...formik.getFieldProps("genero")}
                  >
                    <option value=""></option>
                    <option value="Masculino">Hombre</option>
                    <option value="Femenino">Mujer</option>
                    <option value="otro">Otro</option>
                  </select>
                  <span className="text-red-500 text-sm">
                    {formik.errors.genero}
                  </span>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Código Referido
                  </label>

                  <input
                    type="text"
                    id="codigoReferido"
                    {...formik.getFieldProps("codigoReferido")}
                    placeholder="Ingresar código de referido"
                    className="mt-1 w-full py-3 px-4 rounded-full text-sm border border-gray-200 focus:outline-none focus:ring focus:ring-[#282A70]"
                  />
                  <span className="text-red-500 text-sm">
                    {formik.errors.codigoReferido}
                  </span>
                </div>
                {/* Row 4 */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Tipo de Documento
                  </label>

                  <select
                    name="tipoDocumento"
                    id="tipoDocumento"
                    {...formik.getFieldProps("tipoDocumento")}
                    className="mt-1 w-full py-3 px-4 bg-white rounded-full text-sm border border-gray-200 focus:outline-none focus:ring focus:ring-[#282A70]"
                  >
                    <option value=""></option>
                    <option value="cedula">Cédula</option>
                    <option value="pasaporte">Pasaporte</option>
                  </select>
                  <span className="text-red-500 text-sm">
                    {formik.errors.tipoDocumento}
                  </span>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Número de Documento
                  </label>

                  <input
                    type="text"
                    id="documento"
                    name="documento"
                    {...formik.getFieldProps("documento")}
                    placeholder="Ingresar número de documento"
                    className="mt-1 w-full py-3 px-4 rounded-full text-sm border border-gray-200 focus:outline-none focus:ring focus:ring-[#282A70]"
                  />
                  <span className="text-red-500 text-sm">
                    {formik.errors.documento}
                  </span>
                </div>
                {/* Row 5 */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Contraseña
                  </label>

                  <input
                    type="password"
                    id="password1"
                    {...formik.getFieldProps("password1")}
                    placeholder="Ingresar su contraseña"
                    autoComplete="new-password"
                    className="mt-1 w-full py-3 px-4 rounded-full text-sm border border-gray-200 focus:outline-none focus:ring focus:ring-[#282A70]"
                  />
                  <span className="text-red-500 text-sm">
                    {formik.errors.password1}
                  </span>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Repetir Contraseña
                  </label>
                  <input
                    type="password"
                    id="password2"
                    {...formik.getFieldProps("password2")}
                    placeholder="Repetir contraseña"
                    autoComplete="new-password"
                    className="mt-1 w-full py-3 px-4 rounded-full text-sm border border-gray-200 focus:outline-none focus:ring focus:ring-[#282A70]"
                  />
                  <span className="text-red-500 text-sm">
                    {formik.errors.password2}
                  </span>
                </div>
                {/* Row 6 */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Fecha de Nacimiento
                  </label>

                  <input
                    type="date"
                    id="fecha"
                    {...formik.getFieldProps("fecha")}
                    className="mt-1 w-full py-3 px-4 rounded-full text-sm border border-gray-200 focus:outline-none focus:ring focus:ring-[#282A70]"
                  />
                  <span className="text-red-500 text-sm">
                    {formik.errors.fecha}
                  </span>
                </div>
              </div>

              <div className="mt-4">
                <div className="flex space-x-2">
                  <input
                    type="checkbox"
                    id="terminos"
                    {...formik.getFieldProps("terminos")}
                    className="h-5 w-5 rounded-md border-gray-200 bg-white shadow-sm"
                  />

                  <p className="text-sm text-gray-500">
                    Al crear una cuenta,{" "}
                    <Link to={`/${getDomain()}/terminos`} className="text-[#2C2C7D] underline">
                      acepta nuestros términos y condiciones
                    </Link>{" "}
                    y{" "}
                    <Link to={`/${getDomain()}/privacidad`} className="text-[#2C2C7D] underline">
                      política de privacidad
                    </Link>
                    .
                  </p>
                </div>
                <div>
                  <span className="-mt-12 text-red-500 text-sm">
                    {formik.errors.terminos}
                  </span>
                </div>
                <SpinnerWrapper isFetching={isSignUp}>
                  <button
                    type="submit"
                    className="inline-flex mt-4 lg:w-full md:w-full sm:w-full w-full items-center justify-center rounded-full bg-[#2E307C] hover:bg-[#282A70] px-5 py-3 text-white "
                  >
                    <span className="font-medium"> Registrar </span>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-3 h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                      />
                    </svg>
                  </button>
                </SpinnerWrapper>
                <p className="mt-2 text-sm text-center text-gray-500">
                  Ya tienes una cuenta? {""}
                  <Link
                    to={`/${getDomain()}/inicio`}
                    className="text-[#2C2C7D] underline"
                  >
                    Iniciar Sesión
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default withFirebase(SignUpForm);
