
const initialValues = {
  email: '',
  password: ''

}

const validate = values => {
  const errors = {}

  if (!values.email) {
    errors.email = 'Requerido';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Email no valido';
  }

  if (!values.password) {
    errors.password = 'Requerido';
  } 

  return errors
}


export default {
  initialValues,
  validate
}