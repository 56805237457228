import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore } from 'redux-persist'
import logger from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './root.saga'
import rootReducer from './root.reducer'

import ENVIRONMENT from '../helpers/environment'
///TODO cerar saga de user y banners
const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware]

let compose = ''

if(ENVIRONMENT.PRODUCTION === 'PRODUCTION'){
	compose = applyMiddleware(...middlewares)
}else{
	middlewares.push(logger)
	compose = composeWithDevTools(applyMiddleware(...middlewares))
}


export const store = createStore(
	rootReducer,
	compose
)

// TODO acrear y aplicar sagas
sagaMiddleware.run(rootSaga)

export const persistor = persistStore(store)
export default { store, persistor }
