import { initializeApp } from "../firebase/firebase";

const messaging = initializeApp.messaging;
if (messaging != null) {
messaging.usePublicVapidKey(
  // Project Settings => Cloud Messaging => Web Push certificates
  initializeApp.config.webPush ||
    "BD6HCddxOj0pPncG1F4drdcbL7z4xb-MsoNUwR1IojuJ9ogznxLWeWFYOKhvDxFNKRtDOY5bO4flRIReV7tpnN0"
);
}

export { messaging };
