import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Swalt from "sweetalert2";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Card,
  FormFeedback,
  Progress,
  CustomInput
} from "reactstrap";

import { withFirebase } from "../../../firebase/context";
import formikConfig from "./formikConfig";
import { toggleShowNavigation } from "../../../redux/navigation/actions";
import * as userActions from "../../../redux/user/actions";
import getDomain from "../../../helpers/getDomain";
import domainSettings from "../../../helpers/domainSettings";

import { utils } from "../../../helpers";

const UpdateForm = ({ user }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { convertDate } = utils;

  const {
    isValidateUpdating,
    validUpdated,
    errorMessageValidateUpdate
  } = useSelector(state => state.user);

  const onSubmit = (values, actions) => {
    //console.log(actions);
    dispatch(userActions.userValidateUpdateStart(values));
    actions.setSubmitting(false);
  };

  formikConfig.initialValues = {
    isEmail: user.email ? true : false,
    email: user.email ? user.email : "",
    nombre: user.nombre || "",
    apellido: user.apellido || "",
    fecha: user.birthday ? convertDate(user.birthday) : "",
    tipoDocumento: user.tipoId || "",
    documento: user.cedula || "",
    celular: user.telefono || "",
    genero: user.genero || "",
    codigoReferido: "",
    terminos: ""
  };

  formikConfig.onSubmit = onSubmit;
  const formik = useFormik(formikConfig);

  useEffect(() => {
    if (validUpdated) {
      Swalt.fire({
        //titleText: "Exito",
        html: `
          <h3 align='center'>Usuario Actualizado con éxito</h3>
          <h4>Te enviamos un mensaje a tu correo para configurar tu contraseña</h4>
        `,
        icon: "success",
        onClose: () => {
          dispatch(userActions.userValidateUpdateClean());
          dispatch(toggleShowNavigation());
          history.push(`/${getDomain()}`);
        }
      });
    }
    // eslint-disable-next-line
  }, [validUpdated]);

  useEffect(() => {
    if (errorMessageValidateUpdate) {
      Swalt.fire({
        titleText: "Error",
        text: errorMessageValidateUpdate || "Error al actualizar Usuario",
        icon: "warning",
        onClose: () => dispatch(userActions.userValidateClean())
      });
    }
  }, [errorMessageValidateUpdate, dispatch]);

  const TerminosCondiciones = (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={domainSettings[getDomain()].terminos}
    >
      Ver terminos y condciones
    </a>
  );

  return (
    <Form onSubmit={formik.handleSubmit}>
      {isValidateUpdating ? (
        <Progress striped color="success" value="100" />
      ) : (
        <div></div>
      )}
      <Card body>
        <Row form>
          <Col md="6" sm="12">
            <FormGroup>
              <Label for="nombre">Nombre</Label>
              <Input
                type="text"
                id="nombre"
                {...formik.getFieldProps("nombre")}
                placeholder="Escribe tu nombre"
                invalid={!!(formik.errors.nombre && formik.touched.nombre)}
              />
              <FormFeedback>{formik.errors.nombre}</FormFeedback>
            </FormGroup>
          </Col>

          <Col md="6" sm="12">
            <FormGroup>
              <Label for="apellido">Apellido</Label>
              <Input
                type="text"
                id="apellido"
                {...formik.getFieldProps("apellido")}
                invalid={!!(formik.errors.apellido && formik.touched.apellido)}
                placeholder="Escribe tu apellido"
              ></Input>
              <FormFeedback>{formik.errors.apellido}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col md="6">
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="email"
                id="email"
                disabled={user.email ? true : false}
                {...formik.getFieldProps("email")}
                invalid={!!(formik.errors.email && formik.touched.email)}
                placeholder="escriba su email"
              />
              <FormFeedback>{formik.errors.email}</FormFeedback>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label for="celular">Celular</Label>
              <Input
                type="text"
                id="celular"
                disabled
                {...formik.getFieldProps("celular")}
                invalid={!!(formik.errors.celular && formik.touched.celular)}
                placeholder="numero telefonico"
              />
              <FormFeedback>{formik.errors.celular}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col md="6">
            <FormGroup>
              <Label for="genero">Genero</Label>
              <Input
                type="select"
                name="genero"
                id="genero"
                disabled={user.genero ? true : false}
                {...formik.getFieldProps("genero")}
                invalid={!!(formik.errors.genero && formik.touched.genero)}
              >
                <option value=""></option>
                <option value="Masculino">Masculino</option>
                <option value="Femenino">Femenino</option>
              </Input>
              <FormFeedback>{formik.errors.genero}</FormFeedback>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label id="codigoReferido">Codigo Referido</Label>
              <Input
                type="text"
                id="codigoReferido"
                {...formik.getFieldProps("codigoReferido")}
                invalid={
                  !!(
                    formik.errors.codigoReferido &&
                    formik.touched.codigoReferido
                  )
                }
              />
              <FormFeedback>{formik.errors.codigoReferido}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col md="6">
            <FormGroup>
              <Label for="tipoDocumento">Tipo Documento</Label>
              <Input
                type="select"
                name="tipoDocumento"
                id="tipoDocumento"
                disabled
                {...formik.getFieldProps("tipoDocumento")}
                invalid={
                  !!(
                    formik.errors.tipoDocumento && formik.touched.tipoDocumento
                  )
                }
              >
                <option></option>
                <option value="Cedula">Cedula</option>
                <option value="Pasaporte">Pasaporte</option>
              </Input>
              <FormFeedback>{formik.errors.genero}</FormFeedback>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label id="documento">Numero de Documento</Label>
              <Input
                type="text"
                id="documento"
                name="documento"
                disabled
                {...formik.getFieldProps("documento")}
                invalid={
                  !!(formik.errors.documento && formik.touched.documento)
                }
              />
              <FormFeedback>{formik.errors.documento}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col md="6">
            <FormGroup>
              <Label id="fecha">Fecha de Nacimiento</Label>
              <Input
                type="date"
                id="fecha"
                name="fecha"
                
                {...formik.getFieldProps("fecha")}
                invalid={!!(formik.errors.fecha && formik.touched.fecha)}
              ></Input>
              <FormFeedback>{formik.errors.fecha}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col md="12">
            <FormGroup>
              <Label for="terminos"> Términos y condiciones</Label>
              <CustomInput
                type="checkbox"
                id="terminos"
                label="Acepta los términos y condiciones"
                {...formik.getFieldProps("terminos")}
                invalid={!!(formik.errors.terminos && formik.touched.terminos)}
              />
              <FormFeedback>{formik.errors.terminos}</FormFeedback>

              {TerminosCondiciones}
            </FormGroup>
          </Col>
        </Row>

        <Button type="submit" color="primary" block>
          Actualizar
        </Button>
      </Card>
    </Form>
  );
};

export default withFirebase(UpdateForm);
