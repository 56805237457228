import React, { useEffect, useState } from "react";
import { Alert } from "reactstrap";
import "./index.css";

export default props => {
  const { message, type } = props;
  const ms = props.ms || 15000;
  const [position, setPosition] = useState("bottom");
  const { visible, onDismiss } = props;

  useEffect(() => {
    if (props.position) {
      setPosition(props.position || position);
    }
  }, [position, props.position]);

  useEffect(() => {
    const abortController = new AbortController();
    const timeVisible = setTimeout(() => {
      onDismiss();
    }, ms);
    if (!visible)
      return () => {
        abortController.abort();
        clearTimeout(timeVisible);
      };
  }, [visible, ms, onDismiss]);

  return (
    <Alert
      className={`positionElement-${position} alert-zIndex`}
      color={type || "info"}
      isOpen={visible}
      toggle={onDismiss}
      fade={true}
    >
      {message || "mensaje vacio"}
    </Alert>
  );
};
