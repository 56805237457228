import { useEffect, useCallback, useState } from "react";
import { withAuthentication } from "../firebase/session";
import { messaging } from "./push-notification"; // ensure this is from modular SDK
import { getAuth, onAuthStateChanged } from "firebase/auth"; // modular auth import
import { onMessage, getToken } from "firebase/messaging";    // modular messaging methods
import { getDevice } from "../helpers";
import axios from "axios";
import ENVIRONMENT from "../helpers/environment";
import getDomain from "../helpers/getDomain";

const Worker = (props) => {
  const { authUser } = props; // assuming withAuthentication passes in authUser
  const [count, setCount] = useState(0);

  const unSubscribe = useCallback(async () => {
    if (messaging != null) {
      try {
        const currentToken = await getToken(messaging);
        if (currentToken) {
          // There's no direct deleteToken in the modular API as before. 
          // In v9, deleteToken is still available, but you need to import it:
          // import { deleteToken } from "firebase/messaging";
          // await deleteToken(messaging);
          // If you're using a version that supports deleteToken (v9), do:
          // await deleteToken(messaging, currentToken);

          // If deleteToken is unavailable for some reason, you may need to handle this logic differently.
        }
      } catch (error) {
        console.error("Error Unsuscribe", error);
      }
    }
  }, []);

  const sendToken = useCallback(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        if (count < 1 && messaging != null) {
          const tokenMessage = await getToken(messaging);
          if (tokenMessage) {
            setCount(2);
            const tokenUser = await user.getIdToken();
            const domain = getDomain();

            await axios.post(
              `${ENVIRONMENT.API}/${domain}/usuarios/login`,
              {
                UsuarioToken: tokenUser,
                UsuarioDeviceID: "web",
                UsuarioDeviceOS: getDevice,
                URToken: tokenMessage
              }
            );
          }
        }
      } else {
        // console.log("Usuario no logeado");
      }
    });
  }, [count]);

  useEffect(() => {
    if (messaging) {
      // Listen for messages in the foreground
      onMessage(messaging, (payload) => {
        console.log("Message received. ", payload);
      });
    }
  }, []);

  useEffect(() => {
    if (authUser) {
      if (!("Notification" in window)) {
        alert("This browser does not support desktop notification");
      } else if (Notification.permission === "granted") {
        // Permissions granted, send token if needed
        if (count < 1) {
          sendToken();
          setCount(count + 1);
        }
      } else if (Notification.permission !== "denied") {
        unSubscribe();
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            if (count < 1) {
              sendToken();
              setCount(2);
            }
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  return null;
};

export default withAuthentication(Worker);
