import navigationTypes from "./types";

export const changeNavigationColor = color => ({
  type: navigationTypes.CHANGE_BACKGROUND_COLOR,
  payload: color
});

export const showNavigation = () => ({
  type: navigationTypes.SHOW_NAV
});

export const toggleShowNavigation = () => ({
  type: navigationTypes.TOGGLE_SHOW_NAVIGATION
});

export const setDomain = domain => ({
  type: navigationTypes.CHANGE_DOMAIN,
  payload: domain
});
