import React from "react";
import { useSelector } from "react-redux";

import { Progress } from "reactstrap";

import {
  selectIsFetchingUser,
  selectIsDoingProccess
} from "../../../redux/user/selectors";
import { selectIsFetchingTienda } from "../../../redux/tienda/selectors";

import "./index.css";

export default () => {
  const isLoadingUser = useSelector(selectIsFetchingUser);
  const isLoadingTienda = useSelector(selectIsFetchingTienda);
  const isProccessing = useSelector(selectIsDoingProccess);

  return (
    <React.Fragment>
      {isLoadingUser || isLoadingTienda || isProccessing ? (
        <Progress
          animated
          color="success"
          value="100"
          className="position-sticky customProgress "
        />
      ) : null}
    </React.Fragment>
  );
};
