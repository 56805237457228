import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  useHistory,
  useRouteMatch,
  Link,
  useParams,
  useLocation
} from "react-router-dom";
import Swalt from "sweetalert2";

import { PaginationItem, PaginationLink, Container } from "reactstrap";

import { withAuthentication } from "../firebase/session";

import Regalo from "../components/regalos";

import { selectRegalosArray } from "../redux/user/selectors";

import * as tiendaActions from "../redux/tienda/actions";
import * as userActions from "../redux/user/actions";

import getDomain from "../helpers/getDomain";

const ContainerRegalo = props => {
  const { token } = useSelector(state => state.user);
  const { canjeado, cupon, errorMessageCanjear } = useSelector(
    state => state.tienda
  );

  const { firebase, authUser } = props;
  const regalosArray = useSelector(selectRegalosArray);
  const [init, setInit] = useState(0);
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [indexPage, setIndexPage] = useState(0);
  const location = useLocation();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { pagina } = useParams();

  const loadRegalos = useCallback(() => {
    if (authUser) {
      firebase
        .getRefreshToken()
        .then(token => {
          dispatch(userActions.fetchRegalosStart(token));
          dispatch(userActions.refreshToken(token));
        })
        .catch(error => {
          // console.log("error en citas getting token", error);
        });
    }
  }, [dispatch, firebase, authUser]);

  const selectPage = useCallback(
    page => {
      //console.log("Page : ", page, " ", "Array : ", regalosArray);

      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      if (page >= 0 && page !== regalosArray.length) setIndexPage(page);
    },
    [regalosArray]
  );

  const canjearProducto = useCallback(
    producto => {
      setMessage(producto.rTitulo);
      if (producto.rPunchMax && producto.rStatus === 1) {
        history.push(`${url}/punchcard/${producto.rId}`);
      } else if (producto.rStatus === 1) {
        dispatch(
          tiendaActions.canjearRegaloStart({
            token: token,
            regaloId: producto.rId
          })
        );
      }
      setInit(!init);
    },
    [dispatch, history, init, token, url]
  );

  useEffect(() => {
    if (errorMessageCanjear) {
      Swalt.fire({
        titleText: `Error al Canjear`,
        text: errorMessageCanjear || "Error al canjear",
        icon: "warning",
        onClose: () => dispatch(tiendaActions.cleanAfterCanjear())
      });
    }
  }, [errorMessageCanjear, dispatch]);

  useEffect(() => {
    if (canjeado) {
      setMessage(`${cupon}`);
      Swalt.fire({
        html: `<h4> Codigo :</h4>
        <h3><b>${cupon}</b></h3>`,
        icon: "success",
        customClass: {
          container: "sweetalert-code"
        },
        onClose: () => dispatch(tiendaActions.cleanAfterCanjear())
      });

      //dispatch(tiendaActions.fetchTiendaStart(token));
      //dispatch(userActions.fetchRegalosStart(user.token))
    }
  }, [canjeado, cupon, dispatch, message]);

  useEffect(() => {
    if (authUser) loadRegalos();
  }, [loadRegalos, authUser]);

  useEffect(() => {
    //console.log(url, location);
    if (pagina <= regalosArray.length && pagina > 0) setIndexPage(pagina - 1);
    else selectPage(0);
  }, [pagina, regalosArray.length, selectPage, location, url]);

  const NoData = (
    <React.Fragment>
      <div>
        <h3 className="mt-5" align="center">
          No tienes Regalos, Puedes comprarlos en la{" "}
          <Link to={`/${getDomain()}/tienda`}>tienda</Link> de puntos
        </h3>
      </div>
    </React.Fragment>
  );

  const renderPaginationItems = !regalosArray.length
    ? null
    : regalosArray.map((value, key) => {
        return (
          <PaginationItem key={key} active={key === indexPage}>
            <PaginationLink onClick={() => selectPage(key)}>
              {key + 1}
            </PaginationLink>
          </PaginationItem>
        );
      });
  const containerPagination = (
    <nav aria-label="Page navigation example" className="mt-5">
      <ul className="pagination justify-content-center">
        <PaginationItem disabled={indexPage === 0}>
          <PaginationLink previous onClick={() => selectPage(indexPage - 1)} />
        </PaginationItem>
        {renderPaginationItems}
        <PaginationItem>
          <PaginationLink
            next
            disabled={indexPage === regalosArray.length - 1}
            onClick={() => selectPage(indexPage + 1)}
          />
        </PaginationItem>
      </ul>
    </nav>
  );
  return (
    <Container>
      {regalosArray.length ? (
        <React.Fragment>
          {containerPagination}
          <Regalo
            regalos={regalosArray[indexPage]}
            canjearProducto={canjearProducto}
          />
          {containerPagination}
        </React.Fragment>
      ) : (
        NoData
      )}
    </Container>
  );
};

export default withAuthentication(ContainerRegalo);
