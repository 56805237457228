import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { randomKey } from "../helpers";

import bannerReducer from "./banner/reducer";
import userReducer from "./user/reducer";
import tiendaReducer from "./tienda/reducer";
import navigationReducer from "./navigation/reducer";
import messagesReducer from "./messages/reducer";
import citasReducer from "./citas/reducer";

const persistConfig = {
  key: "16856664876ef333322s" + randomKey(),
  storage
};
/*
const userConfig = {
  key: randomKey(randomKey()),
  storage,
  whiteList: ["token"]
}; */
const rootReducer = combineReducers({
  banner: bannerReducer,
  user: userReducer, //persistReducer(userConfig, userReducer),
  tienda: tiendaReducer,
  navigation: persistReducer(persistConfig, navigationReducer),
  messages: messagesReducer,
  citas: citasReducer
});

export default rootReducer;
