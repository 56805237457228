import { call, all, put, takeLatest } from 'redux-saga/effects'
import { bannerActionTypes } from './types'
import { getBannerSucces, getBannerFailed } from './actions'
import { getBanners } from '../../api/banners'

export function* fetchBannersAsync() {
	try {
		const banners = yield getBanners()
		yield put(getBannerSucces(banners))
	} catch (error) {
		yield put(getBannerFailed(error))
	}
}

export function* fetchBannersListener() {
	yield takeLatest(bannerActionTypes.GET_BANNER_START, fetchBannersAsync)
}

export function* bannerSaga() {
	yield all([call(fetchBannersListener)])
}
