import React, { useEffect, useState, useCallback } from "react";

import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";

import { useSelector, useDispatch } from "react-redux";
import { withAuthentication } from "../firebase/session";

import { fetchCitasStart } from "../redux/citas/actions";
import { refreshToken } from "../redux/user/actions";
import {
  selectCitas,
  selectIsFetching,
  selectIsScheduling
} from "../redux/citas/selectors";

import { TableCitas, NewCita } from "../components/citas";
import { SpinnerWrapper } from "../components/utils";

const Citas = props => {
  const dispatch = useDispatch();
  const { firebase } = props;
  //const token = useSelector(state => state.user.token, shallowEqual);
  const { successScheduling } = useSelector(state => state.citas);
  const isFetching = useSelector(selectIsFetching);
  const isScheduling = useSelector(selectIsScheduling);
  const citas = useSelector(selectCitas);

  const [modal, setModal] = useState(false);

  const toggle = useCallback(() => {
    setModal(!modal);
  }, [modal]);

  const refreshCitas = useCallback(() => {
    firebase
      .getRefreshToken()
      .then(idToken => {
        dispatch(fetchCitasStart(idToken));
        dispatch(refreshToken(idToken));
      })
      .catch(error => {
        // console.log("error en citas getting token", error);
      });
  }, [dispatch, firebase]);

  useEffect(() => {
    refreshCitas();
  }, [refreshCitas]);

  

  useEffect(() => {
    if (successScheduling) {
      refreshCitas();
      setModal(false);
    }
    // eslint-disable-next-line
  }, [successScheduling]);

  return (
    <Container>
      <div className="m-1 ">
        <Row className="mt-5">
          <Col sm="12" md="12">
            <h1 className="text-capitalize text-center text-break">
              Tabla de citas
            </h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button color="primary" onClick={toggle}>
              Agendar Cita
            </Button>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <SpinnerWrapper isFetching={isFetching}>
              <TableCitas citas={citas} />
            </SpinnerWrapper>
          </Col>
        </Row>
        <Modal
          isOpen={modal}
          toggle={toggle}
          centered
          keyboard
          unmountOnClose
          scrollable
          returnFocusAfterClose={false}
        >
          <ModalHeader toggle={toggle}>Agendar Cita</ModalHeader>
          <ModalBody>
            <SpinnerWrapper isFetching={isScheduling}></SpinnerWrapper>
            <NewCita toggle={toggle} />
          </ModalBody>
        </Modal>
      </div>
    </Container>
  );
};

export default withAuthentication(Citas);
