//se especifican las rutas por cliente y las rutas privadas

import { getDomain } from "../../helpers";

//import * as Rutas from "../../components/switch/rutas";

//cada campo en el arreglo de cliente son las pagina a las que tendra acceso
const Routes = {
  dominio: getDomain(),
  clientes: {
    demo: [
      "regalos",
      "tienda",
      "citas",
      "perfil",
      "inicio",
      "registro",
      "validar-usuario",
      "validar-usuario",
      "reset",
      "portal-demo",
    ],
    carretillon: ["regalos", "tienda", "perfil", "inicio", "registro", "reset"],
    local: ["regalos", "tienda", "perfil", "inicio", "registro", "reset"],
    merkadoo: ["regalos", "tienda", "perfil", "inicio", "registro", "reset"],
    glow: ["regalos", "tienda", "perfil", "inicio", "registro", "reset"],
    jumboplus: ["regalos", "tienda", "perfil", "inicio", "registro", "reset"],
    panda: ["regalos", "tienda", "perfil", "inicio", "registro", "reset"],
    runningbalboa: [
      "regalos",
      "tienda",
      "perfil",
      "inicio",
      "registro",
      "validar-usuario",
      "validar-usuario",
      "reset",
    ],
    supercarnes: ["regalos", "tienda", "perfil", "inicio", "registro", "reset"],
    madison: ["regalos", "tienda", "perfil", "inicio", "registro", "reset"],
    chickensabroso: [
      "regalos",
      "tienda",
      "perfil",
      "inicio",
      "registro",
      "reset",
    ],
    todoadollar: ["regalos", "tienda", "perfil", "inicio", "registro", "reset"],
    maagoos: ["regalos", "tienda", "perfil", "inicio", "registro", "reset"],
    tosto: ["regalos", "tienda", "perfil", "inicio", "registro", "reset"],
    motatan: ["regalos", "tienda", "perfil", "inicio", "registro", "reset"],
    cafeduran: ["regalos", "tienda", "perfil", "inicio", "registro", "reset"],
    grancombo: ["regalos", "tienda", "perfil", "inicio", "registro", "reset"],
    keenes: ["regalos", "tienda", "perfil", "inicio", "registro", "reset"],
    beep: ["regalos", "tienda", "perfil", "inicio", "registro", "reset"],
    alleria: ["regalos", "tienda", "perfil", "inicio", "registro", "reset"],
    larosa: [
      "regalos",
      "tienda",
      "perfil",
      "inicio",
      "registro",
      "reset",
      "portal-demo"],
  },
  publicas: ["inicio", "registro", "validar-usuario", "reset"],
  // se declaran las rutas de para el portal
  navLinks: {
    perfil: {
      link: `/${getDomain()}/perfil`,
      title: "Mi Perfil",
    },
    regalos: {
      link: `/${getDomain()}/regalos`,
      title: "Mis Regalos",
    },
    tienda: {
      link: `/${getDomain()}/tienda`,
      title: "Tienda de Puntos",
    },
    citas: {
      link: `/${getDomain()}/citas`,
      title: "Citas",
    },
    "portal-demo": {
      link: `/${getDomain()}/portal-demo`,
      title: "Portal demo",
    },
    // inicio: Rutas.InicioRoute,
    // registro: Rutas.FinalizarValidateRoute,
    // "update-validar": Rutas.FinalizarUpdateRoute,
    // "validar-usuario": Rutas.FinalizarValidateRoute,
    // reset: Rutas.PasswordResetRoute,
  },
};
export default Routes;
