/* eslint-disable import/no-anonymous-default-export */
export default {
    carretillon: 'carretillon',
    local: 'local',
    merkadoo: 'merkadoo',
    glow: 'glow',
    jumboplus: 'jumboplus',
    panda: 'panda',
    runningbalboa: 'runningbalboa',
    supercarnes: 'supercarnes',
    madison: 'madison',
    todoadollar: 'todoadollar',
    demo: 'demo',
    chickensabroso: 'chickensabroso',
    maagoos: "maagoos",
    tosto: "tosto",
    motatan: "motatan",
    cafeduran: "cafeduran",
    keenes: "keenes",
    beep: "beep",
    alleria: "alleria",
    larosa: "larosa",

    
}