import citasTypes from "./types";

export const fetchCitasStart = token => ({
  type: citasTypes.FETCH_CITAS_USUARIO_START,
  payload: token
});

export const fetchCitasSuccess = citas => ({
  type: citasTypes.FETCH_CITAS_USUARIO_SUCCESS,
  payload: citas
});

export const fetchCitasFailed = error => ({
  type: citasTypes.FETCH_CITAS_USUARIO_FAILED,
  payload: error
});

export const fetchCategoriasStart = token => ({
  type: citasTypes.FETCH_CITAS_CATEGORIA_START,
  payload: token
});

export const fetchCategoriaSuccess = data => ({
  type: citasTypes.FETCH_CITAS_CATEGORIA_SUCCESS,
  payload: data
});

export const fetchCategoriasFailed = error => ({
  type: citasTypes.FETCH_CITAS_CATEGORIA_FAILED,
  payload: error
});

export const fetchDisponibilidadStart = dto => ({
  type: citasTypes.FETCH_CITAS_DISPONIBILIDAD_START,
  payload: dto
});

export const fetchDisponibilidadSuccess = data => ({
  type: citasTypes.FETCH_CITAS_DISPONIBILIDAD_SUCCESS,
  payload: data
});

export const fetchDisponibilidadFailed = error => ({
  type: citasTypes.FETCH_CITAS_DISPONIBILIDAD_FAILED,
  payload: error
});

export const agendarStart = dto => ({
  type: citasTypes.AGENDAR_CITA_START,
  payload: dto
});

export const agendarSuccess = () => ({
  type: citasTypes.AGENDAR_CITA_SUCCESS
});

export const agendarFailed = error => ({
  type: citasTypes.AGENDAR_CITA_FAILED,
  payload: error
});
