import React, { useCallback } from "react";
import { Container, Row, Col } from "reactstrap";

import { useDispatch } from "react-redux";
import { userLogoutStart } from "../redux/user/actions";

import { withAuthentication } from "../firebase/session";
import { logos } from "../helpers/logos";

import "./styles/index.css";

const PortalDemo = (props) => {
  const { firebase, authUser } = props;
  const dispatch = useDispatch();

  const onclick = useCallback(
    (e) => {
      const target = e.currentTarget;
      e.preventDefault();
      if (authUser) {
        firebase.auth
          .signOut()
          .then(function () {
            dispatch(userLogoutStart());
            //console.log(target);
            window.location.assign(target.href);
          })
          .catch(function (error) {
            // console.log("Error al Cerrar sesion");
          });
      } else {
        // console.log(target);
        window.location.assign(e.currentTarget.href);
      }
    },
    [firebase, authUser, dispatch]
  );
  return (
    // <Container className="p-4">
    //   <Row className="m-0 p-0"></Row>
    //   <Row>
    //     <Col sm="12" md="3" className="card shadow-sm p-1 mb-2 mr-0.5 ">
    //       <h3 className="text-center mt-2 font-weight-bold">Demo</h3>
    //       <div className="demo-image-container">
    //         <a href="/demo" onClick={onclick} id="/demo">
    //           <img src={logos.demo} alt="Pagina demo" />
    //         </a>
    //       </div>
    //     </Col>

    //     <Col sm="12" md="3" className="card shadow-sm p-1 mb-2 mr-0.5">
    //       <h3 className="text-center mt-2 font-weight-bold">Glow</h3>
    //       <a href="/glow" className="demo-image-container">
    //         <img src={logos.glow} alt="Pagina demo" className="img-fluid" />
    //       </a>
    //     </Col>

    //     <Col sm="12" md="3" className="card shadow-sm p-1 mb-2 mr-0.5">
    //       <h3 className="text-center mt-2 font-weight-bold">Carretillon</h3>
    //       <a href="/carretillon" className="demo-image-container">
    //         <img
    //           src={logos.carretillon}
    //           alt="Pagina demo"
    //           className="img-fluid"
    //         />
    //       </a>
    //     </Col>

    //     <Col sm="12" md="3" className="card shadow-sm p-1 mb-2 mr-0.5">
    //       <h3 className="text-center mt-2 font-weight-bold">Merkadoo</h3>
    //       <a href="/merkadoo" className="demo-image-container">
    //         <img src={logos.merkadoo} alt="Pagina demo" className="img-fluid" />
    //       </a>
    //     </Col>

    //     <Col sm="12" md="3" className="card shadow-sm p-1 mb-2 mr-0.5">
    //       <h3 className="text-center mt-2 font-weight-bold">Jumboplus</h3>
    //       <a href="/jumboplus" className="demo-image-container">
    //         <img
    //           src={logos.jumboplus}
    //           alt="Pagina demo"
    //           className="img-fluid"
    //         />
    //       </a>
    //     </Col>

    //     <Col sm="12" md="3" className="card shadow-sm p-1 mb-2 mr-0.5">
    //       <h3 className="text-center mt-2 font-weight-bold">Panda</h3>
    //       <a href="/panda" className="demo-image-container">
    //         <img src={logos.panda} alt="Pagina demo" className="img-fluid" />
    //       </a>
    //     </Col>

    //     <Col sm="12" md="3" className="card shadow-sm p-1 mb-2 mr-0.5 ">
    //       <h3 className="text-center mt-2 font-weight-bold">runningbalboa</h3>
    //       <a href="/runningbalboa" className="demo-image-container">
    //         <img
    //           src={logos.runningbalboa}
    //           alt="Pagina demo"
    //           className="img-fluid"
    //         />
    //       </a>
    //     </Col>

    //     <Col sm="12" md="3" className="card shadow-sm p-1 mb-2 mr-0.5 ">
    //       <h3 className="text-center mt-2 font-weight-bold">Madison</h3>
    //       <a href="/madison" className="demo-image-container">
    //         <img src={logos.madison} alt="Pagina demo" className="img-fluid" />
    //       </a>
    //     </Col>

    //     <Col sm="12" md="3" className="card shadow-sm p-1 mb-2 mr-0.5">
    //       <h3 className="text-center mt-2 font-weight-bold">Todo A Dollar</h3>
    //       <a href="/todoadollar" className="demo-image-container">
    //         <img
    //           src={logos.todoadollar}
    //           alt="Pagina demo"
    //           className="img-fluid"
    //         />
    //       </a>
    //     </Col>
    //     <Col sm="12" md="3" className="card shadow-sm p-1 mb-2 mr-0.5">
    //       <h3 className="text-center mt-2 font-weight-bold">Local</h3>
    //       <a href="/local" className="demo-image-container">
    //         <img src={logos.local} alt="Local" className="img-fluid" />
    //       </a>
    //     </Col>
    //   </Row>
    // </Container>
    <>
      <section class=" text-white">
        <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
          <div class="mx-auto max-w-lg text-center">
            <h2 class="text-3xl font-bold sm:text-4xl text-[#2C2C7D]">
              Portal demo
            </h2>
          </div>

          <div class="mt-8 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
            <a
              href="/demo"
              onClick={onclick}
              id="/demo"
              class="group cursor-pointer relative flex h-48 items-end rounded-3xl border border-gray-800 bg-white shadow-xl transition ease-in-out delay-150 hover:border-pink-500/10 hover:shadow-[#E0E7FF]"
            >
              <div class="p-8 lg:group-hover:absolute lg:group-hover:opacity-0">
                <img className="w-62" src={logos.demo} />
              </div>

              <div class="absolute p-8 opacity-0 lg:group-hover:relative lg:group-hover:opacity-100">
                <h3 class="text-2xl font-bold text-black">Demo</h3>

                <p class="mt-1 text-xs text-gray-600 ">
                  La forma más rápida y fácil de crear tu página web para vender
                  online. Contamos con Ecommerce y programas de Leltad.
                </p>
              </div>
            </a>
            <a
              href="/glow"
              class="group relative flex h-48 items-end rounded-3xl border border-gray-800 bg-white shadow-xl transition ease-in-out delay-150 hover:border-pink-500/10 hover:shadow-[#E0E7FF]"
            >
              <div class="p-8 lg:group-hover:absolute lg:group-hover:opacity-0">
                <img className="w-52" src={logos.glow} />
              </div>

              <div class="absolute p-8 opacity-0 lg:group-hover:relative lg:group-hover:opacity-100">
                <h3 class="text-2xl font-bold text-black">Glow</h3>

                <p class="mt-1 text-xs text-gray-600 ">
                  Somos una suplidora de cosméticos multimarcas, acá encontrarás
                  gran variedad de las ultimas tendencias de productos y
                  accesorios y tratamientos para el cabello.
                </p>
              </div>
            </a>
            <a
              href="/merkadoo"
              class="group relative flex h-48 items-end rounded-3xl border border-gray-800 bg-white shadow-xl transition ease-in-out delay-150 hover:border-pink-500/10 hover:shadow-[#E0E7FF]"
            >
              <div class="p-8 lg:group-hover:absolute lg:group-hover:opacity-0">
                <img className="w-28" src={logos.merkadoo} />
              </div>

              <div class="absolute p-8 opacity-0 lg:group-hover:relative lg:group-hover:opacity-100">
                <h3 class="text-2xl font-bold text-black">Merkadoo</h3>

                <p class="mt-1 text-xs text-gray-600 ">
                  Compra hoy mismo tu super a domicilio. Productos de Super 99,
                  Members Selection, Pretelt y Global Brands. ¡Delivery desde
                  $3.5 en Ciudad de Panamá!
                </p>
              </div>
            </a>
            <a
              href="/jumboplus"
              class="group relative flex h-48 items-end rounded-3xl border border-gray-800 bg-white shadow-xl transition ease-in-out delay-150 hover:border-pink-500/10 hover:shadow-[#E0E7FF]"
            >
              <div class="p-8 lg:group-hover:absolute lg:group-hover:opacity-0">
                <img className="w-[250px]" src={logos.jumboplus} />
              </div>

              <div class="absolute p-8 opacity-0 lg:group-hover:relative lg:group-hover:opacity-100">
                <h3 class="text-2xl font-bold text-black">Jumboplus</h3>

                <p class="mt-1 text-xs text-gray-600 ">
                  Disfrute sus compras con nuestros precios. Jumboplus le ofrece
                  los más exclusivo siempre para usted.
                </p>
              </div>
            </a>
            <a
              href="/runningbalboa"
              class="group relative flex h-48 items-end rounded-3xl border border-gray-800 bg-white shadow-xl transition ease-in-out delay-150 hover:border-pink-500/10 hover:shadow-[#E0E7FF]"
            >
              <div class="p-8 lg:group-hover:absolute lg:group-hover:opacity-0">
                <img className="w-32 rounded-xl" src={logos.runningbalboa} />
              </div>

              <div class="absolute p-8 opacity-0 lg:group-hover:relative lg:group-hover:opacity-100">
                <h3 class="text-2xl font-bold text-black">Runningbalboa</h3>

                <p class="mt-1 text-xs text-gray-600 ">
                  Empresa especializada para corredores en Panamá. Contamos con
                  accesorios para que no te falte nada cuando vas a entrenar.
                </p>
              </div>
            </a>
            <a
              href="/todoadollar"
              class="group relative flex h-48 items-end rounded-3xl border border-gray-800 bg-white shadow-xl transition ease-in-out delay-150 hover:border-pink-500/10 hover:shadow-[#E0E7FF]"
            >
              <div class="p-8 lg:group-hover:absolute lg:group-hover:opacity-0">
                <img className="w-52 rounded-xl" src={logos.todoadollar} />
              </div>

              <div class="absolute p-8 opacity-0 lg:group-hover:relative lg:group-hover:opacity-100">
                <h3 class="text-2xl font-bold text-black">Todo a dollar</h3>

                <p class="mt-1 text-xs text-gray-600 ">
                  Empresa de venta al por mayor y al detal, nacional e
                  internacional de productos en general.
                </p>
              </div>
            </a>
            <a
              href="/madison"
              class="group relative flex h-48 items-end rounded-3xl border border-gray-800 bg-white shadow-xl transition ease-in-out delay-150 hover:border-pink-500/10 hover:shadow-[#E0E7FF]"
            >
              <div class="p-8 lg:group-hover:absolute lg:group-hover:opacity-0">
                <img className="w-52 rounded-xl" src={logos.madison} />
              </div>

              <div class="absolute p-8 opacity-0 lg:group-hover:relative lg:group-hover:opacity-100">
                <h3 class="text-2xl font-bold text-black">Madison</h3>

                <p class="mt-1 text-xs text-gray-600 ">
                  En Madison store tenemos la mejor tienda por departamentos
                  para dama, caballeros y niños, juguetería y el hogar.
                </p>
              </div>
            </a>
            <a
              href="/local"
              class="group relative flex h-48 items-end rounded-3xl border border-gray-800 bg-white shadow-xl transition ease-in-out delay-150 hover:border-pink-500/10 hover:shadow-[#E0E7FF]"
            >
              <div class="p-8 lg:group-hover:absolute lg:group-hover:opacity-0">
                <img className="w-52 rounded-xl" src={logos.local} />
              </div>

              <div class="absolute p-8 opacity-0 lg:group-hover:relative lg:group-hover:opacity-100">
                <h3 class="text-2xl font-bold text-black">Local</h3>

                <p class="mt-1 text-xs text-gray-600">
                  Restaurante en el que creemos la importancia de tener los
                  mejores ingredientes de la cocina y por ello en Local Panamá
                  compartimos nuestra selección.
                </p>
              </div>
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default withAuthentication(PortalDemo);
