import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import { withRouter, useParams, Redirect } from "react-router-dom";

import { toggleShowNavigation } from "../redux/navigation/actions";

import ValidateUser from "../components/validateUser";

import getDomain from "../helpers/getDomain";

const ContainerValidateUser = props => {
  const { show } = useSelector(state => state.navigation);
  const { token } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const { cedula, celular } = useParams();

  useEffect(() => {
    if (show) dispatch(toggleShowNavigation());
    //dispatch(userActions.userValidateFetchStart(user))
    return () => null;
  }, [dispatch, show]);

  return (
    <React.Fragment>
      {token ? (
        <Redirect to={`${getDomain()}/inicio`} />
      ) : (
        <ValidateUser user={{ cedula, celular }} />
      )}
    </React.Fragment>
  );
};

export default withRouter(ContainerValidateUser);
