import React from "react";
import { useLocation, withRouter } from "react-router-dom";

import { Button } from "reactstrap";
import image404 from "../../statics/img/404.gif";
import "./index.css";

export default withRouter(props => {
  const location = useLocation();

  return (
    <div className="main-container-404">
      <div className="container404 mt-5">
        <img
          className="image404"
          src={image404}
          alt="Error 404 Pagina no Encontrada"
        />
        <h2>{location.pathname}</h2>
        <Button color="primary" onClick={props.history.goBack}>
          Regresar
        </Button>
      </div>
    </div>
  );
});
