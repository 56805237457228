import { bannerActionTypes } from "./types";
import { userActionTypes } from "../user/types";

import dummyImage from "../../statics/img/dummyImage.png";
const INITIAL_STATE = {
  banner: [
    {
      id: 153156151,
      url: dummyImage
    }
  ],
  isFetching: false,
  errorMessageFetch: "",
  isLoaded: false
};

const bannerReducer = (currentState = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case bannerActionTypes.GET_BANNER_START:
      return {
        ...currentState,
        isFetching: true
      };

    case bannerActionTypes.GET_BANNER_SUCCESS:
      return {
        ...currentState,
        banner: payload,
        isFetching: false,
        errorMessageFetch: null,
        isLoaded: true
      };
    case bannerActionTypes.GET_BANNER_FAILED:
      return {
        ...currentState,
        isFetching: false,
        errorMessageFetch: payload.message,
        isLoaded: false,
        banner: []
      };
    case userActionTypes.USER_LOGOUT_SUCCESS:
    default:
      return currentState;
  }
};

export default bannerReducer;
