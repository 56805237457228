import React from "react";
import { withRouter } from "react-router-dom";
import { SignInForm } from "../../components/sign-in-form";
import { withAuthentication } from "../../firebase/session";
import { Container, Col, Row } from "reactstrap";

import SpinnerWrapper from "../../components/utils/spinnerWrapper";

const Inicio = (props) => {
  const { authUser, loading } = props;

  return (
    <>
      {/* <Container className="mt-4">
        <Row>
          <Col sm="12" md={{ size: 9, offset: 1 }}>
            {!authUser && !loading ? (
              <SignInForm />
            ) : (
              <SpinnerWrapper isFetching={loading}></SpinnerWrapper>
            )}
          </Col>
        </Row>
      </Container> */}

      {!authUser && !loading ? (
        <SignInForm />
      ) : (
        <SpinnerWrapper isFetching={loading}></SpinnerWrapper>
      )}
    </>
  );
};

export default withRouter(withAuthentication(Inicio));
