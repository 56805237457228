import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword as firebaseCreateUserWithEmailAndPassword,
  signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
  signInWithCustomToken as firebaseSignInWithCustomToken,
  onAuthStateChanged as firebaseOnAuthStateChanged,
  signOut as firebaseSignOut,
  sendPasswordResetEmail as firebaseSendPasswordResetEmail,
  updatePassword as firebaseUpdateUserPassword,
  updateProfile as firebaseUpdateUserProfile,
  getIdToken as firebaseGetIdToken
} from "firebase/auth";
import { getMessaging, isSupported } from "firebase/messaging";
import getApp from "./getApp";
import getDomain from "../helpers/getDomain";

class Firebase {
  _appId;
  _isConfigValid = false;

  constructor() {
    const domain = getDomain();
    if (domain) {
    const config = getApp(domain);

    this.app = initializeApp(config);
    this.auth = getAuth(this.app);
    this.config = config;
    this._isConfigValid = !!domain;
    this._appId = domain;

    // Check messaging support asynchronously
    this.initializeMessaging();
  } else {
    this.app = initializeApp(getApp(domain));
    this._isConfigValid = false;
    this._appId = domain;
    this.auth = getAuth(this.app);
  //  if  (app.messaging.isSupported()){
    // this.messaging = app.messaging
  //  }else{
    this.messaging = null;
  // }
    this.config = getApp(domain);
  }
  }

  async initializeMessaging() {
    const supported = await isSupported();
    if (supported) {
      this.messaging = getMessaging(this.app);
    } else {
      this.messaging = null;
    }
  }

  createUserWithEmailAndPassword = (email, password) =>
    firebaseCreateUserWithEmailAndPassword(this.auth, email, password);

  signInWithEmailAndPassword = (email, password) =>
    firebaseSignInWithEmailAndPassword(this.auth, email, password);

  signInWithCustomToken = (token) =>
    firebaseSignInWithCustomToken(this.auth, token);

  onAuthStateChanged = (callback) =>
    firebaseOnAuthStateChanged(this.auth, callback);

  doSignOut = () => firebaseSignOut(this.auth);

  resetPassword = (email) => firebaseSendPasswordResetEmail(this.auth, email);

  updatePassword = (newPassword) => {
    const currentUser = this.auth.currentUser;
    if (currentUser) {
      return firebaseUpdateUserPassword(currentUser, newPassword);
    } else {
      return Promise.reject(new Error("No current user to update password for."));
    }
  };

  getRefreshToken = () => {
    const currentUser = this.auth.currentUser;
    if (currentUser) {
      return firebaseGetIdToken(currentUser, true);
    } else {
      return Promise.reject(new Error("No current user."));
    }
  };

  getCurrentUser = () => this.auth.currentUser;

  getAppId = () => this._appId;

  getIsConfigValid = () => this._isConfigValid;

  updateProfile = (profileData) => {
    const currentUser = this.auth.currentUser;
    if (currentUser) {
      return firebaseUpdateUserProfile(currentUser, profileData);
    } else {
      return Promise.reject(new Error("No current user to update profile for."));
    }
  };
}

export { Firebase, initializeApp };
