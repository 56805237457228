import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Button, Row, Container, Col } from "reactstrap";
//import Swalt from "sweetalert2";

import SpinnerWrapper from "../../utils/spinnerWrapper";

import RenderPunchs from "./render-punch";

import { canjearRegaloStart } from "../../../redux/tienda/actions";
import { selectIsDoingAsync } from "../../../redux/tienda/selectors";

import { utils } from "../../../helpers";

import "./index.css";

const ModalPunch = ({ data }) => {
  const dispatch = useDispatch();
  const token = useSelector(({ user }) => user.token);
  const isDoing = useSelector(selectIsDoingAsync);
  const [activeButton, setActiveButton] = useState(false);

  const canjearPunch = useCallback(() => {
    dispatch(
      canjearRegaloStart({
        token,
        regaloId: data.rId
      })
    );
  }, [dispatch, data, token]);

  useEffect(() => {
    if (data.rPunchAcumulado === data.rPunchMax) setActiveButton(true);
  }, [data]);

  return (
    <Container className="overflow-auto">
      <Col sm="12" md="12">
        <h2 className="text-center p-2 text-capitalize">
          {utils.capitalize(data.rTitulo)}
        </h2>
      </Col>
      <RenderPunchs
        actual={data.rPunchAcumulado}
        total={data.rPunchMax}
        emptyImage={data.rImgPunchVacia}
        fillImage={data.rImgPunchLlena}
      />
      <Row>
        <Col sm="12" md="12" className="mb-3 mt-3">
          <SpinnerWrapper isFetching={isDoing}>
            <div className="text-center">
              <Button
                color="primary"
                disabled={!activeButton}
                onClick={canjearPunch}
              >
                Canjear
              </Button>
            </div>
          </SpinnerWrapper>
        </Col>
      </Row>
    </Container>
  );
};
export default ModalPunch;
