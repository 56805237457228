import axios from "axios";
import getDomain from '../../helpers/getDomain'

export default () => {
  const domain = getDomain()
  return axios
    .get(`/${domain}/banners`)
    .then(({ data: { banners } }) => banners)
    .catch(error => {
      throw new Error(
        error.message ? error.message : "Error al fetch los banners"
      );
    });
};
