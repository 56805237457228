import React from "react";

import { Col, Row } from "reactstrap";
import { randomKey } from "../../../helpers";

import "./index.css";
const Punch = ({ actual, total, emptyImage, fillImage }) => {
  const array = new Array(total).fill(5);

  return (
    <Row>
      <Col md="12" sm="12" className="float-left p-0 pt-3 pb-3 pl-1 pr-1">
        {array.map((value, i) => {
          const filter = i > actual ? "filter-gray" : "";
          return (
            <div
              className={`position-relative punchcards-image-container rounded border shadow-sm bg-white mr-1 `}
              key={randomKey(randomKey(total + value))}
            >
              <img
                src={!filter ? fillImage : emptyImage}
                alt=""
                className={`img-fluid punchcards-image ${filter}`}
              ></img>
              {filter ? (
                <div className="position-text-punch text-white ">
                  <h1 className="text-center font-weight-bold">{i + 1}</h1>
                </div>
              ) : null}
            </div>
          );
        })}
      </Col>
    </Row>
  );
};

export default React.memo(Punch);
