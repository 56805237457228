import { createSelector } from "reselect";

const selectCitasState = state => state.citas;

export const selectCitas = createSelector(
  [selectCitasState],
  ({ citas }) => citas
);

export const selectIsFetching = createSelector(
  [selectCitasState],
  ({ isFetchingCitas }) => isFetchingCitas
);

export const selectIsFetchingFormOptions = createSelector(
  [selectCitasState],
  ({ isFetchingDisponibilidad, isFetchingCategorias }) =>
    isFetchingDisponibilidad || isFetchingCategorias
);

export const selectIsScheduling = createSelector(
  [selectCitasState],
  ({ isScheduling }) => isScheduling
);

export const selectCitaData = createSelector(
  [selectCitasState],
  ({ citas, disponibilidad, categorias }) => ({
    citas,
    disponibilidad,
    categorias
  })
);
