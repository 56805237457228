import { validate } from "cedula-panama";
export const validationRegex = {
  validEmail: RegExp(
    // eslint-disable-next-line
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  ),
  validCelular: RegExp(/[0-9]{4}(-?)[0-9]{4}$/),
  validCedula: validate
};

export default validationRegex;
