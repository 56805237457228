import { validationRegex } from "../../helpers/validationRegex";

//modulo de validacion de campos del formulario

const initialValues = {
  email: "",
  password1: "",
  password2: "",
  nombre: "",
  apellido: "",
  fecha: "",
  codigoReferido: "",
  documento: "",
  tipoDocumento: "",
  terminos: "",
  celular: "",
  genero: ""
};

const onSubmit = values => {};

const validate = (values, ...rest) => {
  const errors = {};
  const actualYear = new Date().getFullYear();
  if (!values.nombre) {
    errors.nombre = "Campo requerido";
  } else if (values.nombre.length > 64) {
    errors.nombre = "debe contener menos de 64 caracteres";
  }

  if (!values.apellido) {
    errors.apellido = "Campo requerido";
  } else if (values.apellido.length > 64) {
    errors.apellido = "debe contener menos de 64 caracteres";
  }

  if (
    values.genero !== "Masculino" &&
    values.genero !== "Femenino" &&
    values.genero !== "otro"
  ) {
    errors.genero = "Campo Debe seleccionar un genero";
  }

  if (!values.email) {
    errors.email = "Campo requerido";
  } else if (!validationRegex.validEmail.test(values.email)) {
    errors.email = "Email no valido";
  }

  //
  //console.log('celular ',values.celular)
  if (!values.celular) {
    errors.celular = "Campo requerido";
  } else if (!validationRegex.validCelular.test(values.celular)) {
    errors.celular = "Numero de Celular no válido";
  }

  if (!values.tipoDocumento || values.tipoDocumento == "") {
    errors.tipoDocumento = "Seleccione su tipo de documento";
  }

  if (!values.documento) {
    errors.documento = "campo vacio";
  } else if (values.tipoDocumento.toLowerCase() === "cedula") {
    // more than 5 digits
    if (values.documento.length < 5) {
      errors.documento = "documento no valido";
    }
    // const valid = validationRegex.validCedula(values.documento);
    // if (!valid.isValid) errors.documento = "documento no valido";
    // if (!valid.isComplete) errors.documento = "documento incompleto";
  }

  if (!values.fecha) {
    errors.fecha = "requerido";
  } else if (parseInt(values.fecha.split("-")[0]) < actualYear - 90) {
    errors.fecha = "edad incorrecta";
  } else if (parseInt(values.fecha.split("-")[0]) > actualYear - 12) {
    errors.fecha = "debes tener almenos 13 años";
  }

  if (!values.terminos) {
    errors.terminos = "debe aceptar los términos y condiciones";
  }

  if (!values.password1) {
    errors.password1 = "Campo requerido";
  }
  if (!values.password2) {
    errors.password2 = "Campo requerido";
  } else if (values.password1 && values.password2) {
    if (values.password1 !== values.password2) {
      errors.password1 = "contraseñas no coinciden";
      errors.password2 = "contraseñas no coinciden";
    }
  }

  if (values.password1.length < 6) {
    errors.password1 = "Debe contener minimo 6 caracteres";
  }

  return errors;
};

export default {
  initialValues,
  onSubmit,
  validate
};
