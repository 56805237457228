import { createSelector } from 'reselect'

const selectBannerState = state => state.banner

export const selectBanners = createSelector(
	[selectBannerState],
	({ banner }) => banner
)

export const selectBannerApiState = createSelector(
	[selectBannerState],
	({ isFetching, errorMessageFetch, isLoaded }) => ({ isFetching, errorMessageFetch, isLoaded })
)
