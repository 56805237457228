import { isBrowser, isAndroid, isIOS } from 'react-device-detect'

let deviceType

if (isBrowser) deviceType = 'WEB'
else if (isAndroid) deviceType = 'Android'
else if (isIOS) deviceType = 'Ios'
else deviceType = 'WEB'

export default deviceType
