import axios from "axios";
import getDomain from "../../helpers/getDomain";

import ENVIRONMENT from '../../helpers/environment'
import device from '../../helpers/getDevice'

export const validateUser = async (dto) => {
  try {
    
    const body = {
      usuarioTelefono: dto.celular,
      usuarioNumID: dto.cedula,
      api: ENVIRONMENT.API_KEY_CONSULT
    };
    const { data } = await axios.post(
      `/${getDomain()}/usuarios/finalizar-registro/validate`,
      body
    );
    if (!data.validate) {
      throw Error("Datos utilizados por otra cuenta");
    }

    return data.validate;
  } catch (error) {
    throw error;
  }
};

export const fetchValidateUser = async (dto) => {
  try {
    const body = {
      usuarioNumID: dto.numId,
      usuarioTelefono: dto.celular,
      api: ENVIRONMENT.API_KEY_CONSULT
    };

    const {data} = await axios.post(`/${getDomain()}/usuarios/finalizar-registro/user`, body);

    if(data.ok === false) throw Error('Error al consultar datos del usuario')

    return data.user

  } catch (error) {
    if(error.code === 'auth/id-token-expired')
    throw error;
  }
};

export const finalizarRegistroUpdate =  async user => {
  try {
    
    const body = {
      UsuarioName: user.nombre,
      Usuariolastname: user.apellido,
      UsuarioCorreo: user.email,
      UsuarioTelefono: user.celular,
      UsuarioTipoId: user.tipoDocumento,
      UsuarioNumId: user.documento,
      UsuarioBirth: user.fecha,
      UsuarioCodigoReferido: user.codigoReferido,
      UsuarioGender: user.genero,
      UsuarioAceptaTerminos : 1,
      UsuarioDeviceOS: device,
      UsuarioDeviceID: '',
      URToken: '',
      api: ENVIRONMENT.API_KEY_CONSULT
    };
    
    const { data } = await axios.post(
      `/${getDomain()}/usuarios/finalizar-registro/update`,
      body
    );
    
    if(!data.ok) throw Error('Error al actualizar usuario')

    return  data
  

  } catch (error) {
    throw error;
  }
};
