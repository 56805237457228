import { getDomain } from "./helpers";
export function register() {
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", function() {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then(function(registration) {
          // console.log("Registration successful, scope is:", registration.scope);
          if (navigator.serviceWorker.controller) {
            navigator.serviceWorker.controller.postMessage({
              dominioId: getDomain()
            });
          }
        })
        .catch(function(err) {
          // console.log("Service worker registration failed, error:", err);
        });
    });
  }
}
