import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, useParams } from "react-router-dom";
import { Button, Row, Container, Col, Card, CardImg } from "reactstrap";
import Swalt from "sweetalert2";

import * as tiendaActions from "../../redux/tienda/actions";

import "./index.css";

const PunchCard = props => {
  let { id } = useParams();
  const { regalos, token } = useSelector(state => state.user);
  const { canjeado, cupon } = useSelector(state => state.tienda);
  const [activeButton, setActiveButton] = useState(false);

  //const [modal, setModal] = useState(false);
  const dispatch = useDispatch();

  const [regalo, setRegalo] = useState({
    rId: "empty",
    rImg: "empty",
    rTitulo: "empty",
    rDesc: "empty",
    rFExp: "empty",
    rStatus: 0,
    rCodigo: "empty",
    rTipo: 0,
    rImgPunchVacia: "empty",
    rImgPunchLlena: "empty",
    rPunchMax: 0,
    rPunchAcumulado: 3
  });

  useEffect(() => {
    if (regalos.length) {
      const tmp = regalos.find(r => r.rId === id);
      //console.log(regalo);
      if (tmp) {
        setRegalo(tmp);
        if (regalo.rPunchAcumulado === regalo.rPunchMax) setActiveButton(true);
        else setActiveButton(false);
      }
    }
  }, [
    regalos.length,
    regalos,
    regalo.rPunchAcumulado,
    id,
    regalo.rPunchMax,
    regalo
  ]);

  //const toggleModal = () => setModal(!modal)

  const canjearPunch = e => {
    dispatch(
      tiendaActions.canjearRegaloStart({
        token,
        regaloId: regalo.rId
      })
    );
  };

  useEffect(() => {
    if (canjeado) {
      Swalt.fire({
        html: `<h5>${regalo.rTitulo} <br> Codigo :</h5>
        <h3><b>${cupon}</b></h3>`,
        icon: "success",
        customClass: {
          container: "sweetalert-code"
        },
        onClose: () => dispatch(tiendaActions.cleanAfterCanjear())
      });
      //dispatch(tiendaActions.fetchTiendaStart(token));
      //dispatch(userActions.fetchUserDataStart(user.token))
    }
  }, [canjeado, activeButton, dispatch, cupon, regalo.rTitulo]);

  return (
    <Container className="mt-5">
      <Row>
        <Col>
          {" "}
          <h4 className="text-center">Tarjeta Perforable</h4>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className="punchcard-card">
            <Row>
              <Col className="m-4">
                <h4 className="text-center">{regalo.rTitulo}</h4>
              </Col>
            </Row>
            <Row>
              <Col sm="12" md="6">
                <div className="punchcard-image-container">
                  <CardImg
                    top
                    src={
                      activeButton
                        ? regalo.rImgPunchLlena
                        : regalo.rImgPunchVacia
                    }
                    className="img-fluid"
                  />
                </div>
              </Col>
              <Col sm="12" md="6" className="align-middle">
                <div className="p-3 align-middle">
                  <p className="font-weight-bold align-middle">
                    {regalo.rDesc}
                  </p>
                  <p className="font-weight-bold align-middle">
                    Cantidad total :
                    <b className="text-primary"> {regalo.rPunchMax}</b>
                  </p>
                  <p className="font-weight-bold align-middle">
                    Tarjetas Perforadas:{" "}
                    <b className="text-primary"> {regalo.rPunchAcumulado}</b>
                  </p>
                </div>
              </Col>
            </Row>

            <Row className="m-3">
              <Col sm="12" md={{ size: 4, offset: 4 }}>
                <Button
                  color="primary"
                  block
                  disabled={!activeButton}
                  onClick={canjearPunch}
                >
                  Canjear
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col className="text-center">
          <Button onClick={e => props.history.goBack()}> Regresar</Button>
        </Col>
      </Row>
    </Container>
  );
};
export default withRouter(PunchCard);
