import { userActionTypes } from "./types";

const INITIAL_STATE = {
  isSignin: false,
  isSignUp: false,
  isLoged: false,
  isLogingOut: false,
  isUserData: false,
  isFetchingUser: false,
  isFetchingRegalos: false,
  isFetchingTienda: false,
  isFetchingValidate: false,
  isValidateUpdating: false,
  isValidatingUser: false,
  registrosValidate: {
    app: false
  },
  registroSuccess: {
    app: false
  },
  isValidUser: false,
  validUpdated: false,
  firebase: {},
  regalos: [],
  historial: [],
  direcciones: [],
  errorsFieldForm: {},
  user: {
    email: "",
    genero: "",
    nombre: "",
    apellido: "",
    password: "",
    numId: "",
    cedula: "",
    telefono: "",
    puntos: "",
    tipoId: "",
    acepta: "",
    nivel: {
      nivelId: "",
      nivelNombre: "",
      nivelColor: "",
      nivelImg: ""
    },
    token: ""
  },
  cupon: "",
  userForm: "",
  token: "",
  registroErrores: {
    email: "",
    numId: "",
    celular: "",
    cedula: "",
    telefono: ""
  },
  errorMessageUser: "",
  errorMessageSignin: "",
  errorMessageLogout: "",
  errorMessageSignUp: "",
  errorMessageRegalos: "",
  errorMessageUserValid: "",
  errorMessageValidateFetch: "",
  errorMessageValidateUpdate: ""
};

const userReducer = (currentState = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case userActionTypes.USER_SIGNIN_START:
      return {
        ...currentState,
        // firebase: payload.firebase,
        isSignin: true
      };

    case userActionTypes.USER_SIGNIN_SUCCESS:
      return {
        ...currentState,
        isSignin: false,
        user: {
          ...currentState.user,
          ...payload.user,
          password: ""
        },

        //firebase: payload.firebase,
        isLoged: true,
        errorMessageSignin: null,
        token: payload.user.token
      };

    case userActionTypes.USER_SIGNING_FAILED:
      return {
        ...currentState,
        isSignin: false,
        errorMessageSignin: payload.message
      };

    case userActionTypes.REGISTRO_VALIDAR_USUARIO_APP_START:
      return {
        ...currentState,
        isSignUp: true
      };

    case userActionTypes.REGISTRO_VALIDAR_USUARIO_APP_SUCCESS:
      return {
        ...currentState,
        registrosValidate: {
          app: true
        },
        registroErrores: INITIAL_STATE.registroErrores
      };

    case userActionTypes.REGISTRO_VALIDAR_USUARIO_APP_FAILED:
      return {
        ...currentState,
        isSignUp: false,
        registrosValidate: {
          app: false
        },
        registroErrores: payload
      };
    case userActionTypes.USER_SIGNUP_START:
      return {
        ...currentState,
        isSignUp: true
        //firebase: payload.firebase
      };

    case userActionTypes.USER_SIGNUP_SUCCESS:
      return {
        ...currentState,
        isSignUp: false,
        errorMessageSignUp: "",
        isLoged: true,
        token: payload.user.token,
        errorsFieldForm: {},
        userForm: ""
      };

    case userActionTypes.USER_SIGNUP_FAILED:
      return {
        ...currentState,
        isSignUp: false,
        errorMessageSignUp: payload.error.message
      };

    case userActionTypes.USER_LOGOUT_START:
      return {
        ...currentState,
        isLogingOut: true
      };

    case userActionTypes.USER_LOGOUT_SUCCESS:
      return {
        ...currentState,
        ...INITIAL_STATE
      };

    case userActionTypes.USER_LOGOUT_FAILED:
      return {
        ...currentState,
        errorMessageLogout: payload
      };

    case userActionTypes.USER_FETCH_DATA_START:
      return {
        ...currentState,
        isFetchingUser: true
      };

    case userActionTypes.USER_FETCH_DATA_SUCCESS:
      return {
        ...currentState,
        user: {
          ...INITIAL_STATE.user,
          ...payload.user
        },
        isFetchingUser: false,
        isUserData: true,
        regalos: payload.regalos,
        historial: payload.historial,
        direcciones: payload.direcciones,
        token: payload.user.token
      };

    case userActionTypes.USER_FETCH_DATA_FAILED:
      return {
        ...currentState,
        errorMessageUser: payload.message,
        isFetchingUser: false,
        isUserData: false
      };

    case userActionTypes.USER_FETCH_BASIC_START:
      return {
        ...currentState,
        isFetchingUser: true
      };

    case userActionTypes.USER_FETCH_BASIC_SUCCESS:
      return {
        ...currentState,
        isFetchingUser: false,
        user: {
          ...INITIAL_STATE.user,
          ...payload
        }
      };

    case userActionTypes.USER_FETCH_BASIC_FAILED:
      return {
        ...currentState,
        isFetchingUser: false,
        errorMessageUser: payload
      };

    case userActionTypes.USER_SUCCESS_MESSAGE_TO_NULL:
      return {
        ...currentState,
        errorMessageSignin: null,
        errorMessageSignUp: null,
        errorMessageLogout: null
      };

    case userActionTypes.USER_ERROR_MESSAGE_TO_NULL:
      return {
        ...currentState
      };

    case userActionTypes.USER_SETCUPON:
      return {
        ...currentState,
        cupon: payload
      };

    case userActionTypes.USER_DELETECUPON:
      return {
        ...currentState,
        cupon: ""
      };
    case userActionTypes.USER_REFRESH_TOKEN:
      return {
        ...currentState,
        token: payload
      };
    case userActionTypes.USER_FETCH_REGALOS_START:
      return {
        ...currentState,
        isFetchingRegalos: true
      };

    case userActionTypes.USER_FETCH_REGALOS_SUCCESS:
      return {
        ...currentState,
        isFetchingRegalos: false,
        regalos: payload
      };

    case userActionTypes.USER_FETCH_REGALOS_FAILED:
      return {
        ...currentState,
        isFetchingRegalos: false,
        errorMessageRegalos: payload
      };

    case userActionTypes.USER_VALIDATE_START:
      return {
        ...currentState,
        isValidatingUser: true
      };

    case userActionTypes.USER_VALIDATE_SUCCESS:
      return {
        ...currentState,
        isValidatingUser: false,
        isValidUser: true,
        user: payload
      };

    case userActionTypes.USER_VALIDATE_FAILED:
      return {
        ...currentState,
        errorMessageUserValid: payload,
        isValidUser: false,
        isValidatingUser: false
      };

    case userActionTypes.USER_VALIDATE_CLEAN:
      return {
        ...currentState,
        isValidatingUser: false,
        isValidUser: false,
        errorMessageUserValid: "",
        isValidateUpdating: false,
        validUpdated: false,
        errorMessageValidateUpdate: ""
      };

    case userActionTypes.USER_VALIDATE_UPDATE_CLEAN:
      return {
        ...currentState,
        isValidatingUser: false,
        isValidUser: false,
        errorMessageUserValid: "",
        isValidateUpdating: false,
        validUpdated: false,
        errorMessageValidateUpdate: "",
        user: INITIAL_STATE.user
      };
    case userActionTypes.USER_VALIDATE_FETCH_START:
      return {
        ...currentState,
        isFetchingValidate: true
      };
    case userActionTypes.USER_VALIDATE_FETCH_SUCCESS:
      return {
        ...currentState,
        isFetchingValidate: false,
        user: payload
      };

    case userActionTypes.USER_VALIDATE_FETCH_FAILED:
      return {
        ...currentState,
        isFetchingValidate: false,
        errorMessageValidateFetch: payload
      };
    case userActionTypes.USER_VALIDATE_UPDATE_START:
      return {
        ...currentState,
        isValidateUpdating: true
      };
    case userActionTypes.USER_VALIDATE_UPDATE_SUCCESS:
      return {
        ...currentState,
        isValidateUpdating: false,
        validUpdated: true
      };
    case userActionTypes.USER_VALIDATE_UPDATE_FAILED:
      return {
        ...currentState,
        isValidateUpdating: false,
        validUpdated: false,
        errorMessageValidateUpdate: payload
      };
    default:
      return currentState;
  }
};

export default userReducer;
