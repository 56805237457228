import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Switch, Route, Redirect, withRouter} from "react-router-dom";

import { withAuthentication } from "../../firebase/session";

import { showNavigation } from "../../redux/navigation/actions";
import {
  ContainerPerfil,
  ContainerTienda,
  ContainerRegalo,
  ContainerValidateUser,
  ContainerUpdateValidUser,
  ContainerCitas,
  ContainerDemo
} from "../../containers/index";
import RegistroPage from "../../pages/registro/registro.page";
import InicioPage from "../../pages/inicio/inicio.page";
import { ProtectedRoute } from "../protectedRoute";
import AccessRoute from "../protectedRoute/accessRoutes";
import NoAccess from "../noAccess";
import PunchCard from "../punchCard";
import Error404 from "../Error404/index";
import { PasswordReset } from "../password";

import getDomain from "../../helpers/getDomain";

//cada Route debe tener una tag para habilitar los modulos para
//cada cliente  si no tiene tag tendra acceso si inicia sesion
const Enrutador = props => {
  const pathUrl = props.location.pathname;
  const { authUser, history , location} = props;


  const Regex = /finalizar-registro/;

  const dispatch = useDispatch();

  useEffect(() => {
    //oculta los links del navbar si esta en validacion de usuario
    // **por el momento solo disponible en running balboa

    if (!Regex.test(pathUrl)) dispatch(showNavigation());
  }, [pathUrl, Regex, dispatch]);

  useEffect(() => {
    if (authUser) {
      if (pathUrl.split("/")[2] === "inicio")
        {
        
          if( location.state)history.replace(location.state.from.pathname );
          else history.replace(`/${getDomain()}/perfil`);
          // console.log('redireccionando a perfil', history)
         
          
        }
    }
    // eslint-disable-next-line
  }, [authUser, history, pathUrl]);

  return (
    <React.Fragment>
      {getDomain() ? (
        <Switch>
          {pathUrl === `/${getDomain()}` ? (
            <Redirect to={`${pathUrl}/inicio`} />
          ) : null}
          <Route
            exact
            path={`/${getDomain()}/registro`}
            component={RegistroPage}
          />
          <Route
            exact
            path={`/${getDomain().toString()}/inicio`}
            component={InicioPage}
            tag="inicio"
          />
          <AccessRoute
            exact
            path={`/${getDomain().toString()}/portal-demo`}
            component={ContainerDemo}
            tag="portal-demo"
          />
          <Route
            exact
            path={`/${getDomain().toString()}/finalizar-registro/:cedula/:celular`}
            component={ContainerValidateUser}
          />
          <Route
            exact
            path={`/${getDomain().toString()}/finalizar-registro/user`}
            component={ContainerUpdateValidUser}
          />
          <ProtectedRoute
            exact
            path={`/${getDomain()}/regalos`}
            component={ContainerRegalo}
            tag="regalos"
          />
          <ProtectedRoute
            exact
            path={`/${getDomain()}/regalos/:pagina`}
            component={ContainerRegalo}
            tag="regalos"
          />
          <ProtectedRoute
            exact
            path={`/${getDomain()}/tienda`}
            component={ContainerTienda}
            tag="tienda"
          />
          <ProtectedRoute
            exact
            path={`/${getDomain()}/perfil`}
            component={ContainerPerfil}
            tag="perfil"
          />
          <ProtectedRoute
            exact
            path={`/${getDomain()}/regalos/punchcard/:id`}
            component={PunchCard}
            tag="punchcard"
          />
          <ProtectedRoute
            exact
            path={`/${getDomain()}/citas`}
            component={ContainerCitas}
            tag="citas"
          />
          <Route
            exact
            path={`/${getDomain()}/password/reset`}
            component={PasswordReset}
            tag="reset"
          />
          <Route path={`/${getDomain()}/noAccess`} component={NoAccess} />
          <Route component={Error404} />
        </Switch>
      ) : (
        <Switch>
          <Route component={Error404} />
        </Switch>
      )}
    </React.Fragment>
  );
};

export default withRouter(withAuthentication(Enrutador));
