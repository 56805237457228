import React from "react";
import { withRouter } from "react-router-dom";
import { Alert, Button } from "reactstrap";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    // console.log(error, errorInfo);
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // console.log(this.state.error, this.state.errorInfo);
      return (
        <div>
          <Alert color="success">
            <h4 className="alert-heading">{this.state.error}</h4>
            <p>{this.state.errorInfo}</p>
            <hr />
          </Alert>
          <Button color="primary" onClick={this.props.history.goBack}>
            Regresar
          </Button>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
