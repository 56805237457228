import { tiendaActionTypes } from "./types";

export const fetchTiendaStart = token => ({
  type: tiendaActionTypes.FETCH_TIENDA_START,
  payload: token
});

export const fetchTiendaSuccess = data => ({
  type: tiendaActionTypes.FETCH_TIENDA_SUCCESS,
  payload: data
});

export const fetchTiendaFailure = error => ({
  type: tiendaActionTypes.FETCH_TIENDA_FAILED,
  payload: error
});

export const canjearRegaloStart = (dto) => ({
  type: tiendaActionTypes.CANJEAR_REGALO_START,
  payload: dto
})

export const canjearRegaloSuccess = (cupon) => ({
  type: tiendaActionTypes.CANJEAR_REGALO_SUCCESS,
  payload: cupon
})

export const canjearRegaloFailed = error => ({
  type: tiendaActionTypes.CANJEAR_REGALO_FAILED,
  payload: error
})

export const comprarRegaloStart = (dto) => ({
  type: tiendaActionTypes.COMPRAR_REGALO_START,
  payload:dto
})

export const comprarRegaloSuccess = () => ({
  type: tiendaActionTypes.COMPRAR_REGALO_SUCCESS
})

export const comprarRegaloFailed = error => ({
  type: tiendaActionTypes.COMPRAR_REGALO_FAILED,
  payload: error
})

export const cleanAfterComprar = () => ({
  type: tiendaActionTypes.CLEAN_AFTER_COMPRAR
})

export const cleanAfterCanjear = () => ({
  type: tiendaActionTypes.CLEAN_AFTER_CANJEAR
})