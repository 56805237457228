import React from "react";
import SignUpForm from "../../components/sign-up-form/signUpForm";
import { Container, Row, Col } from "reactstrap";

export default () => {
  return (
    // <Container className="mt-4">
    //   <Row>
    //     <Col sm="12" md={{ size: 6, offset: 3 }}>
    //       <SignUpForm />
    //     </Col>
    //   </Row>
    // </Container>

    <>
      <SignUpForm />
    </>
  );
};
