import React, { useEffect, useCallback } from "react";
import { Container } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";

import { withAuthentication } from "../firebase/session";
import { userFetchBasicStart } from "../redux/user/actions";

import Perfil from "../components/perfil";

const ContainerPerfil = props => {
  const user = useSelector(state => state.user.user);
  const { firebase } = props;

  const dispatch = useDispatch();

  const loadPerfil = useCallback(() => {
    firebase
      .getRefreshToken()
      .then(token => {
        dispatch(userFetchBasicStart(token));
      })
      .catch(error => {
        // console.log("error en citas getting token", error);
      });
  }, [dispatch, firebase]);

  useEffect(() => {
    loadPerfil();
  }, [loadPerfil]);

  //si se inicia sesion o registra activa la petciones de permisos para los push notification
  useEffect(() => {}, []);
  return (
    <Container className="p-2">
      {user ? <Perfil user={user} /> : <h1>No hay datos</h1>}
    </Container>
  );
};

export default withAuthentication(ContainerPerfil);
