import React from "react";

import { withRouter } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";

const NoAccess = props => {
  return (
    <Container>
      <Row className="p-5 mt-5">
        <Col sm="12" md="12">
          <h1 className="text-center text-capitalize text-break ">
            No tienes accesso a este modulo
          </h1>
        </Col>
      </Row>
      <Row>
        <Col sm="12" md={{ size: 4, offset: 5 }}>
          <Button onClick={e => props.history.goBack()} color="primary">
            {" "}
            Regresar{" "}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(NoAccess);
