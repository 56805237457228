import navigationTypes from "./types";

import { getDomain } from "../../helpers";
const INITIAL_STATE = {
  show: true,
  background: "",
  domain: getDomain(),
  rutas: [{}]
};

export default (currenState = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case navigationTypes.SHOW_NAV:
      return {
        ...currenState,
        show: true
      };
    case navigationTypes.TOGGLE_SHOW_NAVIGATION:
      return {
        ...currenState,
        show: !currenState.show
      };
    case navigationTypes.CHANGE_BACKGROUND_COLOR:
      return {
        ...currenState,
        background: payload
      };
    case navigationTypes.CHANGE_DOMAIN:
      return {
        ...currenState,
        domain: payload
      };
    default:
      return currenState;
  }
};
