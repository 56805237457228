import React, { useState, useCallback } from "react";
import {
  Card,
  CardBody,
  CardImg,
  Col,
  //Button,
  Row,
  Modal,
  ModalHeader
} from "reactstrap";

import ModalRegalo from "./modal-regalo";
import ModalPunch from "./modal-punchcard";

import { cleanAfterCanjear } from "../../redux/tienda/actions";

import { utils } from "../../helpers";

import "./index.css";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

const Regalo = props => {
  const { regalos } = props;
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState({});
  const dispatch = useDispatch();

  const toggle = useCallback(() => {
    setModal(!modal);
  }, [modal]);

  const selectProducto = useCallback(
    regalo => {
      setSelected(regalo);
      toggle();
    },
    [toggle]
  );

  useEffect(() => {
    if (!modal) dispatch(cleanAfterCanjear());
  }, [modal, dispatch]);

  return (
    <Row>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        keyboard
        size={"lg"}
        unmountOnClose
        scrollable
        returnFocusAfterClose={false}
      >
        <ModalHeader toggle={toggle} className="text-center"></ModalHeader>
        {selected.rPunchMax ? (
          <ModalPunch data={selected} toggle={toggle} />
        ) : (
          <ModalRegalo data={selected} toggle={toggle} />
        )}
      </Modal>

      {regalos.length ? (
        regalos.map(regalo => (
          <Col
            key={regalo.rId}
            sm="12"
            md={{ size: 4, offset: 0 }}
            className="mb-4"
          >
            <Card
              className="shadow-sm"
              style={{ cursor: "pointer" }}
              onClick={e => selectProducto(regalo)}
            >
              <CardBody>
                <h5 className="card-title text-center text-lowercase">
                  <span className="text-capitalize">
                    {utils.truncateText(utils.capitalize(regalo.rTitulo), 25)}
                  </span>
                </h5>

                <div className="regalos-image-container">
                  <CardImg
                    top
                    className="img-fluid"
                    src={regalo.rImg}
                    alt={regalo.rTitulo}
                  />
                </div>

                <p className="mt-2 text-dark text-break text-center">
                  {utils.truncateText(regalo.rDesc, 30)}
                </p>
                <p className="mt-2 text-dark text-break text-center">
                  Expira el <b>{new Date(regalo.rFExp).toLocaleDateString()}</b>
                </p>
              </CardBody>
            </Card>
          </Col>
        ))
      ) : (
        <Col>
          {" "}
          <h1 align="center">No tienes regalos disponibles</h1>
        </Col>
      )}
    </Row>
  );
};

export default Regalo;
