import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  Row,
  Col,
  Progress,
  FormFeedback,
  Container,
} from "reactstrap";

import { withFirebase } from "../../firebase/context";

import Alert from "../alert";

import getDomain from "../../helpers/getDomain";

// TODO: mostrar mensajes de error

const PasswordReset = (props) => {
  //const history = useHistory();
  const [message, setMessage] = useState("");
  const [type, setType] = useState("success");
  const [send, setSend] = useState("");
  const [visible, setVisible] = useState(false);

  const initialValues = {
    email: "",
  };

  const onDismiss = () => {
    if (message) setMessage("");
    setVisible(!visible);
  };

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Email no valido";
    }

    return errors;
  };

  const onSubmit = (values) => {
    setSend(true);
    props.firebase
      .resetPassword(values.email)
      .then(() => {
        setMessage("Se ha enviado un mensaje de recuperación a su correo");
        setSend(false);
        setType("success");
        onDismiss();
      })
      .catch((error) => {
        setMessage("Usuario no existe");
        setSend(false);
        setType("danger");
        onDismiss();
      });
  };

  useEffect(() => {}, [send]);

  const formik = useFormik({ initialValues, onSubmit, validate });

  return (
    // <Container className="mt-5">
    //   <Row>
    //     <Col sm="12" md="12">
    //       {message ? (
    //         <Alert
    //           message={message}
    //           position="bottom"
    //           type={type}
    //           onDismiss={onDismiss}
    //           visible={visible}
    //           setVisible={setVisible}
    //         />
    //       ) : null}
    //       {send ? (
    //         <Progress animated color="success" value="100" />
    //       ) : (
    //         <div></div>
    //       )}
    //     </Col>
    //     <Col sm="12" md={{ size: 4, offset: 4 }}>
    //       <Form onSubmit={formik.handleSubmit}>
    //         <Card body>
    //           <Row>
    //             <Col md="12" sm="12">
    //               <Row>
    //                 <Col md="12">
    //                   <FormGroup>
    //                     <Label for="email">Email</Label>
    //                     <Input
    //                       type="email"
    //                       id="email"
    //                       name="email"
    //                       {...formik.getFieldProps("email")}
    //                       invalid={
    //                         !!(formik.errors.email && formik.touched.email)
    //                       }
    //                       placeholder="Escribe tu email"
    //                     ></Input>
    //                     <FormFeedback>{formik.errors.email}</FormFeedback>
    //                   </FormGroup>
    //                 </Col>
    //               </Row>

    //               <Button type="submit" color="primary" block>
    //                 Enviar Correo
    //               </Button>
    //               <Row>
    //                 <br />
    //                 <br />
    //                 <br />
    //               </Row>

    //               <p>
    //                 Tienes Cuenta?{" "}
    //                 <Link to={`/${getDomain()}/inicio`}>Iniciar Sesion</Link>
    //               </p>
    //             </Col>
    //           </Row>
    //         </Card>
    //       </Form>
    //     </Col>
    //   </Row>
    // </Container>

    <>
      <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
        <div class="mx-auto max-w-lg">
          <h1 class="text-center text-2xl font-bold text-[#2C2C7D] sm:text-3xl">
            Recuperar contraseña
          </h1>

          <div class="rounded-xl bg-white p-8 mt-12 shadow-lg lg:col-span-3 lg:p-12">
            <form onSubmit={formik.handleSubmit} class="space-y-4">
              <label
                htmlFor="email"
                class="block text-sm font-medium text-gray-700"
              >
                Correo Electrónico
              </label>

              <input
                type="email"
                id="email"
                name="email"
                {...formik.getFieldProps("email")}
                invalid={!!(formik.errors.email && formik.touched.email)}
                placeholder="Ingrese su email"
                class="mt-1 w-full py-3 px-4 rounded-full text-sm border border-gray-200 rounded-full focus:outline-none focus:ring focus:ring-[#282A70]"
              />

              <span className="text-red-500 text-sm">
                {formik.errors.email}
              </span>

              <div class="mt-4">
                <button
                  type="submit"
                  class="inline-flex lg:w-full md:w-full sm:w-full w-full items-center justify-center rounded-full bg-[#2E307C] hover:bg-[#282A70] px-5 py-3 text-white "
                >
                  <span class="font-medium">Enviar correo</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="ml-3 h-5 w-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                    />
                  </svg>
                </button>
                <p class="text-sm mt-2 text-center text-gray-500">
                  Tienes una cuenta? {""}
                  <Link
                    to={`/${getDomain()}/inicio`}
                    className="text-gray-700 underline"
                  >
                    Iniciar sesión
                  </Link>
                </p>
              </div>
            </form>
            {message ? (
              <>
                <Alert
                  message={message}
                  position="bottom"
                  type={type}
                  onDismiss={onDismiss}
                  visible={visible}
                  setVisible={setVisible}
                />
              </>
            ) : null}
            {send ? (
              <Progress animated color="success" value="100" />
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default withFirebase(PasswordReset);
