import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { utils } from "../../helpers";
import '@fortawesome/fontawesome-free/css/all.min.css';

import "./index.css";
import { Form } from "reactstrap";
const Perfil = (props) => {
  const { user } = props;
  const [modal, setModal] = useState(false);

  useEffect(() => {
    const redirectPath = window.location.href.replace('/perfil', '');
    // ...existing code...
  }, []);

  const toggleModal = () => setModal(!modal);

  const handleShare = () => {
    const currentUrl = window.location.href.replace('/perfil', '');
    const referralUrl = `${currentUrl}/registro?codigoReferido=${user.codRef}`;
    const siteTitle = document.title;
    if (navigator.share) {
      navigator.share({
        title: `Registro en ${siteTitle}`,
        text: `Regístrate en ${siteTitle} usando mi código de referido: ${user.codRef}`,
        url: referralUrl,
      }).then(() => {
        console.log('Compartido exitosamente');
      }).catch((error) => {
        console.error('Error al compartir:', error);
      });
    } else {
      const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(`Regístrate en ${siteTitle} usando mi código de referido: ` + referralUrl)}`;
      window.open(whatsappUrl, '_blank');
    }
  };

  const handleCopy = () => {
    const currentUrl = window.location.href.replace('/perfil', '');
    const referralUrl = `${currentUrl}/registro?codigoReferido=${user.codRef}`;
    navigator.clipboard.writeText(referralUrl);
    alert('URL copiada al portapapeles: ' + referralUrl);
  };

  return (
    <>
      <div className="lg:p-16">
        <div className="p-8 bg-white shadow mt-24 rounded-xl">
          <div className="grid grid-cols-1 md:grid-cols-1">
            <div className="relative">
              <div className="w-44 h-44 bg-indigo-100 mx-auto rounded-full shadow-2xl absolute inset-x-0 top-0 -mt-24 flex items-center justify-center text-indigo-500 text-4xl">
                {user.nivel && user.nivel.nivelImg ? (
                  <img
                    src={user.nivel.nivelImg}
                    alt="Nivel de lealtad"
                    className="w-full h-full rounded-full object-cover"
                  />
                ) : (
                  <i className="fa fa-id-card"></i>
                )}
              </div>
            </div>
          </div>

          <div className="mt-28 text-center border-b pb-12">
            <h1 className="text-4xl font-medium text-gray-700">
              {`${user.nombre} ${user.apellido}`}
            </h1>

            <span className="whitespace-nowrap mt-2 rounded-full bg-purple-100 px-2.5 py-0.5 text-sm text-purple-500">
              {`Cliente ${user.nivel ? user.nivel.nivelNombre : ""}`}
            </span>

            <p className="mt-8 text-gray-500">
              NIVEL : <span>{user.nivel ? user.nivel.nivelId : ""}</span>
            </p>
          </div>

          <div className="mt-12 flex flex-col justify-center">
            <p className="text-sm mb-8 font-semibold uppercase tracking-widest">
              Ver perfil
            </p>

            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Cédula
                </label>

                <input
                  type="text"
                  id="nombre"
                  value={user.numId || user.cedula || ''}
                  disabled
                  className="mt-1 w-full py-3 px-4 rounded-full  text-sm border border-gray-200 focus:outline-none focus:ring focus:ring-[#282A70]"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Nombre
                </label>
                <input
                  type="text"
                  id="apellido"
                  value={utils.capitalize(`${user.nombre}`) || ''}
                  disabled
                  className="mt-1 w-full py-3 px-4 rounded-full  text-sm border border-gray-200 focus:outline-none focus:ring focus:ring-[#282A70]"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Apellido
                </label>

                <input
                  type="text"
                  id="nombre"
                  value={utils.capitalize(`${user.apellido}`) || ''}
                  disabled
                  className="mt-1 w-full py-3 px-4 rounded-full  text-sm border border-gray-200 focus:outline-none focus:ring focus:ring-[#282A70]"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <input
                  type="text"
                  id="apellido"
                  value={user.email || ''}
                  disabled
                  className="mt-1 w-full py-3 px-4 rounded-full  text-sm border border-gray-200 focus:outline-none focus:ring focus:ring-[#282A70]"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Número Telefónico
                </label>

                <input
                  type="text"
                  id="nombre"
                  value={user.telefono || ''}
                  disabled
                  className="mt-1 w-full py-3 px-4 rounded-full  text-sm border border-gray-200 focus:outline-none focus:ring focus:ring-[#282A70]"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Código de Referido
                </label>

                <input
                  type="text"
                  id="codRef"
                  value={user.codRef || ''}
                  disabled
                  className="mt-1 w-full py-3 px-4 rounded-full  text-sm border border-gray-200 focus:outline-none focus:ring focus:ring-[#282A70]"
                />
              </div>
            </div>
            <div className="text-center mt-4">
              <button
                onClick={toggleModal}
                className="text-white mx-auto rounded-full inline-flex py-3 px-4 bg-[#2E307C] hover:bg-[#282A70] font-medium mt-4"
              >
                Referir
                <i className="fa fa-share ml-2 h-5 w-5"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Invita a tus amigos!</ModalHeader>
        <ModalBody>
          Comparte el siguiente enlace para invitar a tus amigos a registrarse en {document.title} usando tu código de referido: <strong>{user.codRef}</strong>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleShare}>Compartir</Button>{' '}
          <Button color="secondary" onClick={handleCopy}>Copiar URL</Button>{' '}
          <Button color="secondary" onClick={toggleModal}>Cerrar</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default React.memo(Perfil);
