import { call, all, put, takeLatest } from "redux-saga/effects";

import { tiendaActionTypes } from "./types";
import * as tiendaActions from "./actions";

import { fetchTienda, comprarRegalo, canjearRegalo } from "../../api/data";

export function* fetchTiendaAsync({ payload }) {
  try {
    const data = yield fetchTienda(payload);
    yield put(tiendaActions.fetchTiendaSuccess(data));
  } catch (error) {
    yield put(tiendaActions.fetchTiendaFailure(error));
  }
}

export function* comprarRegaloAsync({ payload }) {
  try {
    yield comprarRegalo(payload.token, payload.regaloId);
    yield put(tiendaActions.comprarRegaloSuccess());
  } catch (error) {
    yield put(tiendaActions.comprarRegaloFailed(error.message));
  }
}

export function* canjearRegaloAsync({ payload }) {
  try {
    const data = yield canjearRegalo(payload.token, payload.regaloId);
    yield put(tiendaActions.canjearRegaloSuccess(data));
  } catch (error) {
    yield put(tiendaActions.canjearRegaloFailed(error.message));
  }
}

export function* fetchTiendaListener() {
  yield takeLatest(tiendaActionTypes.FETCH_TIENDA_START, fetchTiendaAsync);
}

export function* comprarRegaloListener() {
  yield takeLatest(tiendaActionTypes.COMPRAR_REGALO_START, comprarRegaloAsync);
}

export function* canjearRegaloListener() {
  yield takeLatest(tiendaActionTypes.CANJEAR_REGALO_START, canjearRegaloAsync);
}

export function* tiendaSaga() {
  yield all([
    call(fetchTiendaListener),
    call(comprarRegaloListener),
    call(canjearRegaloListener)
  ]);
}
