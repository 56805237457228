export const userActionTypes = {
  USER_SIGNIN: "USER_SIGNIN",
  USER_SIGNIN_START: "USER_SIGNIN_START",
  USER_SIGNIN_SUCCESS: "USER_SIGNING_SUCCESS",
  USER_SIGNING_FAILED: "USER_SIGNIN_FAILED",
  USER_SIGNUP_START: "USER_SIGNUP_START",
  USER_SIGNUP_SUCCESS: "USER_SIGNUP_SUCCESS",
  USER_SIGNUP_FAILED: "USER_SIGNUP_FAILED",
  USER_LOGGED: "USER_LOGGED",
  USER_LOGOUT_START: "USER_LOGOUT_START",
  USER_LOGOUT_SUCCESS: "USER_LOGOUT_SUCCES",
  USER_LOGOUT_FAILED: "USER_LOGOUT_FAILED",
  USER_FETCH_ALL_DATA_START: "USER_FETCH_ALL_DATA",
  USER_FETCH_ALL_DATA_SUCCESS: "USER_FETCH_ALL_DATA_SUCCESS",
  USER_FETCH_ALL_DATA_FAILED: "USER_FETCH_ALL_DATA_SUCCESS",
  USER_FETCH_DATA_START: "USER_FETCH_DATA_START",
  USER_FETCH_DATA_FAILED: "USER_FETCH_DATA_FAILED",
  USER_FETCH_DATA_SUCCESS: "USER_FTECH_DATA_SUCCESS",
  USER_FETCH_REGALOS_START: "USER_FETCH_REGALOS_START",
  USER_FETCH_REGALOS_SUCCESS: "USER_FETCH_REGALOS_SUCCESS",
  USER_FETCH_REGALOS_FAILED: "USER_FETCH_REGALOS_FAILED",
  USER_FETCH_BASIC_START: "USER_FETCH_BASIC_START",
  USER_FETCH_BASIC_SUCCESS: "USER_FETCH_BASIC_SUCCESS",
  USER_FETCH_BASIC_FAILED: "USER_FETCH_BASIC_FAILED",
  USER_ERROR_MESSAGE_TO_NULL: "USER_ERROR_MESSAGE_TO_NULL",
  USER_SUCCESS_MESSAGE_TO_NULL: "USER_SUCCESS_MESSAGE_TO_NULL",
  REGISTRO_VALIDAR_USUARIO_APP_START: "REGISTRO_VALIDAR_USUARIO_APP_START",
  REGISTRO_VALIDAR_USUARIO_APP_SUCCESS: "REGISTRO_VALIDAR_USUARIO_APP_SUCCESS",
  REGISTRO_VALIDAR_USUARIO_APP_FAILED: "REGISTRO_VALIDAR_USUARIO_APP_FAILED",
  REGISTRO_VALIDAR_USUARIO_FIREBASE_START: "REGISTRO_VALIDAR_USUARIO_APP_START",
  REGISTRO_VALIDAR_USUARIO_FIREBASE_SUCCESS:
    "REGISTRO_VALIDAR_USUARIO_FIREBASE_SUCCESS",
  REGISTRO_VALIDAR_USUARIO_FIREBASE_FAILED:
    "REGISTRO_VALIDAR_USUARIO_FIREBASE_FAILED",
  CLEAR_PROCESS_REGISTRO: "CLEAR_PROCESS_REGISTRO",
  USER_VALIDATE_START: "USER_VALIDATE_START",
  USER_VALIDATE_SUCCESS: "USER_VALIDATE_SUCCESS",
  USER_VALIDATE_FAILED: "USER_VALIDATE_FAILED",
  USER_VALIDATE_FETCH_START: "USER_VALIDATE_FETCH_START",
  USER_VALIDATE_FETCH_SUCCESS: "USER_VALIDATE_FETCH_SUCCESS",
  USER_VALIDATE_FETCH_FAILED: "USER_VALIDATE_FETCH_FAILED",
  USER_VALIDATE_UPDATE_START: "USER_VALIDATE_UPDATE_START",
  USER_VALIDATE_UPDATE_SUCCESS: "USER_VALIDATE_UPDATE_SUCCESS",
  USER_VALIDATE_UPDATE_FAILED: "USER_VALIDATE_UPDATE_FAILED",
  USER_VALIDATE_CLEAN: "USER_VALIDATE_CLEAN",
  USER_VALIDATE_UPDATE_CLEAN: "USER_VALIDATE_UPDATE_CLEAN",
  USER_SETCUPON: "USER_SETCUPON",
  USER_DELETECUPON: "USER_DELETECUPON",
  USER_REFRESH_TOKEN: "USER_REFRESH_TOKEN"
};
