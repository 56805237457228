/* eslint-disable import/no-anonymous-default-export */
export default appId => {
  const defaultDemo = {
    apiKey: "AIzaSyAAhAK_yeZG-u1sx9ZQjf_zehsDUB1LgWo",
    authDomain: "clau-demo.firebaseapp.com",
    databaseURL: "https://clau-demo.firebaseio.com",
    projectId: "clau-demo",
    storageBucket: "clau-demo.appspot.com",
    messagingSenderId: "492409228314",
    appId: "1:492409228314:web:bc6cec53cc25c50f8f15a3",
    measurementId: "G-HVKG1C0JHV",
    webPush:
      "BD6HCddxOj0pPncG1F4drdcbL7z4xb-MsoNUwR1IojuJ9ogznxLWeWFYOKhvDxFNKRtDOY5bO4flRIReV7tpnN0"
  };

  switch (appId) {
    case "demo":
      return defaultDemo;

    case "panda":
      return {
        apiKey: "AIzaSyCcXVN-cBLnlvFgV81QkbJeTdwXJ39qxw4",
        authDomain: "claupanda-1cb68.firebaseapp.com",
        databaseURL: "https://claupanda-1cb68.firebaseio.com",
        projectId: "claupanda-1cb68",
        storageBucket: "",
        messagingSenderId: "227224650524",
        appId: "1:227224650524:web:61ca7736028e3eff",
        webPush:
          "BIiEI3ML4qQMeW82-h1fjhhQz6WnIJuWTbHEXenK_5cWKm-n_PhJ2jxiTrUSnE4au4zco-YJnn1jQXpCfEquYU8",
      };

    case "carretillon":
      return {
        apiKey: "AIzaSyCj6UYBs1UUV_YKlBfzz7uI8JQnNptfNC4",
        authDomain: "clausupercarnes.firebaseapp.com",
        databaseURL: "https://clausupercarnes.firebaseio.com",
        projectId: "clausupercarnes",
        storageBucket: "clausupercarnes.appspot.com",
        messagingSenderId: "453216550799",
        appId: "1:453216550799:web:60c23ff74e5b7841",
        webPush:
          "BB98TXg8TFIzDMcHskphBicYt1Ld_dCpfzF_wteCOBkuQGk1s59iqi3a3tKPl5Ew30gxnTTNmx0QFsXAww5a4mk",
      };
    case "local":
      return {
        apiKey: "AIzaSyAK8f3IkQmIYKC3ddKchIaeaVD8dd2kx_8",
        authDomain: "clau-local.firebaseapp.com",
        projectId: "clau-local",
        storageBucket: "clau-local.appspot.com",
        messagingSenderId: "751388509198",
        appId: "1:751388509198:web:8542510b800b50df2f285f",
        webPush:
          "BPGWKe-68z3IIZNMrUmYIwhcz4AVHwmJ8ngGaIut7RNLIhuJOjMr_aczUnwXWL-xTwj-D6VDqz5x_S3I7tOp7mY",
      };
    case "merkadoo":
      return {
        apiKey: "AIzaSyBF8NT0TOSkZa313NuT0oRamapug9l7h5o",
        authDomain: "claumerkdoo.firebaseapp.com",
        databaseURL: "https://claumerkdoo.firebaseio.com",
        projectId: "claumerkdoo",
        storageBucket: "claumerkdoo.appspot.com",
        messagingSenderId: "948910196230",
        appId: "1:948910196230:web:5193e2f6830e4650",
        webPush:
          "BM-WmnSVHcNOllzLzgybQhNC9vzGYZfRyTLqxT7SSZEAUf1vkHn4nspG4SP6r0hJJfQMYzL4fhlwmAuCnjW81Hw",
      };

    case "glow":
      return {
        apiKey: "AIzaSyBkesqEmFQXY6bDFAX7wrXVnw8BNFuF298",
        authDomain: "clau-app-glow.firebaseapp.com",
        databaseURL: "https://clau-app-glow.firebaseio.com",
        projectId: "clau-app-glow",
        storageBucket: "clau-app-glow.appspot.com",
        messagingSenderId: "918425509693",
        appId: "1:918425509693:web:ef5a5b2552ebd880",
        webPush:
          "BIUvhvi1dSWtwQrRow1tDIAweQtlqTl3Sz3VFTHt1fQAc7rmJcoYvaQUKX0U62XtnLgc0hfuvnVYhz03ck82zTk",
      };

    case "jumboplus":
      return {
        apiKey: "AIzaSyCXbQrBw0Dw_UM-H6xVMOjenQdAbqEPh34",
        authDomain: "clauappjumbo.firebaseapp.com",
        databaseURL: "https://clauappjumbo.firebaseio.com",
        projectId: "clauappjumbo",
        storageBucket: "clauappjumbo.appspot.com",
        messagingSenderId: "437126438889",
        appId: "1:437126438889:web:7f5ee55ddcd4c9b2",
        webPush:
          "BP341cmfc_KxbLrlvNoVilkNI_YxbuqPmUXlVJtBTXszOocRzom7n3x8CdsPtXE_vACds8WfhtDzeXwEIGCQQHo",
      };

    case "runningbalboa":
      return {
        apiKey: "AIzaSyBVdJdXp_prMmtYyYgek4homakoPgdChNM",
        authDomain: "clau-piazza.firebaseapp.com",
        databaseURL: "https://clau-piazza.firebaseio.com",
        projectId: "clau-piazza",
        storageBucket: "clau-piazza.appspot.com",
        messagingSenderId: "802884155315",
        appId: "1:802884155315:web:00b50e7ac4b3a6d5ec3dc6",
        measurementId: "G-THTXD48Z23",
        webPush:
          "BCqW7bX-4OvzvAASzjGwharzfIwrXN3yWjt0ZnWj668wHVLTd92cIw8Ceq0fJtRqIwA5_My-Adf7LCW1GAeBCvk",
      };

    case "todoadollar":
      return {
        apiKey: "AIzaSyAX2rl34NeC6dgxj0aGuelLGJyNdJxQqm8",
        authDomain: "clau-todoadollar.firebaseapp.com",
        databaseURL: "https://clau-todoadollar.firebaseio.com",
        projectId: "clau-todoadollar",
        storageBucket: "clau-todoadollar.appspot.com",
        messagingSenderId: "1073069098179",
        appId: "1:1073069098179:web:fe6b1d1622348db2b00e76",
        measurementId: "G-1MSE0YZB6Y",
        webPush:
          "BEqlK703LOB21EJJ_56sN8zEDLwyBOzdjz4PL-KBj3v-XchYZNCufd2Ss914W0a2qZY7kO-kGAPYnRZPGsMKK2A",
      };

    case "chickensabroso":
      return {
        apiKey: "AIzaSyCMG_8AR3x0rzZhjQOKXNeA1rDl8pSFIGg",
        authDomain: "clau-chickensabroso.firebaseapp.com",
        projectId: "clau-chickensabroso",
        storageBucket: "clau-chickensabroso.appspot.com",
        messagingSenderId: "933266399799",
        appId: "1:933266399799:web:02ba0c2ea41b9ee90dd5b2",
        measurementId: "G-1SVCKNZQKC",
        webPush:
          "BC62xOF7X8DrqSxXNXnI32VbrDNwsykQlpFvXOLDtze8P8eNgfT9Oj7lQj5Q8SghormsLi1-R2Up1VtMr1H2Dgo",
      };
    case "madison":
      return {
        apiKey: "AIzaSyAIZQGoPC_ITG-6QukDWxWNzOer8NrhApg",
        authDomain: "clau-madison-tova.firebaseapp.com",
        databaseURL: "https://clau-madison-tova.firebaseio.com",
        projectId: "clau-madison-tova",
        storageBucket: "clau-madison-tova.appspot.com",
        messagingSenderId: "551221316972",
        appId: "1:551221316972:web:5003166849fe196ded7749",
        measurementId: "G-PLEG3EZVHJ",
      };
    case "maagoos":
      return {
        apiKey: "AIzaSyDxRLpi6FfNYAPsiLCGzlaQourbRd_z1Lg",
        authDomain: "clau-maagoos.firebaseapp.com",
        projectId: "clau-maagoos",
        storageBucket: "clau-maagoos.appspot.com",
        messagingSenderId: "1099322682642",
        appId: "1:1099322682642:web:0e13ed72eb91bf6b2f17cb",
        webPush:
          "BPikCy2K2JiMOXtGOirr27BeJ4Afvv5OmnDnmVFWPVlz0aSdeUXHZCWv-j_EAFmNB19T7HA-wfoTv_EJDvq2T3E",
      };
    case "tosto":
      return {
        apiKey: "AIzaSyC5DEYDdokM-OJ46FpPCJb88YgcRFWS9vM",
        authDomain: "clau-tostocoffee.firebaseapp.com",
        projectId: "clau-tostocoffee",
        storageBucket: "clau-tostocoffee.appspot.com",
        messagingSenderId: "548869034010",
        appId: "1:548869034010:web:260a1d079d2d1c059e41a3",
        measurementId: "G-NZVGRMBVJR",
      };
    case "larosa":
      return {
        apiKey: "AIzaSyC2bhJWJQzRuMcFkyP5Sg6xzH33p3UUV2E",
        authDomain: "clau-taquerialarosa.firebaseapp.com",
        projectId: "clau-taquerialarosa",
        storageBucket: "clau-taquerialarosa.appspot.com",
        messagingSenderId: "519656848025",
        appId: "1:519656848025:web:8f885131840ba8be515608",
        measurementId: "G-CTZEQ9XHKH",
      };
    case "motatan":
      return {
        apiKey: "AIzaSyAup95IABB7WsHepz6FOHVWoIVI9o6sd5Y",
        authDomain: "clau-cafemotatan.firebaseapp.com",
        projectId: "clau-cafemotatan",
        storageBucket: "clau-cafemotatan.appspot.com",
        messagingSenderId: "500787709959",
        appId: "1:500787709959:web:5c060d7a5fec3e4ac6c2a2",
        webPush:
          "BB38CUkGRReUtvMMB0JMqX3Klp1vFPb5WMzdL52uW2NJHYVrQZXTaNyQaVbhpE5rXIbXx2u56MnauPh7-e0J8HY",
      };
    case "cafeduran":
      return {
        apiKey: "AIzaSyBd4y7dzIhPxfT71mEgO5Xkk5rfmraCO6c",
        authDomain: "clau-cafeduran.firebaseapp.com",
        projectId: "clau-cafeduran",
        storageBucket: "clau-cafeduran.appspot.com",
        messagingSenderId: "1021691185322",
        appId: "1:1021691185322:web:02f64210875b335d37e8d3",
        measurementId: "G-NVW2NM0Z1R",
        webPush:
          "BNzF5554itAIAC3Js_Sg_9H7rTCwNeVl2Gl8KhOBsWOv_7cte7uzzQN-I_W5ljz0Nnxb8liH1KhSmhsp3nOC-FY",
      };
    case "grancombo":
      return {
        apiKey: "AIzaSyA4gg7kX5aYzoVyVZy71LVvFAIcDQ3Iae4",
        authDomain: "clau-grancombo.firebaseapp.com",
        projectId: "clau-grancombo",
        storageBucket: "clau-grancombo.appspot.com",
        messagingSenderId: "938732986853",
        appId: "1:938732986853:web:cfeaa50eb931957fe4cf0c",
        webPush:
          "BNvLVQN5TcgcHHbopL1i_NFiVX7djF5F_vPkJzk7oxdpDcgxc2AdqMrdhyavOmQtupdnA_u2uriETeWSpl-0QFQ",
      };
    case "keenes":
      return {
        apiKey: "AIzaSyCR8RvQY6WowMqv3d9jpo4jzLB-4WFeWwA",
        authDomain: "clau-heladeriakeenes.firebaseapp.com",
        projectId: "clau-heladeriakeenes",
        storageBucket: "clau-heladeriakeenes.appspot.com",
        messagingSenderId: "847596910389",
        appId: "1:847596910389:web:8d2ac3548beed6cd317e7c",
        webPush:
          "BLffKay1jf1NUMRawnWniu4bb_xFhaND7yK3222b3GaCacwWYvnZVGj8SHiVOQaL8R4SenhvLRWFztnID6dhaII",
      };
    case "beep":
      return {
        apiKey: "AIzaSyBkv5w67SsFj_uAIbsxBA1awzIbITRbz48",
        authDomain: "clau-coffeebeep.firebaseapp.com",
        projectId: "clau-coffeebeep",
        storageBucket: "clau-coffeebeep.firebasestorage.app",
        messagingSenderId: "916660109334",
        appId: "1:916660109334:web:3abc7b6894560c45c17537",
        webPush:
          "BL0ph2eM-ytRR2PMPEGkEaCTCWhSv5ZNoxAzZPS1-pDg0_ENPcblmISCfmejLqW_X_JHcJOlAeLKw4EbYejtH28",
      };
    case "alleria":
      return {
        apiKey: "AIzaSyBoJpYrE6002X9mxr3IC_Zi62iaBS33mZU",
        authDomain: "clau-alleriacoffeebar.firebaseapp.com",
        projectId: "clau-alleriacoffeebar",
        storageBucket: "clau-alleriacoffeebar.firebasestorage.app",
        messagingSenderId: "768324487017",
        appId: "1:768324487017:web:b1a05dce6bcfaf5a935ff8",
        webPush:
          "BGm_AJhdk54ulkwBP5jZ9Xjw1ahXvLderBFu_MHQuuE1-zakvX35V5z_5dCezKpbioWklNlQB4crUupTgHVRV3I",
      };

    case "grupoattie":
      return {
        webPush:
          "BOFsGWndY-JgL_51p8CLgqUp1wNPj5B41rYtQuEjNqQvgyb4TJ06uGybIgIpMLMB98QShxaVSXYUzRiLvjj-nsc",
      };

    default:
      return defaultDemo;
  }
};
