const utils = {
  truncateText: function truncateText(text, length = 10) {
    this.text = text;
    if (text.length > length) return `${text.slice(0, length)}...`;
    return text;
  },

  capitalize: function capitalize(text) {
    this.text = text;
    if (!text) return text;
    text = text.toLowerCase();
    return text[0].toUpperCase() + text.slice(1);
  },
  convertDate: date => {
    const newDate = new Date(date);
    var day = ("0" + newDate.getDate()).slice(-2);
    var month = ("0" + (newDate.getMonth() + 1)).slice(-2);

    var today = newDate.getFullYear() + "-" + month + "-" + day;
    return today;
  },
  findItem: (array = [], id) => array.find(item => item)
};

export default utils;
