import React from "react";
import { Route, Redirect } from "react-router-dom";

import getDomain from "../../helpers/getDomain";

import Routes from "../../settings/routes";

export const AccessRoute = ({ component: Component, tag, ...rest }) => {
  const cliente = Routes.clientes[getDomain()];

  const isAvailable = cliente.find(item => item === tag);

  const renderItem = props => {
    if (!isAvailable && tag) {
      //console.log("componente con tag");
      return (
        <Redirect
          to={{
            pathname: `/${getDomain()}/noAccess`,
            state: {
              from: props.location
            }
          }}
        />
      );
    } else {
      return <Component {...props} />;
    }
  };

  return <Route {...rest} render={renderItem} />;
};

export default AccessRoute;
