export const tiendaActionTypes = {
  FETCH_TIENDA_START: 'FETCH_TIENDA_START',
  FETCH_TIENDA_SUCCESS: ' FETCH_TIENDA_SUCCESS',
  FETCH_TIENDA_FAILED: 'FETCH_TIENDA_FAILED',
  CANJEAR_REGALO_START: 'CANJEAR_REGALO_START',
  CANJEAR_REGALO_SUCCESS: 'CANJEAR_REGALO_SUCCESS',
  CANJEAR_REGALO_FAILED: 'CANJEAR_REGALO_FAILED',
  COMPRAR_REGALO_START: 'COMPRAR_REGALO_START',
  COMPRAR_REGALO_SUCCESS: 'COMPRAR_REGALO_SUCCESS',
  COMPRAR_REGALO_FAILED: 'COMPRAR_REGALO_FAILED',
  CLEAN_AFTER_CANJEAR: 'CLEAN_AFTER_CANJEAR',
  CLEAN_AFTER_COMPRAR: 'CLEAN_AFTER_COMPRAR'
}