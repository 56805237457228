import React, { useCallback } from "react";

import { Button, Row, Col, Container, CardBody, CardImg } from "reactstrap";
import SpinnerWrapper from "../../utils/spinnerWrapper";

import { canjearRegaloStart } from "../../../redux/tienda/actions";
import { selectIsDoingAsync } from "../../../redux/tienda/selectors";
import { useDispatch, useSelector } from "react-redux";

import { utils } from "../../../helpers";

const Modaldata = ({ data, toggle }) => {
  const dispatch = useDispatch();
  const token = useSelector(({ user }) => user.token);
  const isDoing = useSelector(selectIsDoingAsync);

  const canjearRegalo = useCallback(() => {
    dispatch(
      canjearRegaloStart({
        token: token,
        regaloId: data.rId
      })
    );
  }, [dispatch, data.rId, token]);

  const renderButton =
    data.rStatus !== 1 ? (
      <p>Cupon Bloqueado por Caja</p>
    ) : !data.rPunchMax ? (
      <Button
        className="datas-card-button"
        color="primary"
        onClick={canjearRegalo}
      >
        Canjear cupon
      </Button>
    ) : (
      <Button className="datas-card-button" color="success" onClick={e => {}}>
        Tarjeta perjorada{" "}
      </Button>
    );

  return (
    <Container className="overflow-auto">
      <Row>
        {data ? (
          <Col key={data.rId} sm="12" md="12" className="mb-4">
            <CardBody>
              <h5 className="card-title text-center text-capitalize">
                {utils.capitalize(data.rTitulo)}
              </h5>

              <div className="regalos-image-container">
                <CardImg
                  top
                  className="img-fluid"
                  src={data.rImg}
                  alt={data.rTitulo}
                />
              </div>

              <p className="mt-2 text-dark text-break text-center">
                {data.rDesc}
              </p>

              <p className="mt-2 text-dark text-break text-center">
                Expira el <b>{new Date(data.rFExp).toLocaleDateString()}</b>
              </p>

              <div className="datas-card-footer text-center">
                <SpinnerWrapper isFetching={isDoing}>
                  {renderButton}
                </SpinnerWrapper>
              </div>
            </CardBody>
          </Col>
        ) : null}
      </Row>
    </Container>
  );
};

export default React.memo(Modaldata);
