
export default async dto =>  {
  const {firebase, user:{ email, password }}= dto
  try{

    const user = await firebase.signInWithEmailAndPassword(email, password)
      
      return {
        user: {
          email,
          token: user.user.ma
        },
        firebase
      }

  }catch(error) {
      if(error.code === 'auth/wrong-password') error.message = 'Contraseña incorrecta'
      if(error.code === 'auth/invalid-email') error.message = 'formato de correo invalido'
      if(error.code === 'auth/user-not-found') error.message = 'El usuario no existe'
      if(error.message === '') error.message= 'Error al iniciar sesión'
      
      throw new Error(error.message)
    }
};

