import { tiendaActionTypes } from "./types";

const initialState = {
  isFetching: false,
  comprando: false,
  canjeando: false,
  comprado: false,
  canjeado: false,
  pagination: 10,
  cupon: "",
  productos: [],
  errorMessageFetch: "",
  errorMessageCompra: "",
  errorMessageCanjear: ""
};

const tiendaReducer = (currentState = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case tiendaActionTypes.FETCH_TIENDA_START:
      return {
        ...currentState,
        isFetching: true,
        comprado: false,
        comprando: false,
        canjeado: false,
        canjeando: false
      };

    case tiendaActionTypes.FETCH_TIENDA_SUCCESS:
      return {
        ...currentState,
        productos: payload,
        isFetching: false
      };

    case tiendaActionTypes.FETCH_TIENDA_FAILED:
      return {
        ...currentState,
        errorMessageFetch: payload,
        isFetching: false
      };

    case tiendaActionTypes.COMPRAR_REGALO_START:
      return {
        ...currentState,
        comprando: true,
        comprado: false
      };

    case tiendaActionTypes.COMPRAR_REGALO_SUCCESS:
      return {
        ...currentState,
        comprado: true,
        comprando: false
      };

    case tiendaActionTypes.COMPRAR_REGALO_FAILED:
      return {
        ...currentState,
        comprado: false,
        comprando: false,
        errorMessageCompra: payload
      };

    case tiendaActionTypes.CLEAN_AFTER_COMPRAR:
      return {
        ...currentState,
        comprado: false,
        comprando: false,
        errorMessageCompra: ""
      };

    case tiendaActionTypes.CANJEAR_REGALO_START:
      return {
        ...currentState,
        canjeado: false,
        canjeando: true
      };

    case tiendaActionTypes.CANJEAR_REGALO_SUCCESS:
      return {
        ...currentState,
        canjeado: true,
        canjeando: false,
        cupon: payload
      };
    case tiendaActionTypes.CANJEAR_REGALO_FAILED:
      return {
        ...currentState,
        canjeado: false,
        canjeando: false,
        errorMessageCanjear: payload
      };

    case tiendaActionTypes.CLEAN_AFTER_CANJEAR:
      return {
        ...currentState,
        canjeado: false,
        canjeando: false,
        errorMessageCanjear: ""
      };
    default:
      return currentState;
  }
};

export default tiendaReducer;
