import React, { useState, useEffect, useCallback } from "react";
import { Carousel, CarouselItem, CarouselIndicators } from "reactstrap";
import SpinnerWrapper from "../utils/spinnerWrapper";
import { useSelector } from "react-redux";
import {
  selectBannerApiState,
  selectBanners,
} from "../../redux/banner/selectors.js";

import "./index.css";

const Banner = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const banners = useSelector(selectBanners);
  const bannerState = useSelector(selectBannerApiState);

  const next = useCallback(() => {
    if (animating) return;
    const nextIndex = activeIndex === banners.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }, [activeIndex, animating, banners.length]);

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? banners.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  useEffect(() => {
    const abortController = new AbortController();
    const interval = setInterval(() => next(), 15000);
    return () => {
      abortController.abort();
      clearInterval(interval);
    };
  }, [animating, activeIndex, next]);

  const slides = banners.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.id}
      >
        <div className="banner-image-container">
          <img
            style={{ borderRadius: "25px" }}
            src={item.url}
            alt={item.altText || "imagen"}
          />
        </div>
      </CarouselItem>
    );
  });

  return (
    <SpinnerWrapper isFetching={bannerState.isFetching}>
      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
        <CarouselIndicators
          items={banners}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {slides}
      </Carousel>
    </SpinnerWrapper>
  );
};

export default Banner;
