import { all, call } from "redux-saga/effects";
import { bannerSaga } from "./banner/sagas";
import { userSaga } from "./user/sagas";
import { tiendaSaga } from "./tienda/sagas";
import { citasSaga } from "./citas/sagas";
export default function* rootSaga() {
  yield all([
    call(bannerSaga),
    call(userSaga),
    call(tiendaSaga),
    call(citasSaga)
  ]);
}
