import citasTypes from "./types";

const INITIAL_STATE = {
  citas: [],
  disponibilidad: [],
  categorias: [],
  isScheduling: false,
  isFetchingCitas: false,
  isFetchingCategorias: false,
  isFetchingDisponibilidad: false,
  successScheduling: false,
  errorMessageFetchCitas: "",
  errorMessageFetchCategorias: "",
  errorMessageFetchDisponibilidad: "",
  errorMessageScheduling: ""
};

export default (currentState = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case citasTypes.FETCH_CITAS_USUARIO_START:
      return {
        ...currentState,
        isFetchingCitas: true,
        errorMessageFetchCitas: "",
        successScheduling: false
      };
    case citasTypes.FETCH_CITAS_USUARIO_SUCCESS:
      return {
        ...currentState,
        citas: payload,
        isFetchingCitas: false
      };
    case citasTypes.FETCH_CITAS_USUARIO_FAILED:
      return {
        ...currentState,
        isFetchingCitas: false,
        errorMessageFetchCitas: payload
      };

    case citasTypes.FETCH_CITAS_CATEGORIA_START:
      return {
        ...currentState,
        isFetchingCategorias: true,
        errorMessageFetchCategorias: "",
        successScheduling: false
      };

    case citasTypes.FETCH_CITAS_CATEGORIA_SUCCESS:
      return {
        ...currentState,
        isFetchingCategorias: false,
        categorias: payload
      };

    case citasTypes.FETCH_CITAS_CATEGORIA_FAILED:
      return {
        ...currentState,
        isFetchingCategorias: false,
        errorMessageFetchCategorias: payload,
        categorias: []
      };

    case citasTypes.FETCH_CITAS_DISPONIBILIDAD_START:
      return {
        ...currentState,
        isFetchingDisponibilidad: true,
        errorMessageFetchDisponibilidad: ""
      };

    case citasTypes.FETCH_CITAS_DISPONIBILIDAD_SUCCESS:
      return {
        ...currentState,
        isFetchingDisponibilidad: false,
        disponibilidad: payload,
        errorMessageFetchDisponibilidad: ""
      };

    case citasTypes.FETCH_CITAS_DISPONIBILIDAD_FAILED:
      return {
        ...currentState,
        isFetchingDisponibilidad: false,
        errorMessageFetchDisponibilidad: payload,
        disponibilidad: []
      };

    case citasTypes.AGENDAR_CITA_START:
      return {
        ...currentState,
        isScheduling: true,
        errorMessageScheduling: "",
        successScheduling: false
      };

    case citasTypes.AGENDAR_CITA_SUCCESS:
      return {
        ...currentState,
        isScheduling: false,
        successScheduling: true
      };
    case citasTypes.AGENDAR_CITA_FAILED:
      return {
        ...currentState,
        isScheduling: false,
        errorMessageScheduling: payload,
        successScheduling: false
      };

    default:
      return currentState;
  }
};
