import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useFormik, ErrorMessage, Formik } from "formik";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  Row,
  Col,
  FormFeedback,
} from "reactstrap";

import formikConfig from "./formikConfig";
import { withFirebase } from "../../firebase/context";

import Banner from "../banner/banner";
import Alert from "../alert";
import SpinnerWrapper from "../utils/spinnerWrapper";

import { userSignInStart } from "../../redux/user/actions";
//import { setMessage } from "../../redux/messages/actions";
import { getBannerStart } from "../../redux/banner/actions";

import useWindows from "../../hooks/windows-dimension";

import getDomain from "../../helpers/getDomain";

// TODO: mostrar mensajes de error
//CSS de banner
import "./index.css";

//Carousel slider images

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const SignInForm = (props) => {
  const dispatch = useDispatch();
  const redirectPath = { pathname: `/${getDomain()}/perfil` };
  const { width } = useWindows();
  const [message, setMessage] = useState("");
  const [visible, setVisible] = useState(false);
  const [showBanner, setShowBanner] = useState(true);
  const history = useHistory();

  const { errorMessageSignin, isSignin, isLoged } = useSelector(
    (state) => state.user
  );

  const onDismiss = useCallback(() => {
    if (message) setMessage("");
    setVisible(!visible);
  }, [message, visible]);

  useEffect(() => {
    dispatch(getBannerStart());
  }, [dispatch]);

  useEffect(() => {
    // if (width < 600) setShowBanner(false);
    // else
     setShowBanner(true);
  }, [width]);

  useEffect(() => {
    if (isLoged) {
      history.replace(redirectPath);
    }
  }, [isLoged, history, redirectPath, dispatch]);

  const onSubmit = useCallback(
    (values) => {
      if (!isSignin) {
        dispatch(userSignInStart({ firebase: props.firebase, user: values }));
      } else {
        // console.log("iniciando sesion");
      }
    },
    [dispatch, isSignin, props.firebase]
  );

  useEffect(() => {
    if (!message) {
      setMessage(errorMessageSignin);
      setVisible(true);
    }

    return () => null;
  }, [isSignin, errorMessageSignin, message]);

  formikConfig.onSubmit = onSubmit;
  const formik = useFormik(formikConfig);

  return (
    <>
      {/* <Form onSubmit={formik.handleSubmit}>
        {message ? (
          <Alert
            message={errorMessageSignin}
            position="bottom"
            type="warning"
            onDismiss={onDismiss}
            visible={visible}
            setVisible={setVisible}
          />
        ) : null}
        <Card body>
          <Row>
            <Col md="6" sm="12">
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      type="email"
                      id="email"
                      name="email"
                      {...formik.getFieldProps("email")}
                      invalid={!!(formik.errors.email && formik.touched.email)}
                      placeholder="Escribe tu email"
                    ></Input>
                    <FormFeedback>{formik.errors.email}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label for="Password">Password</Label>
                    <Input
                      type="password"
                      id="password"
                      name="password"
                      {...formik.getFieldProps("password")}
                      invalid={
                        !!(formik.errors.password && formik.touched.password)
                      }
                      placeholder="escribe tu contraseña"
                      autoComplete="current-password"
                    ></Input>
                    <FormFeedback>{formik.errors.password}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>

              <SpinnerWrapper isFetching={isSignin}>
                <Button type="submit" color="primary" block>
                  Iniciar sesion
                </Button>
              </SpinnerWrapper>
              <Row>
                <br />
                <br />
                <br />
              </Row>
              <p>
                Olvidastes Tu Contraseña?{" "}
                <Link to={`/${getDomain()}/password/reset`}>
                  Recuperar contraseña
                </Link>
              </p>

              <p>
                No Tienes Cuenta?{" "}
                <Link to={`/${getDomain()}/registro`}>Registro</Link>
              </p>
            </Col>

            <Col md="6" sm="12" className="banner-container ">
              {showBanner ? <Banner /> : null}
            </Col>
          </Row>
        </Card>
      </Form> */}

      {/* <section class="bg-white">
        <div class="lg:grid lg:h-[93.5vh] lg:grid-cols-12">
          <section class="relative flex h-60 items-end bg-gray-900 lg:col-span-5 lg:h-full xl:col-span-6">
            <img
              alt="Logo Clau.io"
              src="https://img.invupos.com/clauwebapp/Cover%203.png"
              class="absolute inset-0 h-full w-full object-cover opacity-80 object-top"
            />

            <div class="hidden lg:relative lg:block lg:p-12">
              {showBanner ? <Banner /> : null}
            </div>
          </section>

          <main
            aria-label="Main"
            class="flex items-center justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:py-12 lg:px-16 xl:col-span-6"
          >
            <div class="max-w-xl lg:max-w-7xl">
              <div class="relative -mt-16 block lg:hidden">
                <a
                  class="inline-flex h-16 w-16 items-center justify-center rounded-full bg-gray-50 border-3 border-[#fff] text-blue-600 sm:h-20 sm:w-20"
                  href="/"
                >
                  <span class="sr-only">Home</span>
                  <img
                    src="https://img.invupos.com/clauwebapp/Clau%20logo.svg"
                    className="h-7"
                  />
                </a>

                <h1 class="mt-2 text-2xl font-bold text-gray-900 sm:text-3xl md:text-4xl">
                  Iniciar Sesión
                </h1>

                <p class="mt-4 leading-relaxed text-gray-500">
                  El poder de la data traducido en venta. Empieza ya!
                </p>
              </div>

              <h1 class="mt-2 text-2xl lg:block hidden font-bold text-gray-900 sm:text-3xl md:text-4xl">
                Iniciar Sesión
              </h1>

              <p class="mt-4 leading-relaxed lg:block hidden text-gray-500">
                El poder de la data traducido en venta. Empieza ya!
              </p>

              <form
                onSubmit={formik.handleSubmit}
                class="mt-8 grid grid-cols-6 gap-6"
              >
                {message ? (
                  <Alert
                    message={errorMessageSignin}
                    position="bottom"
                    type="warning"
                    onDismiss={onDismiss}
                    visible={visible}
                    setVisible={setVisible}
                  />
                ) : null}
                <div class="col-span-6">
                  <FormGroup>
                    <label
                      htmlFor="email"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Correo Electrónico
                    </label>

                    <input
                      type="email"
                      id="email"
                      name="email"
                      {...formik.getFieldProps("email")}
                      invalid={!!(formik.errors.email && formik.touched.email)}
                      placeholder="Ingrese su email"
                      class="mt-1 w-full py-3 px-4 rounded-full  text-sm border border-gray-200 rounded-full focus:outline-none focus:ring focus:ring-orange-600"
                    />
                    <FormFeedback>{formik.errors.email}</FormFeedback>
                  </FormGroup>
                </div>
                <div class="col-span-6">
                  <label
                    htmlFor="Password"
                    class="block text-sm font-medium text-gray-700"
                  >
                    Contraseña
                  </label>

                  <input
                    type="password"
                    id="password"
                    name="password"
                    {...formik.getFieldProps("password")}
                    invalid={
                      !!(formik.errors.password && formik.touched.password)
                    }
                    placeholder="Escribe tu contraseña"
                    autoComplete="current-password"
                    class="mt-1 w-full py-3 px-4 rounded-full  text-sm border border-gray-200 rounded-full focus:outline-none focus:ring focus:ring-orange-600"
                  />
                  <FormFeedback>{formik.errors.password}</FormFeedback>
                </div>
                <div class="lg:col-span-4 col-span-6">
                  <p class="text-sm text-center text-gray-500">
                    Has Olvidado tu contraseña? {""}
                    <Link
                      to={`/${getDomain()}/password/reset`}
                      className="text-gray-700 underline"
                    >
                      Recuperar contraseña aquí
                    </Link>
                  </p>
                </div>

                <div class="col-span-6 sm:flex sm:items-center sm:gap-4">
                  <SpinnerWrapper isFetching={isSignin}>
                    <button class="w-full inline-block shrink-0 rounded-full border  bg-orange-500 hover:bg-orange-600 px-12 py-3 text-sm font-medium text-white">
                      Iniciar sesión
                    </button>
                  </SpinnerWrapper>
                </div>
                <div class="col-span-6">
                  <p class="text-sm text-center text-gray-500">
                    Aún no tienes una cuenta? {""}
                    <Link
                      to={`/${getDomain()}/registro`}
                      className="text-gray-700 underline"
                    >
                      Regístrate aquí
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </main>
        </div>
      </section> */}

      <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
        <div class="mx-auto max-w-2xl">
          <h1 class="text-center text-2xl font-bold text-[#2C2C7D] sm:text-3xl">
            Iniciar sesión
          </h1>

          {/* <p class="mx-auto mt-4  max-w-lg text-center text-gray-500">
            Premia la lealtad de tus clientes y aumenta tus ventas. Todo desde
            una plataforma personalizable y fácil de usar.
          </p> */}

          <div class="rounded-xl bg-white p-8 mt-12 shadow-lg lg:col-span-3 lg:p-12">
            <form onSubmit={formik.handleSubmit} class="space-y-4">
              {message ? (
                <Alert
                  message={errorMessageSignin}
                  position="bottom"
                  type="warning"
                  onDismiss={onDismiss}
                  visible={visible}
                  setVisible={setVisible}
                />
              ) : null}

              <label
                htmlFor="email"
                class="block text-sm font-medium text-gray-700"
              >
                Correo Electrónico
              </label>

              <input
                type="email"
                id="email"
                name="email"
                {...formik.getFieldProps("email")}
                invalid={!!(formik.errors.email && formik.touched.email)}
                placeholder="Ingrese su email"
                className="input1"
                class="mt-1 w-full py-3 px-4 rounded-full text-sm border border-gray-200 rounded-full focus:outline-none focus:ring focus:ring-[#282A70]"
              />

              <span className="text-red-500 text-sm">
                {formik.errors.email}
              </span>

              <label
                htmlFor="email"
                class="block text-sm font-medium text-gray-700"
              >
                Contraseña
              </label>

              <input
                type="password"
                id="password"
                name="password"
                {...formik.getFieldProps("password")}
                invalid={!!(formik.errors.password && formik.touched.password)}
                placeholder="Escribe tu contraseña"
                autoComplete="current-password"
                className="mt-1 w-full py-3 px-4 rounded-full  text-sm border border-gray-200 rounded-full focus:outline-none focus:ring focus:ring-[#282A70]"
              />
              <span className="text-red-500 text-sm">
                {formik.errors.password}
              </span>

              <div class="mt-4">
                <p class="text-sm text-center text-gray-500">
                  Has Olvidado tu contraseña? {""}
                  <Link
                    to={`/${getDomain()}/password/reset`}
                    className="text-gray-700 underline"
                  >
                    Recuperar contraseña aquí
                  </Link>
                </p>
                <SpinnerWrapper isFetching={isSignin}>
                  <button
                    type="submit"
                    class="inline-flex lg:w-full md:w-full sm:w-full w-full items-center justify-center rounded-full bg-[#2E307C] hover:bg-[#282A70] px-5 py-3 text-white "
                  >
                    <span class="font-medium"> Iniciar sesión </span>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="ml-3 h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                      />
                    </svg>
                  </button>
                </SpinnerWrapper>
                <p class="text-sm mt-2 text-center text-gray-500">
                  Aún no tienes una cuenta? {""}
                  <Link
                    to={`/${getDomain()}/registro`}
                    className="text-gray-700 underline"
                  >
                    Regístrate aquí
                  </Link>
                </p>
                <div className="mt-10">{showBanner ? <Banner /> : null}</div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default withFirebase(SignInForm);
//export default SignInForm;
