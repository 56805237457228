const PRODUCTIONDOMAINAPI = "https://intermediario2.clau.io";
const PRODUCTIONDOMAINREACT = 'https://portal.clau.io'

const actualdomain = window.location.origin;

const apiDomain = "https://intermediario2.clau.io"; //"http://localhost:80/";

const isProduction = () => actualdomain === PRODUCTIONDOMAINREACT;

/*creacion de variables de entorno para que la app detecte 
  si esta en producion o en desarrollo en base al dominio
*/
const ENVIRONMENT = Object.freeze({
  API: isProduction() ? PRODUCTIONDOMAINAPI : apiDomain,
  PRODUCTION: isProduction() ? "PRODUCTION" : "DEVELOPMENT",
  API_KEY_CONSULT: '030b0205-af1f-4e63-b40e-da6417818d27'
});

export default ENVIRONMENT;
