import React, { useState, useCallback } from "react";

import "./index.css";

const initialStyles = {
  display: "none"
};
const Overlay = ({ text, action, color, children }) => {
  const [styles, setStyles] = useState(initialStyles);

  const onMouseOn = useCallback(() => {
    setStyles({
      ...styles
    });
  }, [styles]);

  const onMouseOut = useCallback(() => {
    // console.log(styles);
    setStyles(initialStyles);
  }, []);

  return (
    <div
      onMouseMove={onMouseOn}
      onMouseOut={onMouseOut}
      className="container-overlay m-0 p-0"
    >
      <div className="overlay-item m-0 p-0" style={styles}></div>
      {children ? children : null}
    </div>
  );
};

export default React.memo(Overlay);
