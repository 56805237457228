import React from "react";
import AuthUserContext from "./context";
import { withFirebase } from "../context";
import getCustomToken from "../../helpers/getCustomToken";

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authUser: this.props.firebase.currentUser,
        token: null,
        loading: true,
      };
    }

    componentDidMount() {
      console.log("withAuthentication componentDidMount");
      // console.log(this.props.firebase);
      // const auth = this.props.firebase.getAuth();

      this.listener = this.props.firebase.auth.onAuthStateChanged(authUser => {
        if (authUser) {
          this.setState({ authUser, loading: false });
          // Retrieve the token directly from the authUser instance
          this.props.firebase.getRefreshToken(token => {
            //(authUser, token);
            if (this.state.token !== token)
              this.setState({ token, authUser, loading: false });
          });
        } else {
          const customToken = getCustomToken();
          if (customToken) {
            this.props.firebase.signInWithCustomToken(customToken)
              .then((userCredential) => {
                // Signed in
              })
              .catch((error) => {
                console.error("Custom token sign-in error:", error.message);
              });
          }

          this.setState({ authUser: null, loading: false });
        }
      });
    }

    componentWillUnmount() {
      if (this.listener) {
        this.listener();
      }
    }

    render() {
      const { authUser, token, loading } = this.state;

      return (
        <AuthUserContext.Provider value={authUser}>
          <Component
            {...this.props}
            authUser={authUser}
            token={token}
            loading={loading}
          />
        </AuthUserContext.Provider>
      );
    }
  }

  return withFirebase(WithAuthentication);
};

export default withAuthentication;
