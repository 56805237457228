import * as Yup from "yup";
import * as moment from "moment";

function validarFecha(value) {
  moment().local();

  const actual = moment(new Date())
    .utc()
    .format();

  if (moment(value).isValid()) {
    value = moment(value)
      .utc()
      .format();
    // console.log(value, actual);
    //console.log(actual, value, moment(value).isSameOrAfter(actual));
    return moment(value).isSameOrAfter(actual);
  }
}

export const initialValues = {
  fecha: "",
  categoria: "",
  comentarios: "",
  slot: "",
  status: 0
};

export const validationSchema = Yup.object({
  fecha: Yup.date()
    .required("Campo requerido")
    .test("date-valid", "Debes escoger una fecha proxima", validarFecha),
  categoria: Yup.string().required("Campo requerido"),
  slot: Yup.string().required("Campo requerido")
});

export default {
  initialValues,
  validationSchema
};
