import { createSelector } from "reselect";

import converToArrays from "../../helpers/convertToarrays";

const selectUserState = state => state.user;

export const selectUser = createSelector([selectUserState], user => user);
export const selectUserData = createSelector(
  [selectUserState],
  ({ user }) => user
);
export const selectUserToken = ([selectUserState], ({ token }) => token);

export const selectUserApiState = createSelector(
  [selectUserState],
  ({ isUserData, isFetchingUser }) => ({ isUserData, isFetchingUser })
);

export const selectIsFetchingUser = createSelector(
  [selectUserState],
  ({
    isFetchingRegalos,
    isFetchingUser,
    isFetchingValidate,
    isFetchingTienda
  }) =>
    isFetchingRegalos ||
    isFetchingUser ||
    isFetchingTienda ||
    isFetchingValidate
);

export const selectIsDoingProccess = createSelector(
  [selectUserState],
  ({ isSignin, isSignUp, isValidateUpdating, isLogingOut }) =>
    isSignin || isSignUp || isValidateUpdating || isLogingOut
);

export const selectRegalosArray = createSelector(
  [selectUserState],
  ({ regalos }) => converToArrays(regalos, 10)
);
