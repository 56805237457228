export default (array = [], perPage) => {
  const newArray = [];
  let index = 0;
  let offset = 0;
  if (array.length) {
    const levels = Math.ceil(array.length / perPage);

    while (index < levels) {
      newArray.push(array.slice(offset, offset + perPage));
      offset += perPage;
      index++;
    }
  }

  return newArray;
};
