import React from "react";

import { Spinner } from "reactstrap";

const SpinnerWrapper = (props) => {
  return (
    <React.Fragment>
      {props.isFetching ? (
        <div className="text-center">
          <Spinner color="dark" />
        </div>
      ) : props.children || props.childrens ? (
        props.children
      ) : null}
    </React.Fragment>
  );
};

export default React.memo(SpinnerWrapper);
