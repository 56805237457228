export default user => ({
  UsuarioName: user.nombre || "",
  Usuariolastname: user.apellido || "",
  UsuarioCorreo: user.email || "",
  UsuarioTelefono: user.celular || "",
  UsuarioCodigoReferido: user.codigoReferido || "",
  UsuarioTipoID: user.tipoDocumento || "",
  UsuarioNumID: user.documento || "",
  UsuarioBirth: user.fecha || "",
  UsuarioGender: user.genero || "",
  UsuarioToken: user.token || "",
  UsuarioDeviceOS: user.device || ""
});
