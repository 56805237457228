import { call, all, put, takeLatest, debounce } from "redux-saga/effects";
import { userActionTypes } from "./types";
import * as actions from "./actions";
import * as tiendActions from "../tienda/actions";
import { setDomain } from "../navigation/actions";
//import { setMessage } from "../messages/actions";
import { signInUser, signUpUser, validateUserApp } from "../../api/user";
import * as apiValidate from "../../api/validate";
import { fetchUser, fetchRegalos, fetchOnlyUser } from "../../api/data";
import getDomain from "../../helpers/getDomain";
// TODO: terminas proceso de signup y signIn , verificacion de dominio

export function* signInAsync({ payload }) {
  try {
    const data = yield signInUser(payload);
    yield put(actions.userSignInSuccess(data));

    yield put(setDomain(getDomain()));
    yield put(actions.fetchUserDataStart(data.user.token));
    yield put(tiendActions.fetchTiendaStart(data.user.token));
  } catch (error) {
    // console.log("error");
    yield put(actions.userSignInFailed(error));
  }
}

export function* signUpAsync({ payload }) {
  try {
    const data = yield signUpUser(payload);
    yield put(actions.userSignUpSuccess(data));
    yield put(actions.fetchUserDataStart(data.user.token));
    yield put(tiendActions.fetchTiendaStart(data.user.token));
  } catch (error) {
    yield put(actions.userSignUpFailed({ user: payload.user, error }));
  }
}

export function* userValidateAppAsync({ payload }) {
  try {
    yield validateUserApp(payload.user);
    yield put(actions.userSignUpStart(payload));
  } catch (error) {
    yield put(actions.validarUserAppFailed(error.stack));
  }
}

export function* logOutAsync() {
  try {
    yield console.log("cerrando sesion............."); //logOut(payload);
    yield put(actions.userLogoutSuccess());
  } catch (error) {
    yield put(actions.userLogoutFailed(error.message));
  }
}

export function* fetchUserAsync({ payload }) {
  try {
    const user = yield fetchUser(payload);
    yield put(actions.fetchUserDataSucces(user));
  } catch (error) {
    yield put(actions.fetchUserDataFailed(error));
  }
}

export function* fetchRegalosAsync({ payload }) {
  try {
    const regalos = yield fetchRegalos(payload);
    yield put(actions.fetchRegalosSuccess(regalos));
  } catch (error) {
    yield put(actions.fetchRegalosFailed(error));
  }
}

export function* validateUserAsync({ payload }) {
  try {
    yield apiValidate.validateUser(payload);

    yield put(
      actions.userValidateSuccess({
        numId: payload.cedula,
        celular: payload.celular
      })
    );
    yield put(
      actions.userValidateFetchStart({
        cedula: payload.cedula,
        celular: payload.celular
      })
    );
  } catch (error) {
    yield put(actions.userValidateFailed(error));
  }
}

export function* fetchValidateUsersAsync({ payload }) {
  try {
    const user = yield apiValidate.fetchValidateUser(payload);
    yield put(actions.userValidateFetchSuccess(user));
  } catch (error) {
    yield put(actions.userValidateFetchFailed(error.message));
  }
}

export function* updateValidateUsersAsync({ payload }) {
  try {
    yield apiValidate.finalizarRegistroUpdate(payload);
    yield put(actions.userValidateUpdateSuccess());
  } catch (error) {
    yield put(actions.userValidateUpdateFailed(error.message));
  }
}

export function* userFetchBasicAsync({ payload }) {
  try {
    const user = yield fetchOnlyUser(payload);
    yield put(actions.userFetchBasicSuccess(user));
  } catch (error) {
    yield put(actions.userFetchBasicFailed(error.message));
  }
}

export function* validateUserListener() {
  yield takeLatest(userActionTypes.USER_VALIDATE_START, validateUserAsync);
}

export function* signInListener() {
  yield takeLatest(userActionTypes.USER_SIGNIN_START, signInAsync);
}

export function* signUpListener() {
  yield takeLatest(userActionTypes.USER_SIGNUP_START, signUpAsync);
}

export function* logOutListener() {
  yield takeLatest(userActionTypes.USER_LOGOUT_START, logOutAsync);
}

export function* fetchUserListener() {
  yield debounce(1000, userActionTypes.USER_FETCH_DATA_START, fetchUserAsync);
}

export function* fetchRegalosListener() {
  yield takeLatest(userActionTypes.USER_FETCH_REGALOS_START, fetchRegalosAsync);
}

export function* fetchValidateUserListener() {
  yield takeLatest(
    userActionTypes.USER_VALIDATE_FETCH_START,
    fetchValidateUsersAsync
  );
}

export function* updateValidateUserListener() {
  yield takeLatest(
    userActionTypes.USER_VALIDATE_UPDATE_START,
    updateValidateUsersAsync
  );
}

export function* userFetchBasicListener() {
  yield takeLatest(userActionTypes.USER_FETCH_BASIC_START, userFetchBasicAsync);
}

export function* userValidateAppListener() {
  yield takeLatest(
    userActionTypes.REGISTRO_VALIDAR_USUARIO_APP_START,
    userValidateAppAsync
  );
}

export function* userSaga() {
  yield all([
    call(signInListener),
    call(signUpListener),
    call(logOutListener),
    call(fetchUserListener),
    call(fetchRegalosListener),
    call(validateUserListener),
    call(fetchValidateUserListener),
    call(updateValidateUserListener),
    call(userFetchBasicListener),
    call(userValidateAppListener)
  ]);
}
