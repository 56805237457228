const domainSettings = Object.freeze({
  demo: {
    domain: "demo",
    terminos: "https://cdn.clau.io/terms/privacy.pdf",
    logo:
      "https://dashboard.clau.io/dashboard/app-assets/img/logos/logo_supercarnes.png",
    navbar: {
      background: "demo-background"
    }
  },
  local: {
    domain: "local",
    terminos: "https://cdn.clau.io/terms/privacy.pdf",
    logo:
      "https://cdn-multi.clau.io/1/logo-local.png",
    navbar: {
      background: "white-background"
    }
  },
  carretillon: {
    domain: "carretillon",
    terminos: "https://cdn.clau.io/terms/privacy.pdf",
    logo: "https://dashboard.clau.io/dashboard/app-assets/img/logos/logo_supercarnes.png",
    navbar: {
      background: "white-background"
    }
  },
  //pendiente a cambios por terminos y condiciones originales
  merkadoo: {
    domain: "merkadoo",
    terminos: "https://cdn.clau.io/terms/privacy.pdf",
    logo: "https://s3.us-east-2.amazonaws.com/clau.app.merkdoo/lgoomerk.png",
    navbar: {
      background: "merkadoo-background"
    }
  },
  jumboplus: {
    domain: "jumboplus",
    terminos: "https://jumbodotrecursos.clau.io/terminos_Jumbo.html",
    logo: "https://jumbodotrecursos.clau.io/1572641078-9504.png",
    navbar: {
      background: "white-background"
    }
  },
  runningbalboa: {
    domain: "runningbalboa",
    terminos: "https://cdn.clau.io/terms/privacy.pdf",
    logo: "https://recursosdotpiazza.clau.io/logo/piaza.jpeg",
    navbar: {
      background: "black-background"
    }
  },
  chickensabroso:{
    domain: "chickensabroso",
    terminos:
      "https://cdn.clau.io/terms/privacy.pdf",
    logo: "https://dashboard.clau.io/dashboard/app-assets/img/logos/logo_supercarnes.png",
    navbar: {
      background: "white-background"
    }
  },
  madison: {
    domain: "madison",
    terminos:
      "https://recursosdotmadison.clau.io/terminos/madisoncondiciones.html",
    logo: "https://www.adnde.com/getLogo.php?5614",
    navbar: {
      background: "black-background"
    }
  },
  glow: {
    domain: "glow",
    terminos: "https://cdn.clau.io/terms/privacy.pdf",
    logo:
      "https://dashboard.clau.io/dashboard/app-assets/img/logos/logo-glow.png",
    navbar: {
      background: "white-background"
    }
  },
  panda: {
    domain: "panda",
    terminos: "https://pandadotrecursos.clau.io/terminos/panda_terminos.pdf",
    logo:
      "https://dashboard.clau.io/dashboard/app-assets/img/logos/logo_panda.png",
    navbar: {
      background: "black-background"
    }
  },
  todoadollar: {
    domain: "todoadollar",
    terminos: "https://cdn.clau.io/terms/privacy.pdf",
    logo: "https://todoadollardotrecursos.clau.io/boton-app-todo-a-dollar.png",
    navbar: {
      background: "white-background"
    }
  },
  maagoos: {
    domain: "maagoos",
    terminos: "https://cdn.clau.io/terms/privacy.pdf",
    logo: "https://img.clau.io/177.png",
    navbar: {
      background: "white-background"
    }
  },
  tosto: {
    domain: "tosto",
    terminos: "https://cdn-multi.clau.io/166/1661296437-8091.pdf",
    logo: "https://img.clau.io/166.png",
    navbar: {
      background: "white-background"
    }
  },
  motatan: {
    domain: "motatan",
    terminos: "https://cdn.clau.io/terms/privacy.pdf",
    logo: "https://cdn-multi.clau.io/260/1712759635-5484.png",
    navbar: {
      background: "motatan-background"
    }
  },
  cafeduran: {
    domain: "cafeduran",
    terminos: "https://cdn.clau.io/terms/privacy.pdf",
    logo: "https://cdn-multi.clau.io/144/1643731906-6600.jpg",
    navbar: {
      background: "cafeduran-background",
      topnav: "cafeduran-topnav"
    }
  },
  grancombo: {
    domain: "grancombo",
    terminos: "https://cdn.clau.io/terms/privacy.pdf",
    logo: "https://img.clau.io/264.jpg",
    navbar: {
      background: "white-background"
    }
  },
  keenes: {
    domain: "keenes",
    terminos: "https://cdn.clau.io/terms/privacy.pdf",
    logo: "https://img.clau.io/280.png",
    navbar: {
      background: "white-background"
    }
  },
  beep: {
    domain: "beep",
    terminos: "https://cdn.clau.io/terms/privacy.pdf",
    logo: "https://img.clau.io/307.png",
    navbar: {
      background: "white-background"
    }
  },
  alleria: {
    domain: "alleria",
    terminos: "https://cdn.clau.io/terms/privacy.pdf",
    logo: "https://img.clau.io/311.png",
    navbar: {
      background: "white-background"
    }
  },
  larosa: {
    domain: "larosa",
    terminos: "https://cdn.clau.io/terms/privacy.pdf",
    logo: "https://cdn-multi.clau.io/171/1735059727-5077.png",
    navbar: {
      background: "white-background"
    }
  }
});

export default domainSettings;
